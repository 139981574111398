.ContenarInvoice {
    background: #FFFFFF;
    box-shadow: 0px 4px 21px rgba(204, 204, 204, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 32px 18px 32px;

    .typo_heding {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 700;
        line-height: 20px;
        text-align: left;
        color: #025041;

    }
}

.ContenarTebal {
    padding: 38px 43px;
}

// .StatusTypoRejected {
//     background: #F6F7F7;
//     padding: 1px 10px;
//     font-family: Poppins;
//     font-size: 10px;
//     font-weight: 400;
//     line-height: 20px;
//     text-align: left;
//     background: #1D1D1D;

// }

.imgContean {
    display: flex;
    align-items: center;

    p {
        color: #000;
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        text-align: left;
        line-height: 20px;
        padding-left: 10px;
    }
}

.contenar_tebal_liner th {
    border-bottom: 0px solid;
}

.StatusTypoissued {
    padding: 1px 10px;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #257D6B;
    width: min-content;
}

.headingSummaryTitle {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;
}

.invoiceSummary {
        background: #FBFBFB;
            padding: 20px 20px 10px 20px;
            border-radius: 8px;
            margin-bottom: 30px;
}

.summaryRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.summaryLabel {
    font-weight: 400;
    color: #333;
}

.summaryValue {
    font-weight: 400;
    color: #000;
}

.fast_button_data {
    border-radius: 8px;
    border: 1px solid #1D1D1D;
    height: 49px;
    padding: 13px 30px 13px 30px;
    color: #1D1D1D;
    width: 13rem;
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    background-color: #FFF;
    margin-right: 10px;
}
.fast_button_data:hover {
    border-radius: 8px;
    border: 1px solid #1D1D1D;
    height: 49px;
    padding: 13px 30px 13px 30px;
    color: #1D1D1D;
    width: 13rem;
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    background-color: #FFF;
    margin-right: 10px;
}

.fast_button_data:focus {
    border-radius: 8px;
    border: 1px solid #1D1D1D;
    height: 49px;
    padding: 13px 30px 13px 30px;
    color: #1D1D1D;
    width: 13rem;
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    background-color: #FFF;
    margin-right: 10px;
}
.fast_button_data:active {
    background-color: #FFF !important;
    color: #1D1D1D !important;
    border: 1px solid #1D1D1D !important;

}
.CancelStyles{
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    color: #1D1D1D;
}
.LinkButtonClasss{
    color: #FFF;
       text-align: center;
       font-family: Poppins;
       font-size: 14px;
       font-style: normal;
       font-weight: 400;
       line-height: 14px;
}
   .two_button_invoice { 
       cursor: pointer;
       margin-left: 10px;
       border-radius: 8px;
       background-color: #025041 !important;
       padding: 13px 30px 13px 30px;
       color: #FFF;
       width: 13rem;
       text-align: center;
       font-family: Poppins;
       font-size: 14px;
       font-style: normal;
       font-weight: 400;
       line-height: 14px;
       border: 0px solid;
   }
        .two_button_invoice:hover {
            cursor: pointer;
            margin-left: 10px;
            border-radius: 8px;
            background-color: #025041 !important;
            padding: 13px 30px 13px 30px;
            color: #FFF;
            width: 13rem;
            text-align: center;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            border: 0px solid;
        }

.buttinsDiv{
    display: flex;
    justify-content: space-between;
    gap: 2;
}

.limitExceed{
    color: rgb(255, 70, 70);
    font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        padding: 5px 0px 5px 0px;
}