.contenar_tebal_liner {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 2px 14px 2px rgba(174, 174, 174, 0.25);
    width: 100%;

    th {
        font-family: Poppins;
        padding: 16px 6px;
        color: #000;
        font-size: 13px;
        font-style: normal;
        line-height: 20px;
    }

    td {
        color: #000;
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        // font-weight: 500;
        text-align: center;
        padding: 16px 6px;
        line-height: 20px;
    }

    .main_camin_box_dataa {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #D9D9D9;
    }

    .main_camin_box_dataa_twoo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        p {
            width: 50%;
        }

        .textLeft {
            text-align: left;
            overflow-wrap: normal;

        }

        .textRight {
            text-align: right;
        }
    }

    .maian_bottom_borderr {
        border-bottom: 1px solid #D9D9D9;
    }

}

.main_serech_box_filtar_data {
    display: flex;
    align-items: center;
    padding: 0px 0px 50px 0px;
}

.balance_cat {
    margin: 0px 0px 0px 14px;

    select.form-select {
        border-radius: 50px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.03em;
        color: #383838 !important;
    }
}

#bootom_button_two {
    justify-content: end;
    margin-top: 40px;
}

.menuitem {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #282828;
}

.white_btn_two {
    border: 0px solid #025041 !important;
    background-color: white !important;
    margin-right: 20px;
    color: #F87161 !important;
    border: 1px solid #DF3A3A !important;

}

.white_btn_two:hover {
    color: #F87161 !important;
}

.addnewbtnclass_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 768px) {
    .main_camin_box_dataa_twoo {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            width: 50%;
        }

        .textLeft {
            text-align: left;
        }

        .textRight {
            text-align: right;
        }
    }
}

@media only screen and (max-width: 450px) {

    .main_serech_box_filtar_data {
        display: block;
    }
    #SelectBoxContean{
        margin: 10px 0 0 0px !important;
    }
}