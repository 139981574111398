.tebal_class_contean {

    .LinksTableCell {
        color: #1D1D1D;
        background-color: #fbfbfb;
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        padding: 15px 10px;

    }

    .TableCell_roww {
        padding: 15px 10px;
        border: 0px solid !important;

    }
}

.Typography_Box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    // border: 0.5px solid #BBB;
    // padding: 7px 16px;

    p {
        color: #1D1D1D;
       text-decoration: underline;
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    .button_ContentCopyIcon {
        background-color: transparent !important;
        color: #1D1D1D;
        border: 0px solid !important;
        padding: 0px;

        svg {
            font-size: 17px;
        }
    }

    .button_ContentCopyIcon:hover {
        color: #1D1D1D;
    }
}

.inactiveTypography {
    color: #1D1D1D;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.TypographyStatus {
    color: #025041;

    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.TypographyCreated_at {
    color: #1D1D1D;

    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.TypographyUpdated_at {
    color: #1D1D1D;

    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

@media only screen and (max-width: 450px) {
    .Typography_Box {
        display: block;
    }
    .LinksTableCell{
        min-width: 100px !important;
      }
}
@media screen and (min-width:450px) and (max-width:1024px) {
    .LinksTableCell{
        min-width: 110px !important;
      }
}
