.product_btn_wrapper {
    width: 100%;
    display: flex;
    // padding-right: 20px !important;
    // padding-left:20px;
}

@media only screen and (min-width:520px) and (max-width:600px){
    .custom_mobile_btn_width{
        min-width:120px !important;
    }
}