@import "../../../../../styles/variables.module.scss";


.rejection_box {
    width: 450px;
    background-color: $color-background-light;
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 10px;

    .rejection_heading {
        font-size: $font-size-xxl;
        font-weight: $font-weight-semibold;
        margin-bottom: 5px;
        font-family: "Poppins" !important;
    }

    .rejection_subheading {
        color: $color-text-secondary;
        font-family: "Poppins";
    }
}