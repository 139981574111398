.topup {
    width: 440px;
    background: #FFFFFF;
    border-radius: 12px;
    margin: 20px auto;
    padding: 48px 32px;
    box-shadow: 0px 2px 11px rgba(158, 155, 155, 0.25);

    &__heading {
        font-weight: 600;
        font-size: 25px;
        line-height: 40px;
        text-align: center;
        color: #3A3A3A;
        margin-bottom: 15px;
    }

    &__description {
        display: flex;
        justify-content: center;
        margin-bottom: 15px;

        p {
            text-align: center;
            font-weight: 400;
            font-size: 13px;
            line-height: 24px;
            width: 340px;
            color: #000;
        }
    }

    &__table {
        border: 1px solid #DDDDDD;
        border-radius: 8px;
        padding: 0 10px;
        margin: 0px auto;
        width: 380px;

        .bottomNone {
            border-bottom: none !important;
        }

        .b-bot {
            border-bottom: 1px solid #DDDDDD;
        }

        .d-flex {
            display: flex;
            justify-content: space-between;
            // margin-bottom: 10px;
            align-items: center;
            height: 36px;

            .left {
                font-weight: 400;
                font-size: 13px;
                line-height: 30px;
                color: #000;
            }

            .right {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #111111;
            }
        }
    }

    &__button {
        display: flex;
        justify-content: center;

        .green-btn {
            background: #025041;
            border-radius: 6px;
            width: 376px;
            height: 48px;
            border: 1px solid #025041;
            margin-top: 40px;
            color: white;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
        }
    }
}