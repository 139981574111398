@import "../../../../../styles/variables.module.scss";


.transaction_failed_box{
    background: #FFFFFF;
    border-radius: 8px;
    padding: 32px;
    width: 600px;
    box-shadow: $box-shadow;
    font-family: "Poppins";
    .transaction_failed_heading{
        font-size: $font-size-base;
        color:$color-primary;
        font-weight: 500;
        font-family: "Poppins";
    }
    .transaction_failed_text{
        font-size: $font-size-md;
        color:$color-text-primary;
        font-weight: 500;
        font-family: "Poppins";
    }
}
