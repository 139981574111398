.heder_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 18px 43px;
    background: #FFF;
    box-shadow: 0px 4px 21px 0px rgba(204, 204, 204, 0.25);

    p {
        color: #025041;

        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
    }

    button {
        border-radius: 6px;
        background: #025141;
        color: #FFF;
        padding: 12px 25px;
        border: 0px solid;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    button:active {
        background-color: #025141 !important;
    }

    button:hover {
        background: #025141;
        border: 0px solid;
        color: #FFF;

    }
}
@media only screen and (max-width: 450px) {

.heder_box{
    padding: 18px 20px;

}
}