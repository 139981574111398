#id_document_style {
    // margin: 60px;
    background: #F6F6F6;
    padding: 0px;
    margin-bottom: 0;
    display: block;
}

#div_wrapper_doyoument {
    border-radius: 5px;
    background: #FFF;
    padding: 0px 20px 20px 20px;
    width: 85%;
    margin: 6px auto 6px auto;

}
.heading_label{
//     color: #1D1D1D;
// font-family: Poppins;
// font-size: 10px;
// font-style: normal;
// font-weight: 500;
// line-height: 20px;
}
.dateContean{
    display: flex;
    justify-content: space-between;
}
.manin_loop_collam {
    border-radius: 5px;
    background: #FFF;
    padding: 10px 10px 28px 10px;

    .main_contean_data_inthis {
        display: flex;
        // align-items: center;
        // width: 50%;

        .Recurringlabel {
            color: #1D1D1D;
            font-family: Poppins;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
        }


       
    }

}
.main_div_no_text {
    color: #000;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 36px 2px 0px 2px;

}
.displya_data_fastdiv {
    display: flex;
    align-items: center;
}

.main_div_RecurringIntervals {
    // width: 100%;
    width: 85%;
    margin: auto;
}
.main_div_classss{
    width: 85%;
    margin:6px auto;
}
.line_items_main_div {
    border-radius: 5px;
    background: #FFF;
    padding: 10px 10px 10px 10px;
}
@media only screen and (max-width: 450px) {

#div_wrapper_doyoument{
    width: 100%;

}
.main_div_RecurringIntervals{
    width: 100%;
}
.main_div_classss{
    width: 100%;
}
}