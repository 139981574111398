.payable-grid-filter-box {
    margin: 28px 0px 50px 0px;
    display: flex;
    gap: 5px;
    width: 100%;
    padding: 0 32px;



    .payable-filter-wrapper {
        display: flex;

        height: 40px;
        background: #FFFFFF;
        border: 1px solid #DDDDDD;
        border-radius: 100px;
        outline: none;

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #111111;

        &.input {
            width: 104px;
        }

        &.dropdown {
            width: 133px !important;

            &.lg{
                width: 143px !important;
            }
        }

        &.custom {
            width: 94px !important;
            display: flex;
            align-items: center;
            gap: 13px;
            justify-content: center;
            cursor: pointer;
        }

        .icon {
            display: flex;
            align-items: center;
            padding: 0px 12px 0px 14px;
        }

        .payable-search-input {
            width: 100%;
            border: none;
            border-radius: 100px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: #111111;
            outline: none;
        }

        .payable-search-input::placeholder {

            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: #111111;
            outline: none;
        }
    }
}

.payable-grid-wrapper {
    padding: 0 32px;
    table {
        width: 100%;
        font-style: normal;

        th {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: left;
            color: #000;
        }

        td {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 50px;
            color: #111111;
        }

        .logo-wrapper {
            display: flex;
            align-items: center;
            gap: 5px;
        }

        .form-check-input{
            width: 17px;
            height: 17px;
            /* left: 2px; */
            /* top: calc(50% - 20px/2); */
            background: #FFFFFF;
            border: 2px solid #DDDDDD !important; 
            border-radius: 4px;
            border-color: red !important;
        }
        .form-check-input:focus{
            background-color: #86b7fe !important;
        }
        .status-wrapper {
            padding: 2px 8px;
            border-radius: 4px;
            font-size: 14px;
            font-weight: 500;
            width: fit-content;
            height: 24px;
            display: flex;
            align-items: center;

            &.Draft {
                background: #F3F3F3;
                color: #000;
            }

            &.Approved {
                background: #E1FBEB;
                color: #1FBCA0;
            }

            &.Paid {
                background: #E1FBEB;
                color: #1FBCA0;
            }

            &.Cancelled {
                background: #FFF3E9;
                color: #E27E46;
            }

            &.Partially-paid {
                background: #FBF1FC;
                color: #A06DC8;
            }
        }
    }

}

.navigator {
    display: flex;
    justify-content: flex-end;
    gap: 36px;
    align-items: center;
    margin-top: 50px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    padding-right: 80px;
}