@media only screen and (min-width: 898px) and (max-width: 1078px) {
  .custom_login_btn_wrapper .custom_login_btn .span_btn{
     font-size: 12px !important;
  }
}


@media only screen and (max-width: 490px) {
    .custom_login_btn_wrapper {
      display: flex !important;
      flex-direction: column !important;
      
    }
    .custom_login_btn_wrapper .custom_login_btn{
      width: 100% !important;
    }
    .login_change_page{
      padding-top: -30px !important;
    }
  }