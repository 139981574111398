.Customers_details_container {
    padding: 40px 32px;

    .Customers_details_box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
            background-color: #fff;
            border: 0px solid;
            font-family: Poppins;
            font-size: 12px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: #1D1D1D;
            padding: 0px;

            svg {
                margin-right: 7px;
            }
        }

        button:active {
            background-color: #fff !important;
            color: #1D1D1D;
        }

        button:focus-visible {
            box-shadow: none !important;
        }
    }

    .calssName_heding {
        padding: 25px 0px 30px 0px;
        // display: flex;
        // justify-content: space-between;
        // align-items: center;

        p {
            font-family: Poppins;
            font-size: 22px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: #1D1D1D;

        }
    }

    .Box_Recent_activity {
        box-shadow: 0px 2px 14px 2px #AEAEAE40;
        background: #FFFFFF;

    }
}

.hedaderBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.DownloadButton {
    border: 1px solid #DDDDDD;
    background-color: #FFFFFF;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #025041;
    border-radius: 13px !important;
    margin: 10px 10px;
    height: 40px;
    padding: 6px 20px !important;
    text-decoration: none;
    min-width: 0px !important;
}

.DownloadButton:hover {
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;


}

.DownloadButton:active {
    background-color: #FFFFFF !important;
    border: 1px solid #DDDDDD !important;
    color: #025041 !important;
}

.DownloadButton:focus-visible {
    box-shadow: none !important;
}

.tab_name {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #1D1D1D;
    text-transform: capitalize;
    // width: 152px;
    // width: 28%;
    padding-top: 20px;
    padding-bottom: 20px;
    align-items: normal;
}

.setp_container {
    padding: 20px;
    gap: 12px;

    .grid_fast {
        padding: 11px 20px 20px 10px;
        // opacity: 0px;
        background-color: #FBFBFB;

        .heding_box {
            font-family: Poppins;
            font-size: 15px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
            color: #1D1D1D;
            padding-bottom: 12px;
        }

        .contean_box {
            display: flex;
            justify-content: space-between;

            // padding-bottom: 12px;
            .typo_contean {
                width: 50%;
                font-family: Poppins;
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: #6D6B6B;

            }

            .typo_contean_sub {
                width: 50%;
                font-family: Poppins;
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: #6D6B6B;

            }
        }
    }

    .box_secend_poshan {
        display: flex;
        align-items: center;
        padding: 12px 0px;

        .LineStart {
            border-top: 0.5px solid #DDDDDD;
            width: 100px;
        }

        .Recent_typo {
            font-family: Poppins;
            font-size: 12px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
            color: #1D1D1D;
            padding: 0px 10px;
        }

        .box_last_line {
            border-top: 0.5px solid #DDDDDD;
            width: 79%;
        }
    }
}

.setp_footer_container {
    background: #FBFBFB;
    padding: 10px 19px;
    border-radius: 10px;

    .name_text_HEDEAR {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        line-height: 15px;
        text-align: left;
        color: #1D1D1D;
        padding-bottom: 12px;
    }

    .name_text {
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        text-align: left;
        color: #1D1D1D;
        padding-bottom: 12px;
    }
}

.infoButton {
    display: flex;
    align-items: center;

}

.IconBUTTON {
    background-color: #fff;
    border: 0px solid;
}

.IconBUTTON:hover {
    background-color: #fff;
    border: 0px solid;
}

@media only screen and (max-width: 450px) {
    .Customers_details_container {
        padding: 40px 10px;

    }

    .tab_name {
        width: 180px;
    }
}