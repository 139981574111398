
.niobi-balance-detail {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 32px;
    height: 300px;
    box-shadow: 0 3px 11px hsla(0,2%,61%,.25);
    &__heading {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        margin: 20px 0px 120px;
        text-transform: capitalize;
    }
    
    &__btn-row {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 30px;
        column-gap: 10px;

        .btn {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0.05em;
            color: #FFFFFF;
            border-radius: 6px;
            &:disabled {
                background: #CFCFCF !important;
            }
        }
        &__white {
            color: #707070 !important;
            background-color: #FFFFFF !important;
            border-color: #015241 !important;
            padding: 11px 25px;
        }
    }
}