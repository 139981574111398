.account_setup_main_box {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    justify-content: center;
    align-items: center;

    .description_text {
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        color: #1D1D1D;

        span {
            color: #025041;
            font-weight: 500;
        }
    }

    .logout_button {
        width: 32%;
        height: 35px;
        padding: 10px;
        border-radius: 5px;
        color: #fff;
        background: #025041;
        text-transform: capitalize;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
    }

    .column_box {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        align-items: center;
    }

    .conteanDav {
        background: #ffffff;
        box-shadow: 0px 1px 11px 0px #1111111a;
        padding: 30px 40px;
        width: 100%;

        .hedingPage {
            p {
                font-family: Poppins;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                text-align: left;
            }
        }

        .ConteanBox {
            padding-top: 20px;
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            .content_flex_box {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .details_text {
                    font-family: Poppins;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: left;
                }
            }

            .typo_lebal {
                font-family: Poppins;
                font-size: 12px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;

                color: #1D1D1D;
            }
        }
    }
}

.title_text {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #025041;

}