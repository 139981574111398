@import "../../../../../../../../styles/variables.module.scss";


.step2_heading {
  font-size: $font-size-heading;
  color: $color-text-primary;
  font-weight: $font-weight-semibold;
  font-family: "poppins" !important;
}

.inputs_container {
  border-bottom: 1px solid $color-border;
  display: flex;
  flex-direction: column;
  font-family: "poppins";
  gap: 25px;

  .label {
    font-size: $font-size-base;
    margin-bottom: 10px;
    font-weight: $font-weight-regular ;
    font-family: "Poppins";

  }

  .arrowforwardicon {
    font-size: $font-size-xxl;
    cursor: pointer;
  }
}

.paydata_container {
  margin-top: 35px;
  margin-bottom: 50px;
  color: $color-text-primary;
  font-family: "Poppins";

  .paydata_heading {
    font-size: $font-size-xl;
    color: $color-text-primary;
  }

  .bank_account {
    font-weight: $font-weight-medium;
    font-size: $font-size-md;
    font-family: "Poppins";
    color: $color-text-primary;
  }

  .available_balance {
    font-size: $font-size-sm;
    color: $color-text-secondary;
    font-family: "Poppins";
  }

  .info {
    font-size: $font-size-md;
    color: #025041;
    font-family: "Poppins";
  }

  .arrowicon {
    font-weight: $font-weight-light;
    font-size: $font-size-xxl;
    color: #025041;
  }

  .paydata_item {

    .paydata_title {
      font-size: $font-size-md;
      color: $color-text-secondary;
      font-family: "Poppins";
    }

    .info {
      font-size: $font-size-md;
      color: #025041;
      font-family: "Poppins";
    }

    .arrowicon {
      font-weight: $font-weight-light;
      font-size: $font-size-xxl;
      color: #025041;
    }
  }

  .vat_box {
    
      will-change: grid-template-rows; 


    // padding :20px 0px 20px 0px;
    .vat_heading {
      margin-top: 20px;
      font-size: $font-size-xl;
      color: $color-text-primary;
      font-family: "Poppins";
      margin-bottom: 2px;
      padding-bottom: 10px;
      border-bottom: 1px solid $color-border;
      margin-bottom: 32px;
    }

    .label {
      font-size: $font-size-base;
      color: $color-text-primary;
      font-family: "Poppins";
    }
  }

}

// @supports (overflow: overlay) {
//   .inputs_container {
//     overflow-y: overlay;
//   }
// }