.boxbgimg {
    background-image: url('https://app.niobi.co/static/media/3.e2abf67aef451a9538d9.gif');
    height: 100vh;
    background-size: cover;
    display: flex;
    align-items: center;
}
.addinvitelist {
    background: #FFFFFF;
    box-shadow: 0px 3px 11px rgba(158, 155, 155, 0.25);
    border-radius: 10px;
    margin: 0 auto;
    width: 500px;
    padding: 34px 40px;
}
.hefingtage {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    text-align: center;
    color: #025041;
    margin-bottom: 12px;
}
.addtestdiscer {
    text-align: center;
    padding-bottom: 20px;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 12px;   
    color: #282828;
}
.boximcedata {
    border: 1px solid #D9D9D9;
    border-radius: 16px;
    padding: 20px 20px;
}

.addbusinessname {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 12px;
    color: #6D6B6B;
    margin-bottom: 0px;
}
.abbdataapi{
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 18px;
    color: #000;
    margin-bottom: 0px;
}
.addnewdivfast{
    padding-bottom: 15px;
}
.centerbtn {
    color: #282828 !important;
    font-family: 'Poppins' !important;
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize !important;
    background: transparent !important;
    border: 0px solid !important;
}

.savebtn {
    color: #FFFFFF !important;
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    text-transform: capitalize !important;
    margin-left: 31px;
    background: #025041 !important;
    border-radius: 4px !important;
    width: 230px;
    height: 48px;
}
.savebtn:hover{
    background-color: #025041;
}
.adddibbtntwo{
    padding: 30px 0px 0px 0px;
    text-align: end;
}