.contenar_box {
    border-radius: 10px;
    background: #FFF;

    box-shadow: 0px 1px 10px 0px rgba(17, 17, 17, 0.10);
    margin: 60px 0px;

    .box_conteanr_main {
        padding: 35px;

        .img_main_dev_contean {
            display: flex;
            align-items: center;

            .img_box_img_contenar {
                img {
                    width: 50px;
                    height: 50px;
                }
            }

            .box_heding_conten {
                padding: 0px 0px 0px 8px;

                p {
                    color: #1D1D1D;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                }
            }
        }

        .box_contenar_amount {
            padding-top: 25px;

            .des_heding_data {
                color: #1D1D1D;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
            }

            .Typography_amont {
                color: #1D1D1D;
                font-family: Poppins;
                font-size: 28px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                padding-top: 2px;
            }
        }
    }
}

.from_contean_data {

    padding-bottom: 30px;

    .heding_from {
        color: #1D1D1D;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }

    .input_class {
        border-radius: 5px;
        width: 100%;
        height: 48px;
        padding: 0px 8px;
        outline: none;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        color: #3A3A3A;
    }

    .tab_contean_button {
        display: flex;
        justify-content: space-between;

        .difolt_button {
            border-radius: 5px;
            border: 1px solid #DDD;
            color: #000;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            background-color: #FFF;
            line-height: 24px;
            width: 49%;
            display: flex;
            align-items: center;
        }

        .mpesa_button {
            border-radius: 5px;
            border: 1.5px solid #025041;
            color: #000;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            background-color: #FFF;
            line-height: 24px;
            width: 96%;
            display: flex;
            align-items: center;
        }
    }

}
.tab_contean_button {
    display: flex;
    justify-content: space-between;

    .difolt_button {
        border-radius: 5px;
        border: 1px solid #DDD;
        color: #000;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        background-color: #FFF;
        line-height: 24px;
        // width: 49%;
        display: flex;
        width: 96%;
        align-items: center;

        img {
            margin-right: 10px;
        }
    }

    .mpesa_button {
        border-radius: 5px;
        border: 1.5px solid #025041;
        color: #000;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        background-color: #FFF;
        line-height: 24px;
        // width: 49%;
        width: 96%;
        display: flex;
        align-items: center;

        img {
            margin-right: 10px;
        }
    }
}

.button_bottomcontean {
    position: absolute;
    bottom: 60px;
    width: 94%;

    button {
        width: 100%;
    }
}


.bg_color_country {
    background-color: #FFF;
}

.div_pading_data {

    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 1px 10px 0px rgba(17, 17, 17, 0.15);
    max-width: 35%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;

    .des_data {
        color: #474747;
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .helite_text {
        color: #474747;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}

.main_dev_classs {
    padding-top: 50px;
}

.contenar_main_text_line {
    text-align: center;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 1px 10px 0px rgba(17, 17, 17, 0.15);
    max-width: 35%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
}

.FAILURE_contean {
    text-align: center;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 1px 10px 0px rgba(17, 17, 17, 0.15);
    max-width: 35%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;

}

.classname_text_key_minnn {
    color: #025041;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 15px;
}

.text_err_contenarr_minn {
    color: #025041;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 15px;
}

.ErrorMessage {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #dc3545;
    font-weight: 500;

}

.RetryButton {
    width: 350px;
    border-radius: 5px;
    background: #025041;
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: 0px solid;
    margin-top: 30px;
    height: 39px;
}

.RetryButton:focus-visible {
    background: #025041;
    color: #FFF;
    border: 0px solid;
}

.RetryButton:hover {
    background: #025041;
    color: #FFF;
}

.heding_data_call_sjjshgss {
    color: #025041;

    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.mpsaimg {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}