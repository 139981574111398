

.box_add_new_data{
    box-shadow: 0px 2px 14px 2px #AEAEAE40;
max-width: 1000px;
margin: auto;
height: 100px;
}
.add_a_ldidhdhddggdg{
    color: #025041;
    text-decoration: none;
    padding: 0px 0px 0px 5px;
}
.add_a_ldidhdhddggdg:hover{
    color: #025041;
}
.redyarect_tecttssfs{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}