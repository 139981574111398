.main_dev_contean {
    max-width: 750px;
    margin: auto;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 2px 14px 2px rgba(174, 174, 174, 0.25);
    padding: 34px 50px;
    text-align: center;

    .heding_tage_listtt {
        color: #1D1D1D;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    .iframe_calss_div {
        padding: 15px 0px;
    }

    .conatenar_footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .Cancel_footer {
            color: #1D1D1D;
            font-family: Poppins;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            padding: 6px 25px;
            background-color: #FFF;
            border-radius: 6px;
            border: 1px solid #1D1D1D;
            width: 150px;
        }

        .List_footer {
            color: #fff;
            font-family: Poppins;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            padding: 6px 25px;
            background: #025041;
            border-radius: 6px;
            border: 0px solid;
            // border: 1px solid #1D1D1D;
            width: 150px;
            margin-left: 15px;
        }
    }

}