.ContenarInvoice {
    background: #FFFFFF;
    box-shadow: 0px 4px 21px rgba(204, 204, 204, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 18px 32px 18px 30px;

    .typo_heding {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 700;
        line-height: 20px;
        text-align: left;
        color: #025041;

    }
}
.aTageStyle{
    color: #000 !important;
}
.ContenarTebal {
    padding: 38px 43px;
}
.setp_container {
    padding: 20px 0px;
    gap: 12px;

    .grid_fast {
        padding: 11px 20px 20px 10px;
        // opacity: 0px;
        background-color: #FBFBFB;

        .heding_box {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
            color: #1D1D1D;
            padding-bottom: 12px;
        }

        .contean_box {
            display: flex;
            justify-content: space-between;

            // padding-bottom: 12px;
            .typo_contean {
                width: 50%;
                font-family: Poppins;
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: #6D6B6B;

            }

            .typo_contean_sub {
                width: 50%;
                font-family: Poppins;
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: #6D6B6B;

            }
        }
    }

    .box_secend_poshan {
        display: flex;
        align-items: center;
        padding: 12px 0px;

        .LineStart {
            border-top: 0.5px solid #DDDDDD;
            width: 100px;
        }

        .Recent_typo {
            font-family: Poppins;
            font-size: 12px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
            color: #1D1D1D;
            padding: 0px 10px;
        }

        .box_last_line {
            border-top: 0.5px solid #DDDDDD;
            width: 79%;
        }
    }
}
.StatusTypoRejected {
    background: #F6F7F7;
    padding: 1px 10px;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    background: #1D1D1D;

}

.imgContean {
    display: flex;
    align-items: center;

    p {
        color: #000;
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        text-align: left;
        line-height: 20px;
        padding-left: 10px;
    }
}

.contenar_tebal_liner th {
    border-bottom: 0px solid;
}

.StatusTypoissued {
    padding: 1px 10px;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #257D6B;
width: min-content;
}
@media only screen and (min-width:520px) and (max-width:600px){
    .custom_invoice_btn_width{
        min-width:120px !important;
        padding: 12px 12px !important;
    }
}