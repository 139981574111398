// .layout {
//   min-height: 100vh;
//   display: flex;
// }
.sEFASFfsaff {
  position: fixed;
  // min-width: 15%;
  height: 100%;
  overflow-y: scroll;
}

.logout_menuitem {
  border-top: 0.5px solid #E4E4E4;
  padding: 0px;
}

.sider {
  padding: 12px 12px;
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 240px;
  display: flex;
  position: sticky;
  top: 0px;
  // min-height: 100%;
  height: 91.5vh;
  overflow-y: scroll;
  flex-direction: column;
  background: rgb(246, 246, 246);
}

.content {
  background: none;
  //  padding: 0px 0px;
  //  padding: 32px;
  display: flex;
  // flex-direction: column;
  // width: 82%;
  width: calc(100% - 240px);
  margin-left: 240px;
}
.content_two {
  width: auto;
  margin-left: 0px;
  padding-top: 32px;

  // margin-top: 20px;
}
.content_three {
  width: auto;
  margin-left: 0px;
  padding-top: 32px;
  padding: 10px;

  // margin-top: 20px;
}

.conteant_data_contenar {
  width: 100%;
}

.menu {
  background: transparent;
  margin-top: 24px;

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.company {
  align-items: center;
}

.companyMenu {
  background: transparent;

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.emptyPageWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: none;
}

.emptyPageContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 480px;
  height: 100%;
  align-items: center;
}

.link {
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
}

// .topNav{
//   background: none;
//   z-index: -1;
//   top: 0;
//   display: flex;
//   position: fixed;
//   top: 0;
//   height: 8%;
//   width: 100%;
//   padding: 5px 8px;
//   background: #fff;
//   box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
// }

.bodyWrapper {
  // min-height: 92%;
  overflow: auto;
  display: flex;
  width: 100%;
  background: none;
  justify-content: center;
  padding: 24px 0px;
}

#add_menu_icon {
  display: none !important;
}

.registrationBody {
  box-sizing: border-box;
  background: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 16px 100px;
  gap: 24px;
  width: 60%;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 8px;
  flex: none;
  height: 50%;
  order: 1;
  flex-grow: 0;
}

.companyTitle {
  font-size: 18px;
}

.usernameTitle {
  font-size: 12px;
  color: #000;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.64px;
}

.sdjffffjfhfh {
  box-sizing: border-box;
  margin: 0px 0px 0px 12px;
  min-width: 0px;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
}

.roleTitle {
  font-size: 12px;
  color: #000;
}

:root {
  --bs-link-color: #055041 !important;
  --bs-link-hover-color: #2b7a6a !important;
  --bs-btn-bg: #2b7a6a !important;
}

a {
  color: rgb(5, 80, 65) !important;
  text-decoration: none;
}

.react-datepicker__aria-live {
  display: none;
}

.add_respons_ahahgagga {
  // padding: 30px 0px 0px 0px;
  position: absolute;
  bottom: 4px;
}

.side_logo_bottonn {
  position: fixed;
  bottom: 10px;
}

.ADJADhdjdhudhdndd {
  margin-top: 24px;
  padding-bottom: 60px;
}

.side_logo_bottonn {
  position: absolute;
  bottom: 10px;
}

.grid_container {
  background-color: #055041;
  padding: 8px 0px;
  position: fixed;
  z-index: 100;
  // border-radius: 15px 15px 0px 0px;
}

.box_dialog_main {
  padding: 14px 32px;
}

.typo_text {
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  text-align: center;
  color: #282828 !important;
}

.grid_senter {
  background-color: #FFFFFF;
  height: 170px;
}

.box_btn_cencal {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
}

.delete_btn {
  font-family: 'Poppins' !important;
  font-size: 14px !important;
  color: #FF3A23 !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
}

.cancel_btn {
  font-family: 'Poppins' !important;
  font-size: 14px !important;
  color: #025141 !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
}

.box_header {
  display: flex;
  justify-content: end;
  align-items: center;

}

.account_info_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 30px;
}

.bsnNamaBox {
  display: flex;
  align-items: center;
  padding-left: 30px;
}

.pending_Typo {
  padding: 1px 10px 1px 10px;
  border-radius: 5px;
  background: #21144c;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #FFFFFF;  
}

.typo_Active {
  padding: 1px 10px 1px 10px;
  border-radius: 5px;
  background: #025041;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #FFFFFF;
}

.typo_account_name {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #FFFFFF;

}

.grid_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info_name {
  svg {
    color: #fff;
  }
}

.menu_item {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #000000;
  padding: 11px 10px 5px 10px;
}

.menu_box {
  display: flex;
  align-items: center;
  padding: 11px 10px 5px 10px;

  img {
    width: 35px;
  }

}

.typo_menuName {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #1D1D1D;
  padding-left: 4px;
}

.roleText {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #6D6B6B;
  padding-left: 4px;

}

.account_icon_class {
  padding: 0px 17px 0px 0px;
}

@media only screen and (max-width: 820px) {
  .sEFASFfsaff {
    height: unset;
  }

  .sider {
    height: 100vh;
  }

  #sidebar_textxt {
    display: none;
  }

  .content {
    width: calc(100% - 0px);
    margin-left: 0px;
    padding-top: 32px;

    // margin-top: 20px;
  }
  .content_two {
    width: calc(100% - 220px);
    margin-left: 0px;
    padding-top: 32px;

    // margin-top: 20px;
  }

  #add_menu_icon {
    display: block !important;
  }

  .layout {
    display: block !important;
  }
}

@media only screen and (max-width: 2000px) {

  // .sEFASFfsaff {
  //   // width: 13%;
  // }
}

@media only screen and (max-width: 450px) {
  .conteant_data_contenar {
    margin-top: 0px !important;
  }

  .sider {
    position: unset;
    background-color: #fff;
  }

  // .add_respons_ahahgagga {
  //   bottom: 100px;
  // }

  .content {
    width: calc(100% - 0px);
    margin-left: 0px;
    padding-top: 0px;

    // margin-top: 20px;
  }
  .content_two {
    width: calc(100% - 0px);
    margin-left: 0px;
    padding-top: 0px;

    // margin-top: 20px;
  }
  .sEFASFfsaff {
    position: sticky;

  }

  #sidebar_textxt {
    display: none;
  }

  #add_menu_icon {
    display: block !important;
  }

  .layout {
    display: block !important;
  }
}