.box_wraper {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 10px 0px 20px 0px;
    margin: 10px;
    border-radius: 5px;
    // margin: 40px 80px 40px 80px;
    width:50%;
    margin-right:auto;
    margin-left:auto;
    margin-top:50px;
    margin-bottom: 40px;

   
}
 .box_header {
    //  padding: 5px 0px 15px 20px;
    //  border-bottom: 1px solid #dddddd;
    //  margin-top: 10px;
 }

 .box_heading {
     background: #ffffff;
     box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.1450980392);
     padding: 18px 32px;
     font-family: "Poppins";
     font-style: normal;
     font-weight: 700;
     font-size: 18px;
     line-height: 20px;
     color: #025041;
 }
.box_body {
    padding: 20px;

    .country_name {
        margin-bottom: 15px;
        font-size: 14px;
        font-weight: 500;
    }

    .country_name_img {
        font-size: 14px;
    }

    .currency_name {
        font-size: 13px;
        font-weight: 400;
    }

    .buttonSection {
        display: flex;
        justify-content: center;
    }

    .submitButton {
        border: none;
        border-radius: 5px;
        color: #fff;
        background-color: #055041;
        margin-top: 40px;
        padding: 10px 100px 10px 100px;
    }
}

// checkbox-style
.inputCurrency[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    outline: none;
}

.inputCurrency[type="checkbox"]:checked {
    border-color: #055041;
}

.grid_contenar {
    display: flex;
    justify-content: end;
}
.inputCurrency[type="checkbox"]:checked::after {
    content: '✔';
    display: block;
    color: #055041;
    font-size: 16px;
    text-align: center;
    line-height: 20px;
}
.currency_check{
    padding-left: 20px;
}

.currency_list {
    background: rgb(236 235 235 / 25%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-radius: 5px;
    line-height: 15px;
}

.leftAligned {
    flex: 1;
}

.centerAligned {
    flex: 1;
    text-align: center;
}

.rightAligned {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}
@media only screen and (max-width:1300px){
    .box_wraper{
        width:70%;
    }
}
@media only screen and (max-width:950px){
    .box_wraper{
        width:85%;
    }
}
@media only screen and (max-width:600px){
    .box_wraper{
        width:85%;
    }
}


       



