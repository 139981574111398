// Color Palette
$color-primary: #025041;
$color-primary-variant: #025141;
$color-secondary: #F6F6f6;
$color-background-light: #FFFFFF;
$color-text-primary: #282828;
$color-text-secondary: #6D6B6B;
$color-text-light: #ffffff;
$color-text-muted: #515151;
$color-border: #DDDDDD;
$color-accent: #1D1D1D;
$color-gray-light:#F8F8F8;
$color-gray-variant: #f4f4f4;

// Typography
$font-family-primary: 'Poppins', sans-serif;

// Font Sizes
$font-size-xs: 10px;
$font-size-sm: 12px;    // placeholder
$font-size-base: 13px;  //lable and also base for all
$font-size-md: 14px;    // buttons
$font-size-lg: 15px;
$font-size-xl: 16px;      //section headings
$font-size-xxl: 18px;
$font-size-heading: 20px;   //headings

// Font Weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// Spacing
$spacing-xs: 4px;
$spacing-sm: 8px;
$spacing-md: 12px;
$spacing-lg: 16px;
$spacing-xl: 24px;

// Border Radius
$border-radius-sm: 6px;
$border-radius-md: 8px;
$border-radius-lg: 10px;

// Shadows
$shadow-light: 0px 2px 11px rgba(145, 145, 145, 0.25);

//header Shadow 
$header-shadow: 0px 4px 21px #CCCCCC40;

//box shadow
$box-shadow: 0 3px 11px hsla(0, 2%, 61%, 0.25);

// Transitions
$transition-fast: 0.2s;
$transition-medium: 0.3s;
$transition-slow: 0.5s;

// Z-Index
$z-index-base: 1;
$z-index-dropdown: 10;
$z-index-modal: 100;
$z-index-tooltip: 1000;