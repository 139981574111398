.descText{
    text-align: center;
    margin: 20px;
}

.footer{
    text-align: center;
}

.closeBtn {
      background: #025041;
      border-radius: 6px;
      width: 150px;
      height: 48px;
      border: 1px solid #025041;
      margin-top: 40px;
      color: white;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin: 20px ;
  }
