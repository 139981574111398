@import '../../../../../../../../styles/variables.module.scss';


.step2_container {
    .reject {
        font-size: $font-size-xl;
        color: red;
        cursor: pointer;
    }

    .step2_heading {
        font-size: $font-size-heading;
        color: $color-text-primary;
        font-weight: $font-weight-semibold;
        font-family: "poppins" !important;
    }

    .step2_payee_data_wrapper {
        background-color: $color-secondary;
        padding: 10px 20px;

        .step2_payee_data_container {
            display: flex;
            flex-direction: row;
            column-gap: 0px;
        }

        .step2_payee_data_row {
            display: flex;
            justify-content: space-between;
        }

        .color_secondary {
            color: $color-text-secondary;
            font-family: "poppins" !important;
        }
        transition: background-color 0.5 ease-in-out;

    }
    .open{
        background-color: #ffffff;
        transition: background-color 0.5 ease-in-out;
    }
    .anchoropen{
        background-color: $color-secondary !important;
    }

    .arrowforwardicon {
        font-size: $font-size-xxl;
        cursor: pointer;
    }
    .font-base{
        font-size: $font-size-base;
        font-family: "poppins" !important;
    }
}
