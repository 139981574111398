.box_data_contean {
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.10);
    // height: calc(100vh - 80px);
// width: 100%;
height: 100%;
    .box_doument {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        .document_uploaded {
            color: #1D1D1D;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            padding-top: 15px;
        }
    }
}