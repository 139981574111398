.grid_header {
  box-shadow: 0px 4px 21px 0px #cccccc40;
  background: #ffffff;
  padding: 14px 30px;
}

.BoxButtom {
  border-bottom: 1px solid #025041;
}

.gridLayout {
  padding-top: 35px;
  display: flex;

  .classNameBox {
    padding-left: 30px;

    .box_conteansetp {
      p {
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
      }
    }
  }
}

.maindev {
  padding-top: 25px;
}

.box_conteanList {
  display: flex;
  padding-bottom: 6px;
  align-items: center;

  p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #6d6b6b;
    padding-left: 5px;
  }
}

.activeContean {
  display: flex;
  padding-bottom: 6px;
  padding-left: 10px;
  align-items: center;

  p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #025041;
    padding-left: 5px;
  }
}

@media (max-width: 900px) {

  .gridLayout {
    display: block;
  }
  .gridLayout {

  .classNameBox{
    padding-left: 60px;
  }
}
}
@media (max-width: 500px) {

  .gridLayout {
    display: block;
  }
  .gridLayout {

  .classNameBox{
    padding-left: 30px;
  }
}
}