.m-pesa-container {
  background: #ffffff;
  border-radius: 8px;
  padding: 10px 32px 32px 32px;
  //height: fit-content;
  box-shadow: 0 3px 11px hsla(0, 2%, 61%, 0.25);
  position: relative;

  &__heading {
    display: flex;
    justify-content: center;

    h4 {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #292929;
    }
  }

  &__recipient {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #000;
    margin-bottom: 30px;
    margin-top: 10px;
  }

  &__option-wrapper {
    width: 100%;
  }

  &__pay-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #dddddd;
    border-radius: 4px;
    background: #ffffff;
    height: 48px;
    padding: 10px;
    margin: 15px 0px;
    cursor: pointer;

    .option-container {
      display: flex;
      align-items: center;

      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #111111;
        margin-left: 10px;

        .bottomtext {
          font-size: 0.6em;
          line-height: 1;
          vertical-align: bottom;
          position: absolute;
          left: auto;
          margin-left: 5px;
          bottom: 17px;
          width: max-content;
        }
      }
    }

    .arrow {
      cursor: pointer;
    }
  }

  &__pay-option_two {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid red;
    border-radius: 4px;
    background: #ffffff;
    height: 48px;
    padding: 10px;
    margin: 15px 0px;
    cursor: pointer;

    .option-container {
      display: flex;
      align-items: center;

      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #111111;
        margin-left: 10px;

        .bottomtext {
          font-size: 0.6em;
          line-height: 1;
          vertical-align: bottom;
          position: absolute;
          left: auto;
          margin-left: 5px;
          bottom: 17px;
          width: max-content;
        }
      }
    }

    .arrow {
      cursor: pointer;
    }
  }

  .fee-area {
    display: flex;
    align-items: center;

    &__txt {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #b7b7b7;
      margin-right: 18px;
    }
  }
}

#ajjahaggagga {
  cursor: no-drop;
}

// DEV CODE
// .available_balance {
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   font-size: 15px;
//   margin-top: 10px;
//   padding: 10px 35px 10px 0px;

  
// }
.available_com{
  display: flex;
  justify-content: end;
  .balance_lable {
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    // letter-spacing: 0.09px;
  }
  .balance_amount {
    color: #282828;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
}
// .available_balance {
//   position: absolute;
//   top: 115px;
//   right: 0;
//   font-size: 15px;
//   padding: 0px 35px 10px 0px;

//   .balance_lable {
//     color: #000;
//     font-family: Poppins;
//     font-size: 14px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: 20px;
//     letter-spacing: 0.09px;
//   }

//   .balance_amount {
//     color: #282828;
//     font-family: Poppins;
//     font-size: 14px;
//     font-style: normal;
//     font-weight: 600;
//     line-height: 20px;
//   }
// }

.calss_accordion_contenar {
  padding-top: 12px;

  .maian_adaat_listtts {
    border-radius: 5px;
    border: 1px solid #bbb;
    background: #f1f1f1;
    display: flex;
    justify-content: space-between;
    padding: 12px 20px;
    cursor: pointer;

    .accordion_details_contean {
      background-color: #fff;

      .accordion_p_data_contean {
        color: #1d1d1d;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  .mainana_cantena_daat_potrrr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px 0px 0px;

    .Minn_button_mpesa_datata {
      border-radius: 5px;
      border: 1.5px solid #025041;
      width: 48%;
      padding: 10px;
      background-color: #fff;
      color: #000;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-align: start;
      display: flex;
      align-items: center;

      .button_heding_text {
        color: #000;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-align: start;
      }

      .des_button {
        color: #000;
        font-family: Poppins;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-align: start;
      }
    }

    .calss_not_active_data {
      border-radius: 5px;
      border: 1px solid #ddd;
      width: 48%;
      padding: 10px;
      background-color: #fff;
      color: #000;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-align: start;
      display: flex;
      align-items: center;
    }
  }
}

.imgMetheds {
  // width: 60px;
  height: 43px;
  margin-right: 13px;
}

.textTypo {
  color: #000;
  padding: 0px 10px;

  .button_heding_text {
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: start;
  }

  .des_button {
    color: #000;
    font-family: Poppins;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: start;
  }
}

.description {
  font-size: 10px;
  color: #282828;
}

.description_limit {
  font-size: 10px;
  color: #282828;
  margin-top: -10px;
}

.rtgf_eft_btn {
  text-align: center;
  padding: 11px 30px !important;
  border: 1px solid #025041;
  align-items: center;
  max-width: 14rem;
  justify-content: center;
  border-radius: 6px;
  white-space: nowrap;
  font-size: 14px !important;
  width: 100%;
  margin-right: 10px;
}

.rtgf_eft_btn:hover {
  color: #000;
  text-align: center;
  padding: 11px 30px !important;
  border: 1px solid #025041;
  align-items: center;
  max-width: 14rem;
  justify-content: center;
  border-radius: 6px;
  white-space: nowrap;
  font-size: 14px;
  width: 100%;
  margin-right: 10px;
}

.pesalink_btn {
  text-align: center;
  padding: 11px 30px !important;
  border: none;
  align-items: center;
  background-color: #025041 !important;
  max-width: 14rem;
  justify-content: center;
  border-radius: 6px;
  white-space: nowrap;
  font-size: 14px;
  width: 100%;
  margin-left: 10px;
}

.pesalink_btn:hover {
  font-size: 14px !important;
  text-align: center;
  padding: 11px 30px !important;
  border: none;
  align-items: center;
  background-color: #025041 !important;
  max-width: 14rem;
  justify-content: center;
  border-radius: 6px;
  white-space: nowrap;
  color: #fff;
  width: 100%;
  margin-left: 10px;
}

.limitModal_heading {
  color: #1d1d1d;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  // line-height: 24px;
  // text-align: start;
  padding: 0px 0px 0px 15px;
  line-height: 21px;
  text-align: center;
}

.limitModal_Buttons {
  display: flex;
  justify-content: center;
}

.buttons_wrapper {
  display: flex;
}

@media only screen and (max-width: 450px) {
  .m-pesa-container {
    padding: 20px;
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .buttons_wrapper {
    display: block;
  }

  .rtgf_eft_btn {
    margin-bottom: 10px;
    margin-right: 0px;
  }

  .rtgf_eft_btn:hover {
    margin-bottom: 10px;
    margin-right: 0px;
  }

  .pesalink_btn {
    margin-left: 0px;
  }

  .pesalink_btn:hover {
    margin-left: 0px;
  }
}