@import "../../../../styles/variables.module.scss";

.label{
    font-size: $font-size-base !important;
    font-family: "Poppins";
    margin-bottom: 1px;
}
.css-ux17pc-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected{
    background-color: #025041 !important;
}
.css-4k4mmf-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    background-color : #025041 !important ;
  }
.css-innj4t-MuiPickersYear-yearButton.Mui-selected{
    background-color: #025041 !important;
}

