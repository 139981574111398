.divclassName_linkpage {
    border-radius: 10px;
    background: #FFF;
    height: 60vh;
    box-shadow: 0px 1px 10px 0px rgba(17, 17, 17, 0.10);
    
    display: flex;
    justify-content: center;
    align-items: center;

    .className_mainlikadd {

        p {
            color: #1D1D1D;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            padding-bottom: 5px;
        }

        .Typography_div_clsss {
            border-radius: 5px;
            border: 1px solid #B8B8B8;
            padding: 14px 60px 14px 9px;

        }
    }
}
.GOback_button {
    color: #1D1D1D;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    background-color: #fff;
    border: 0px solid;
    padding: 0px;
    margin-top: 60px;
    margin-bottom: 15px;
}
.GOback_button:hover{
background-color: #fff;
}
.GOback_button:active{
background-color: #fff !important;
}
.button_threeorr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding-top: 40px;

    button {
        border-radius: 5px;
        background: #025041;
        color: #FFF;
        border: 0px solid;

        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        width: 116px;

        svg {
            margin-right: 8px;
        }
    }
    button:hover {
        background: #025041;
        color: #FFF;
    }
    button:active {
        background: #025041;
        color: #FFF;
        background-color: #025041 !important; 
    }
    button {
        background: #025041;
        color: #FFF;
        background-color: #025041;
    }
}