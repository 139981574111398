.contenar_main_text_line {
    text-align: center;
    padding: 30px 0px 0px 0px;
    border-radius: 10px;
    background: #FFF;
    width: 40%;
    margin: 30px auto;
    box-shadow: 0px 1px 10px 0px rgba(17, 17, 17, 0.15);

}

.text_err_contenarr_minn {
    color: #D24949;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 15px;
}

.des_Pesalink {
    color: #1D1D1D;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 18px 40px 35px 40px;
}

.Cancel_Button {
    border-radius: 5px;
    border: 1px solid #025041;
    color: #025041;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    width: 47%;
    height: 34px;

}

.Cancel_Button:hover {
    border-radius: 5px;
    border: 1px solid #025041;
    color: #025041;
}

.button_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px 30px 30px;
}

.Continue_button {
    border-radius: 5px;
    background: #025041;
    color: #FFF;
    border-color: #025041;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    width: 47%;
    height: 34px;
}
.Continue_button:hover {
    background: #025041;
    border-color: #025041;
    color: #FFF;
}
.Continue_button:active {
    background: #025041 !important;
    border-color: #025041 !important;
    color: #FFF !important;
    background-color: #025041 !important;
}
.Continue_button:focus-visible {
    background: #025041 !important;
    border-color: #025041 !important;
    color: #FFF !important;
    background-color: #025041 !important;
}