.ContenarInvoice {
    background: #FFFFFF;
    box-shadow: 0px 4px 21px rgba(204, 204, 204, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 32px 18px 32px;

    .typo_heding {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 700;
        line-height: 20px;
        text-align: left;
        color: #025041;

    }
}

.ContenarTebal {
    padding: 38px 43px;
}

.fast_button_data {
    border-radius: 8px;
    border: 1px solid #025041;
    height: 40px;
    padding: 13px 10px;
    color: #025041;
    width: 49%;
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    background-color: #FFF;
}

.fast_button_data:hover {
    background-color: #FFF;
    border: 1px solid #025041;
}

.two_button_invoice {
    border-radius: 8px;
    background: #025041;
    padding: 13px 19px 13px 19px;
    color: #FFF;
    width: 49%;
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    border: 0px solid;
}

.two_button_invoice:hover {
    border: 0px solid;
    background: #025041;
    color: #FFF;
}

.StatusTypoRejected {
    background: #F6F7F7;
    padding: 1px 10px;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    background: #1D1D1D;

}

.imgContean {
    display: flex;
    align-items: center;

    p {
        color: #000;
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        text-align: left;
        line-height: 20px;
        padding-left: 10px;
    }
}

.bottom_BUTTON {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.contenar_tebal_liner th {
    border-bottom: 0px solid;
}

.StatusTypoissued {
    padding: 1px 10px;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #257D6B;
    width: min-content;
}

.grid_Contean_classs {
    display: flex;
    align-items: center;
}
.typo_invoiceGrid{
    font-family: Poppins;
font-size: 16px;
font-weight: 600;
line-height: 20px;
text-align: left;
color: #1D1D1D;
padding-left: 16px;

}
@media only screen and (max-width: 450px) {

    .ContenarTebal {
        padding: 38px 14px;

    }

    .grid_Contean_classs {
        display: flex;
        justify-content: end;
        padding-bottom: 10px;
    }
}