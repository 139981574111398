.product_btn_wrapper {
    // width: 100%;
    // display: flex;
    // padding-right: 20px !important;
    // padding-left:20px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding: 12px 16px;
    border-radius: 6px;
}

@media only screen and (min-width:520px) and (max-width:600px){
    .custom_mobile_btn_width{
        min-width:120px !important;
    }
}