@import "../../../../../../../../styles/variables.module.scss";


.step1_container{
    color:$color-text-primary;
    .bills_heading{
        font-size: $font-size-xxl;
        font-weight: 500;
        font-family: "Poppins";
    }
    .payee_name{
       font-size: $font-size-lg;
       font-family: "Poppins";
    }
    .payee_number{
        font-size:$font-size-base;
        color:$color-text-secondary;
        font-family: "Poppins";
    }
    .amount{
        font-size: $font-size-lg;
        font-family: "Poppins";
    }
    .bill_number{
        font-size:$font-size-base;
        color:$color-text-secondary;
        font-family: "Poppins";
    }
    .no_bill_found{
        width:100%;
        height:160px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding:10px 20px;
        text-align: center;
    }
    .no_bill_found_text{
        font-family: "Poppins";
        font-size: $font-size-lg    ;
    }
}