.ContenarInvoice {
    background: #FFFFFF;
    box-shadow: 0px 4px 21px rgba(204, 204, 204, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 32px 18px 32px;

    .typo_heding {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 700;
        line-height: 20px;
        text-align: left;
        color: #025041;

    }
}

.ContenarTebal {
    padding: 38px 43px;
}

.StatusTypoRejected {
    background: #F6F7F7;
    padding: 1px 10px;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    background: #1D1D1D;

}

.imgContean {
    display: flex;
    align-items: center;

    p {
        color: #000;
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        text-align: left;
        line-height: 20px;
        padding-left: 10px;
    }
}

.contenar_tebal_liner th {
    border-bottom: 0px solid;
}

.StatusTypoissued {
    padding: 1px 10px;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #257D6B;
    width: min-content;
}
.GoBACKBUTTONTYLE {
    background-color: #fff;
    border: 0px solid;
    color: #000;
    margin-bottom: 20px;
}
.GoBACKBUTTONTYLE:hover {
    background-color: #fff;
    border: 0px solid;
    color: #000;
}
.GoBACKBUTTONTYLE:active {
    background-color: #fff !important;
    border: 0px solid !important;
    color: #000 !important;
}