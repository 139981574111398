@import "../../../../../styles/variables.module.scss";

.fields_container{
    width: 100%;
    // background-color: $color-background-light;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: visible !important;
    .edit_heading{  
        font-size:20px;
        font-weight: $font-weight-semibold;
        color:$color-text-primary;
    }
    .required_field:after {
        content: "";
        display: inline-block;
        margin-left: 7px;
        vertical-align: middle;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: rgb(255, 71, 93);
    }
}

