/* RolePopup.css */
.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr; 
  gap: 16px; 
  margin-top: 16px;
}

.card {
  background: #F2F8F7;
  border-radius: 8px;
  padding: 15px 18px;
  margin-bottom: 20px; 
  width: 100%;
}

.card h5 {
  margin-top: 0;
  margin-bottom: 10px;
}

.card h3 {
  margin-top: 10px;
  margin-bottom: 5px;
}

.card ul {
  padding-left: 20px;
  margin: 0;
}

.card ul li {
  list-style-type: disc;
}

.topup-modal3 .modal-content {
  width: 600px;
  background: #ffffff;
  max-height:600px; 
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.topup-modal3 .modal-header {
  padding: 20px 32px;
  flex-shrink: 0;
}

.topup-modal3 .modal-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: 0.03em;
  color: #111111;
}

.topup-modal3 .modal-body {
  padding: 16px 32px;
  flex-grow: 1; 
  outline: none; 
  overflow-y:scroll;
}

.topup-modal3 .modal-footer {
  padding: 16px 32px; 
  flex-shrink: 0; 
  display: flex;
  justify-content: flex-end; 
  position: sticky;
  bottom: 0;
  background: white;
}

.topup-modal3 .label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #000;
}

.topup-modal3 .type-card-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  margin-top: 16px;
}

.topup-modal3 .type-card {
  background: #F2F8F7;
  border-radius: 8px;
  padding: 15px 18px;
  height: 128px;
  cursor: pointer;
  width: 100%;
}

.topup-modal3 .type-card svg {
  margin-right: 9px;
}

.topup-modal3 .type-card__name {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #282828;
}

.topup-modal3 .type-card__detail {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.08em;
  color: #000;
  margin-top: 13px;
}

.topup-modal3 .active {
  border: 1.6px solid #025041;
}

.topup-modal3 .btn {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  height: 48px;
  padding: 12px 36px;
  border-radius: 6px;
}

.topup-modal3 .btn:disabled {
  background: #F3F3F3 !important;
  border: none;
  color: #111111 !important;
  opacity: 1;
}

.topup-modal3 .white-btn {
  background-color: #fff !important;
  border-color: #015241 !important;
  color: #000;
  padding: 11px 25px;
}

.topup-modal3 .green-btn {
  background: #025041 !important;
  color: #ffffff !important;
}

/* Custom Scrollbar */
.topup-modal3 .modal-body::-webkit-scrollbar {
  width: 12px; 
}

.topup-modal3 .modal-body::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

.topup-modal3 .modal-body::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 10px; 
  border: 3px solid #f1f1f1;
}

.topup-modal3 .modal-body::-webkit-scrollbar-thumb:hover {
  background: #555; 
}