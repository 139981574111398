.box_contenar_tebal {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 2px 14px 2px rgba(174, 174, 174, 0.25);
    margin-top: 23px;
}

.tag {
    &_color {
        color: white;
        border-radius: 4px;
        padding: 1px 8px;
        white-space: nowrap;
        cursor: default;
        font-size: 13px;
    }

    &_payin-tag {
        background-color: #009E7E;
    }

    &_payout-tag {
        background-color: #21144c;
    }

    &_fees-tag {
        background-color: #F87161;
    }
}

.text {
    font-size: 10px;

    &_payin-tag {
        color: #009E7E;
        font-size: 13px;

    }

    &_payout-tag {
        color: #21144c;
        font-size: 13px;

    }

    &_fees-tag {
        color: #F87161;
        font-size: 13px;

    }
}

.LinksTableCell {
    color: #1D1D1D;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    padding: 15px 10px;
    border: 0px solid !important;
}
#amountColam{
    border: 0px solid !important;
}
.TableCell_roww {
    padding: 15px 10px;
    border: 0px solid !important;
    p {
        color: #000;

        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;

    }
}

.viwe_buttonTypography {
    color: #2E2E2E;
    cursor: pointer;
    font-family: Poppins;
    font-size: 10px;
    font-style: italic;
    font-weight: 500;
    line-height: 20px;
    /* 200% */
    letter-spacing: 0.3px;
    text-decoration-line: underline;

}

.div_Buttondiv {
    display: flex;
    justify-content: end;
    padding-top: 15px;
}

@media only screen and (max-width: 450px) {

    #id_gobackbutton {
        width: 137px !important;
    }
}