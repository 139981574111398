.main_card_contean_data {
    display: flex;
    align-items: center;
    padding: 0px 27px;

}

.baahhajhajaha {
    background: #F0F0F0;
}

.tebal_contean_data {
    margin-top: 4px;
}

.ajSDjkjadjdkdjd>tr:nth-child(even) {
    background: #F9F9F9;
}

.Failed_calss {
    color: #FF3B24;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 200% */
    letter-spacing: 0.3px;
}

.Success_calss {
    color: #007AFF;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 200% */
    letter-spacing: 0.3px;
}

.Pending_calss {
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 200% */
    letter-spacing: 0.3px;

}

.main_contenar_listts_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.heding_tesyssjsj {
    color: #025041;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;

}

.topup_modal .modal-content {
    width: 600px;
    background: #ffffff;
    height: auto;
    margin: 0 auto;
}

.TypographyStasssowa {
    font-family: Poppins;
    font-size: 14px;
    color: #000;
    font-weight: 400;
}

@media only screen and (max-width: 450px) {

    .main_card_contean_data {
        display: block;
    }
}