.Customers_grid_container {
    padding: 50px;

    table {
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 2px 14px 2px rgba(174, 174, 174, 0.25);
        border-radius: 8px;
        padding: 6px;

        td,
        th {
            text-align: start;
            // padding: 14px 45px 14px 8px;
        }

        th {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.01em;
            color: #000000;
        }

        td {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.03em;
            color: #000;
        }

        td:first-child {
            // padding: 14px 10px 14px 20px;
        }

        tr:nth-child(even) {
            background: #ffffff;
        }
    }

    .Customers_TableContainer {
        box-shadow: 0px 2px 14px 2px #AEAEAE40;
        // margin-top: 20px;
    }
}
.typo_textname{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.03em;
    color: #000;
    max-width: 150px;
}
.textStyle{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 0.03em;
    color: #000;
}
.selectBoxcalss{
    border-radius: 100px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12 px;
    line-height: 20px;
    letter-spacing: 0.03em;
    color: #383838;
}
.optionSelect{
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12 px;
    line-height: 20px;
    letter-spacing: 0.03em;
    color: #383838;
}
.Total_maindiv {
    display: flex;
    align-items: center;
    // justify-content: space-between;

    .TypographystylesHeading {
        font-family: Poppins;
        font-size: 13px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: left;
        color: #515151;
        padding-right: 6px;
    }

    .Typographystyles {
        font-family: Poppins;
        font-size: 13px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: left;
        color: #1D1D1D;

    }
}

.infoclass {
    display: flex;
    align-items: center;

    .Typographystyles_infoclass {
        font-family: Poppins;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #1D1D1D;
        padding-left: 10px;
    }
}

.box_dialog_main {
    padding: 14px 32px;
}

.typo_text {
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 16px !important;
    text-align: center;
    color: #282828 !important;
}

.grid_senter {
    background-color: #FFFFFF;
    height: 170px;
}

.box_btn_cencal {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
}

.delete_btn {
    font-family: 'Poppins' !important;
    font-size: 14px !important;
    color: #FF3A23 !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
    background-color: #fff !important;
    border: 0px solid !important;

}

.cancel_btn {
    font-family: 'Poppins' !important;
    font-size: 14px !important;
    color: #025141 !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
    background-color: #fff !important;
    border: 0px solid !important;
}

.addnewbtnclass {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    padding-top: 13px;
}

.pageNeshan {
    display: flex;
}

.inpagenum {
    padding: 0px 20px 0px 0px;

    p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 0px;
    }
}

.nextntmistish {
    background-color: transparent;
    border: none;
    margin-left: 20px;
}
@media only screen and (max-width: 600px) {
    .currency_select_box{
        width:100% !important;
    }
}
@media only screen and (max-width: 450px) {

    .Customers_grid_container {
        overflow: scroll;
        padding: 15px;
    }
    .selectBox{
        width: 100% !important;
    }
}