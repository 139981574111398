@import '../../../../../../styles/variables.module.scss';
*{
    font-family: 'Poppins';
}
button{
    border:none;
    outline:none;
    background-color: $color-background-light;
    border-radius: 5px;
}
