
.flex_container {
    display: flex;
    gap: 20px;
}
.heading{
    padding-top: 40px;
}

.type_card {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: #F2F8F7;
    border-radius: 8px;
    padding: 40px 20px 40px 20px;
    height: auto;
    flex: 1;
    cursor: pointer;
    text-align: center;
}

.sub_heading {
            color: black;
            padding-bottom: 30px;
                font-family: "Poppins";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 24px;
}

.heading_text {
font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    padding-bottom: 15px;
}

.desc_text {
   font-family: "Poppins";
   font-style: normal;
   font-size: 13px;
}

