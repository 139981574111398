@import "../../../../../../../../../../styles/variables.module.scss";
*{
    font-family: "Poppins" !important;
}
.schedule_step1_container{
    .schedule_heading{
        font-size: $font-size-heading;
        font-weight:$font-weight-semibold;
        margin-bottom:20px;
        font-family: "Poppins";
    }
    .label{
        font-size: $font-size-base !important;
        font-family: "Poppins";
        margin-bottom: 10px;
    }
    .schedule_payment_detail_box{
        background-color:$color-secondary;
        padding:20px 20px;
        margin:30px 0px 20px 0px;
        font-family: "Poppins";
    }


}
.color-secondary{
    color:$color-text-secondary;
}

