@import "../../../../../../../../styles/variables.module";

.mpesa_btn{
    width:30%;
    background-color: $color-secondary !important;
    color:$color-text-primary !important;
    font-family: "Poppins";
    text-transform: none !important;
}

.label{
    font-size: $font-size-base;
    font-family: "Poppins";
}
.active-btn {
    border: 1px solid $color-primary;
  }
  



