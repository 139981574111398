@import "../../../../../styles/variables.module.scss";


.schedule_noti_box{
    background: #FFFFFF;
    border-radius: 8px;
    padding: 38px;
    width: 600px;
    box-shadow: $box-shadow;
    font-family: "Poppins";
    .transaction_noti_text{
        color: #474747;
        text-align: center;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        margin-top: 15px;
    }
}
