.customer_btn_wrapper{
    display: flex !important;
    justify-content: space-between !important;
    // padding-left: 30px !important;
    // padding-right:30px !important;
}
@media only screen and (min-width:520px) and (max-width:600px){
    .custom_customer_btn_width{
        min-width:120px !important;
        padding: 12px 12px !important;
    }
}