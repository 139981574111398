.tebal_class_contean {

  .LinksTableCell {
    color: #1D1D1D;

    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    padding: 15px 10px;

  }

  .TableCell_roww {
    padding: 15px 10px;
    border: 0px solid !important;

  }
}

.Typography_Box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  // border: 0.5px solid #BBB;
  // padding: 7px 16px;

  p {
    color: #1D1D1D;
    text-decoration: underline;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .button_ContentCopyIcon {
    background-color: transparent !important;
    color: #1D1D1D;
    border: 0px solid !important;
    padding: 0px;

    svg {
      font-size: 17px;
    }
  }

  .button_ContentCopyIcon:hover {
    color: #1D1D1D;
  }
}

.inactiveTypography {
  color: #1D1D1D;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.TypographyStatus {
  color: #025041;

  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.TypographyCreated_at {
  color: #1D1D1D;

  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.TypographyUpdated_at {
  color: #1D1D1D;

  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.Request_PaymentTebalGrid {
  padding: 50px;
}

.TableContainerdata {
  border-radius: 8px;
  background: #FFF;
  // margin-top: 31px;
  box-shadow: 0px 2px 14px 2px rgba(174, 174, 174, 0.25);
}

.react-datepicker__input-container {
  height: 48px;
  border-radius: 50px;
  background: #ffffff !important;

  input {
    height: 48px;
    border-radius: 50px;
    background: #ffffff !important;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.03em;
    color: #383838 !important;
    margin: 0 0px 15px;
    width: 100%;

    &::placeholder {
      color: #383838 !important;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.33px;
    }

    &::placeholder:hover {
      color: #515151;
      color: white;
    }

    &::placeholder:focus:hover {
      color: white;
    }

    &::placeholder:hover {
      color: #ffffff !important;
    }

    &:focus::-webkit-input-placeholder,
    &:hover::-webkit-input-placeholder {
      color: #ffffff !important;
    }

    &:focus {
      box-shadow: 0px 0px 0px 4px rgba(1, 82, 65, 0.25);
      border-color: #015241;
    }

    &:hover {
      color: #515151;
      background-color: #3f7268;
      box-shadow: 0px 0px 0px 4px rgba(1, 82, 65, 0.25);
    }
  }
}

@media only screen and (max-width: 450px) {

  .Typography_Box {
    display: block;
  }
}