@import "../../../../styles/_variables.module.scss";

// gloabal
*{
    box-sizing: border-box;
    padding:0;
    margin:0;
}
.auto_container{
    width:90%;
    margin-right: auto;
    margin-left: auto;
}


.makepayment_header{
    background-color:$color-background-light;
    height: 86px;
    color:#fff;
    display: flex;
    justify-content: center;
    box-shadow:$header-shadow;
    .header_name{
        font-size:$font-size-xxl;
        font-weight:$font-weight-bold;
        color:$color-primary;
        margin:0px;
    }
}
.header_auto_container{
    width:90%;
    margin:auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}


.payee_header_text{
    font-weight: 600;
    font-size: 20px;
}
