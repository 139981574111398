.counterparty {
    &__header {
        background: #FFFFFF;
        box-shadow: 0px 4px 21px rgba(204, 204, 204, 0.25);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 43px;

        &__name {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 20px;
            font-family: "Poppins";
            color: #025041;
        }
    }

    .btn {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        padding: 12px 16px;
        border-radius: 6px;
    }

    &__white-btn {
        border: 1px solid #025041 !important;
        background-color: white !important;
        color: #025141 !important;
        margin-right: 12px;

    }

    &__save-btn {
        border: 1px solid #025041 !important;
        background-color: #025141 !important;
        color: #FFFFFF !important;
        margin-right: 0px;
    }

    h1 {
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        color: #025041;
        font-family: "Poppins";
        margin-left: 0px;
        padding-left: 0px;
    }


}

.main_contenar_listts {

    .box_contean_data_contenar {
        width: 500px;
        margin: auto;
        border-radius: 10px;
        background: #FFF;
        box-shadow: 0px 3px 11px 0px rgba(158, 155, 155, 0.25);
        padding: 20px 35px 20px 35px;

        .p_tage_cerete_data {
            color: #1D1D1D;
            font-family: Poppins;
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            text-align: center;
        }

        .main_fast_and_last {
            display: flex;
            justify-content: space-between;
            align-items: center;

            // .fast_name_inpurt_div {
            //     width: 48%;
            // }
        }

        .maib_redio_button_contenar {
            flex-direction: initial;

            .main_redio_buttton {
                span {
                    font-family: Poppins;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: #000;
                }
            }
        }


        .calss_accordion_contenar {
            padding-top: 12px;

            .maian_adaat_listtts {
                border-radius: 5px;
                border: 1px solid #BBB;
                background: #F1F1F1;
                display: flex;
                justify-content: space-between;
                padding: 12px 20px;
                cursor: pointer;

                .accordion_details_contean {
                    background-color: #FFF;

                    .accordion_p_data_contean {
                        color: #1D1D1D;
                        font-family: Poppins;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                    }
                }
            }

            .mainana_cantena_daat_potrrr {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 0px 0px 0px;

                .Minn_button_mpesa_datata {
                    border-radius: 5px;
                    border: 1.5px solid #025041;
                    width: 48%;
                    padding: 10px;
                    background-color: #FFF;
                    color: #000;
                    font-family: Poppins;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    text-align: start;
                }

                .calss_not_active_data {
                    border-radius: 5px;
                    border: 1px solid #DDD;
                    width: 48%;
                    padding: 10px;
                    background-color: #FFF;
                    color: #000;
                    font-family: Poppins;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    text-align: start;
                }
            }
        }

        .main_buuton_line_logg {
            padding: 20px 0px 10px 0px;
        }

    }
}
.classname_customer{
    font-family: Poppins;
     font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #000;
}
@media only screen and (max-width: 820px) {

    .height {
        padding: 10px 17px !important;

    }
}

@media only screen and (max-width: 450px) {
    .main_contenar_listts {

        .box_contean_data_contenar {
            width: 100%;
            padding: 20px 15px 20px 15px;

        }
    }

    .counterparty {
        h1 {
            padding-left: 0px;
            margin-left: 0px;

        }

        &__save-btn {
            margin-right: 0px;
            padding: 9px 4px !important;
            

        }

        &__white-btn {
            // padding: 9px 4px !important;
            // margin-right: 10px;
            margin-right: 10px !important;
            // padding: 9px 4px !important;
            padding: 9px 4px !important;
        }

    }

    .styles_main_tesffsffs__VVD-X {
        padding: 16px 10px;

    }
}