.main_dic_contenar {
    background: #F6F6F6;
    height: 100%;
    padding: 6px 36px;
    margin-bottom: 3px;

    .box_div_conteanararaa {
        border-radius: 5px;
        background: #FFF;
        padding: 15px 20px;

        .Typography_Documentdetails_text {
            color: #000;
            font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            padding-bottom: 13px;
        }

        .main_div_divfooter_Recurring {
            border-radius: 5px;
            border: 0.5px solid #DDD;
            display: flex;
            justify-content: space-between;

            .row_text_conteann_hafsize {
                width: 52%;
                display: flex;
                justify-content: space-between;

                .fast_text_lineen {
                    width: 40%;
                    color: #1D1D1D;
                    font-family: Poppins;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    padding: 5px 10px 5px 15px;


                }

                .two_text_conteanana {
                    color: #1D1D1D;
                    width: 60%;
                    font-family: Poppins;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    padding: 5px 10px;


                }

                .threed_test_data {
                    color: #007AFF;
                    width: 50%;
                    font-family: Poppins;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    padding: 5px 10px;

                }
            }

            .row_text_conteann_hafsize_End_date {
                width: 48%;
                display: flex;
                justify-content: space-between;

                .fast_text_lineen {
                    width: 40%;
                    color: #1D1D1D;
                    font-family: Poppins;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    padding: 5px 10px 5px 15px;


                }

                .two_text_conteanana {
                    color: #1D1D1D;
                    width: 60%;
                    font-family: Poppins;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    padding: 5px 10px;


                }
            }
        }

        .main_div_divfooter {
            border-radius: 5px;
            border: 0.5px solid #DDD;


            .row_text_conteann {
                display: flex;
                border-bottom: 0.5px solid #DDD;

                .fast_text_lineen {
                    width: 48%;
                    color: #1D1D1D;
                    font-family: Poppins;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    padding: 5px 10px 5px 15px;


                }

                .two_text_conteanana {
                    color: #1D1D1D;
                    width: 50%;
                    font-family: Poppins;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    padding: 5px 10px;


                }

                .threed_test_data {
                    color: #007AFF;
                    width: 50%;
                    font-family: Poppins;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    padding: 5px 10px;

                }
            }
        }
    }
}

.main_lineitem {
    border-radius: 5px;
    border: 0.5px solid #DDD;

    .main_div_other_div {
        display: flex;
        width: 100%;

        .main_div_name {
            // width: 17%;
            border-right: 0.5px solid #DDD;

            .typo_topdivv {
                padding: 4px;

                p {
                    color: #1D1D1D;

                    font-family: Poppins;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    text-align: center;
                }
            }

            .typo_des_datatata {
                padding: 4px;

                p {
                    color: #1D1D1D;
                    text-align: center;

                    font-family: Poppins;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                }
            }
        }
    }
}

.cls_main_div_bill {
    display: flex;
    justify-content: space-between;

    .cls_withe_porsann {
        width: 49%;
        border-radius: 5px;
        border: 0.5px solid #DDD;

        .main_porsan_adafafaf {
            display: flex;


            .main_dixv_kdjdhdhd {
                width: 50%;
                border-right: 0.5px solid #DDD;

                .typo_text_kryrry {
                    color: #1D1D1D;
                    font-family: Poppins;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    padding: 6px 10px 3px 10px;
                }

                .main_div_conteananana {
                    color: #1D1D1D;
                    padding: 6px 10px 3px 10px;
                    font-family: Poppins;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                }
            }
        }
    }
}

.Bill_lifecyclediv {
    border-radius: 5px;
    border: 0.5px solid #DDD;
    padding: 16px 12px;
    display: flex;
    flex-direction: column;
position: relative;
gap: 15px;
    .conteanBill_lifecycl {
        display: flex;
        justify-content: space-between;
        align-items: center;


        .clsTypographydiv {
            width: 48%;
            display: flex;
            // justify-content: space-between;
            position: relative;

            .Typography_icon {
                display: flex;
                // align-items: center;

                svg {
                    position: absolute;
                    left: -4px;
                    top: 6px;
                }
            }

            .clsTypographyheding {
                color: #1D1D1D;
                font-family: Poppins;
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                padding-left: 10px;
            }

            .clsTypographyname {
                color: #1D1D1D;
                font-family: Poppins;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                padding-left: 15px;
            }

        }

        .Submitted_datedev {
            width: 48%;
            display: flex;
            // justify-content: space-between;

            .TypographySubmittedheding {
                color: #1D1D1D;
                font-family: Poppins;
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
            }

            .dateTypography {
                color: #1D1D1D;
                font-family: Poppins;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                padding-left: 15px;
            }
        }
    }

    .bottum_spees {
        position: absolute;
        background: #D9D9D9;
        /* height: 2px; */
        width: 1px;
        height: 35px;
        top: 29px;
    }
}

@media only screen and (max-width: 450px) {
    .main_dic_contenar {
        padding: 3px 15px;

    }

    .cls_main_div_bill {
        display: block;

        .cls_withe_porsann {
            width: 100%;
        }
    }
}