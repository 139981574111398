@import "../../../../../../../../styles/variables.module.scss";

.recent_payee_container {
    padding-top: 20px;
    font-family: "Poppins";

    .recent_payee_heading {
        font-size: 17px;
        color: $color-text-primary;
        font-weight: $font-weight-medium;
        font-family: "Poppins";
    }
}

.list_all_payee_btn {
    color: $color-text-secondary;
    border: 1px solid $color-text-secondary;
    border-radius:7px;
    min-width: 50px;
    padding: 2px 5px;
    font-size: $font-size-md;
    font-family: "Poppins";

    &.active {
        color: $color-text-primary;
        border: 1px solid $color-text-primary;
    }
}

.list_frequent_payee_btn {
    color: $color-text-secondary;
    border: 1px solid $color-text-secondary;
    border-radius:7px;
    padding: 2px 15px;
    font-size: $font-size-md;
    font-family: "Poppins";

    &.active {
        color: $color-text-primary;
        border: 1px solid $color-text-primary;
    }
}


