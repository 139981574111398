@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.imgBx {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  width: 50%;
  height: 100%;
}

.imgBx:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.imgBx .background-img img {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 99vh;
  object-fit: cover;
}

.logo-corporate {
  position: absolute;
  bottom: 0px;
  transform: translate(0px, 0px);
  width: 100%;
  height: 40vh;
  background: linear-gradient(225deg, #025041, #fff);
  mix-blend-mode: screen;
}

.logo-niobi {
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  width: 359px;
  height: 256px;
  transform: translate(150px, 150px);
}

.link {
  display: flex;
  color: #000;

  gap: 15px;
  transition: all 0.5s;
}

.link:hover {
  color: #025041;
  transition: all 0.5s;
}

.active {
  background: rgb(102, 117, 102);
  color: #000;
}

.icon,
.link_text {
  font-size: 20px;
}

.formBx {
  box-sizing: border-box;
  background: none;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 16px;
    gap: 24px;
  /* border-right: 2px solid #f1f1f1; */

  width: 496px;

  background: #ffffff;
  /* New brand/Grey 4 */

  border: 1px solid #dddddd;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}
.formBx_tow {
  box-sizing: border-box;
  background: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 16px;
  gap: 24px;
  /* border-right: 2px solid #f1f1f1; */
  background: #ffffff;
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.login {
  text-align: center;
  color: #025041;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.register-business {
  text-align: center;
  color: #025041;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.login p {
  font-family: "Faktum";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 0px;
}

.formBx h2 {
  color: #025041;
  font-weight: 600;
  font-size: 1.5em;
  display: inline-block;
  margin-bottom: 20px;
  letter-spacing: 1px;
}
.formBx_tow h2 {
  color: #025041;
  font-weight: 600;
  font-size: 1.5em;
  display: inline-block;
  margin-bottom: 20px;
  letter-spacing: 1px;
}
.formBx .inputBx span {
  width: 40%px;
  height: 20px;
  font-family: "Faktum";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #025041;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.formBx_tow .inputBx span {
  width: 40%px;
  height: 20px;
  font-family: "Faktum";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #025041;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.formBx .inputBx input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  gap: 8px;
  width: 100%;
  height: 48px;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 1;
  font-size: 12px;
}
.formBx .inputBx select {
  font-size: 12px;

}
.formBx_tow .inputBx input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  gap: 8px;
  width: 100%;
  height: 48px;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 1;
  font-size: 12px;
}

.formBx .inputBx input:focus,
.formBx .inputBx select:focus {
  border-color: #025041;
  box-shadow: 0 0 0 0.25rem #0250414d;
}

.formBx .inputBx input[type="button"] {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  gap: 8px;
  margin-top: 10px;

  width: 60%;
  height: 48px;
  color: #fff;
  background: #025041;
  border-radius: 6px;
  flex: none;
  order: 0;
  flex-grow: 1;
}
.formBx_tow .inputBx input:focus,
.formBx_tow .inputBx select:focus {
  border-color: #025041;
  box-shadow: 0 0 0 0.25rem #0250414d;
}

.formBx_tow .inputBx input[type="button"] {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  gap: 8px;
  margin-top: 10px;

  width: 60%;
  height: 48px;
  color: #fff;
  background: #025041;
  border-radius: 6px;
  flex: none;
  order: 0;
  flex-grow: 1;
}
.verification-button {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.formBx .inputBx-mobile input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 48px;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 1;
  padding: 0px 50px;
}

.formBx .inputBx input:hover {
  border-color: #023d41;
  box-shadow: rgba(22, 211, 123, 0.2) 0px 0px 0px 4px 2px 2px 2px 2px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}
.formBx_tow .inputBx-mobile input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 48px;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 1;
  padding: 0px 50px;
}

.formBx_tow .inputBx input:hover {
  border-color: #023d41;
  box-shadow: rgba(22, 211, 123, 0.2) 0px 0px 0px 4px 2px 2px 2px 2px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}
.form-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  gap: 8px;
  width: 368px;
  height: 48px;
  background: #023d31;
  border-radius: 6px;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.formBx .inputBx input[type="submit"]:hover {
  background: #023d31;
}
.formBx .inputBx input[type="tel"] {
  padding: 0px 45px;
}

.formBx .remember {
  margin-bottom: 10px;
  color: #023d41;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  display: flex;
}

.formBx .remember input[type="checkbox"] {
  margin-right: 0.2rem;
}

.formBx .remember .link {
  font-family: "Faktum";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  color:  #023d41;;
  flex: none;
  order: 0;
  width: 100%;
  flex-grow: 0;
}
.formBx .inputBx p {
  width: 100%;
  height: 20px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: black;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.formBx_tow .inputBx input[type="submit"]:hover {
  background: #023d31;
}
.formBx_tow .inputBx input[type="tel"] {
  padding: 0px 45px;
}

.formBx_tow .remember {
  margin-bottom: 10px;
  color: #023d41;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  display: flex;
}

.formBx_tow .remember input[type="checkbox"] {
  margin-right: 0.2rem;
}

.formBx_tow .remember .link {
  font-family: "Faktum";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  color:  #023d41;;
  flex: none;
  order: 0;
  width: 100%;
  flex-grow: 0;
}
.formBx_tow .inputBx p {
  width: 100%;
  height: 20px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: black;
  flex: none;
  order: 0;
  flex-grow: 0;
}
@media (max-width: 500px) {
  .imgBx {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .formBx {
    width: 88%;
    padding: 20px 20px;
    background: white;
    margin: 5px;
  }
  .formBx_tow {
    width: 100%;
    /* padding: 20px 20px; */
    background: white;
    margin: 0px;
  }
  .formBx h3 {
    color: #607d8b;
    text-align: center;
    margin: 30px 0 10px;
    font-weight: 500;
  }
  .formBx_tow h3 {
    color: #607d8b;
    text-align: center;
    margin: 30px 0 10px;
    font-weight: 500;
  }
}
.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0rem;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #c1c9c1;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}

.social-login {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  gap: 8px;

  width: 100%;
  height: 48px;
  color: #ffffff;
  background: #025041;
  border-radius: 6px;
  flex: none;
  order: 0;
  flex-grow: 1;
}
.social-login a {
  color: #fff;
}

.social-login a:hover  {
  color: #fff;
}

.formBx {
  /* margin-top: 0.5rem; */
  margin-bottom: 0.5rem;
}
.formBx .social-login-item {
  display: flex;
  align-items: center;
  height: 45px;
  border: 1px solid #c1c9c1;
  border-radius: 5px;
  justify-content: space-evenly;
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;
  width: 40%;
  margin: 0.3rem;
  cursor: pointer;
  height: 100%;
  padding: 5px 0px;
}
.formBx .social-login-item:hover {
  background: #025041;
}

.social-box {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 80%;
  /* height:40px; */
}

.formBx .social-login-item a {
  text-decoration: none;
  color: #000;
  font-weight: 500;
  color: #025041;
  outline: none;
  background: transparent;
}
.formBx .social-login-item:hover a {
  color: #fff;
}

.formBx .social-login-item input {
  width: 100%;
  padding: 10px 20px;
  outline: none;
  font-weight: 400;
  border: 1px solid #c1c9c1;
  font-size: 16px;
  letter-spacing: 1px;
  border-radius: 5px;
  background: transparent;
  color: #025041;
}

.centerText {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  font-family: "Nunito", sans-serif;
}

.centerText p {
  color: #025041;
  font-size: 24px;
  font-weight: 600;
  line-height: 40px;
}

.formBx .header- .welcome span {
  font-weight: 900;
  font-size: 32px;
  line-height: 0%;
}

.formBx .header- .details span {
  font-weight: 400;
  font-size: 14px;
  line-height: 0%;
  letter-spacing: 0.04em;
  color: #868a8f;
}
.inputBx .centerText p {
  min-width: 0px;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  color: #000;
}

.invalid-Login span {
  margin: 8px 0px 0px;
  min-width: 0px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: rgb(255, 71, 93);
}

select {
  width: 100%;
  height: 48px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 1;
}
/* added Dashboard featured by vaishali */
.react-datepicker__aria-live{
  display: none;
}
.space-between{
  justify-content: space-between;
}