.conteanDav {
  background: #ffffff;
  box-shadow: 0px 1px 11px 0px #1111111a;
  padding: 30px 40px;

  .hedingPage {
    p {
      font-family: Poppins;
      font-size: 22px;
      font-weight: 600;
      line-height: 20px;
      text-align: center;
      color: #1d1d1d;
      padding-bottom: 20px;
    }
  }

  .ConteanBox {
    padding-top: 20px;

    .typo_lebal {
      font-family: Poppins;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #1d1d1d;
    }

    #selectBox {
      height: 45px;
    }

    .qewaqasnData {
      font-family: Poppins;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #1d1d1d;
      // padding-bottom: 7px;
    }
  }
}

.dot {
  // display: inline-block;
  // margin-left: 7px;
  // vertical-align: middle;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: rgb(255, 71, 93);
  // padding-right: 10px;
  margin-left: 4px;
  margin-top: 4px;
}

.Box_namemain {
  display: flex;
  align-items: center;
padding-bottom: 6px;
  p {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    color: #1d1d1d;
  }

  .chackBox_class {
    width: 20px;
    height: 19px;
    border-radius: 4px;
    margin-right: 10px;
  }
}
.SelectFrom{
  font-size: 12px;
  font-family: Poppins;
  height: 43px;
  font-weight: 400;
}
.textarea {
  border: 1px solid rgba(31, 31, 31, 0.4);
  width: 100%;
  padding: 13px;
  height: 100px;
  font-size: 14px;
  font-family: Poppins;
  font-weight: 400;
}

.button_entity {
  background: #025041;
  padding: 10px;
  border-radius: 5px;
  // height: 35px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  width: 100%;
  border: 0px solid;
  margin-top: 60px;
}

.errorMassage {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: red;
  font-weight: 500;

}
@media (max-width: 900px) {

.gridEntity{
margin-top: 30px;
margin-bottom: 30px;
}
}