.addteballist td {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 13px;
    color: #000;
    text-align: left;
    background-color: white;
    padding: 2px !important;
    // width: 10%;
    border-bottom: 0px solid;
}

.pa_tadaeagagga {
    margin-bottom: 0px;
    border: 1px double #F0F0F0;
    height: 40px;
    width: 30%;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 8px;
    overflow-y: scroll;

}

.contenar_data_maiana_tebal {
    padding: 0px 44px 30px 44px;
}

.addteballist td:first-child {
    width: 0.2%;
}

.checkbox_td_datata {
    width: 2%;
}

.tebal_contenar_data {
    overflow-x: unset !important;
}

.error_messege {
    border: 1px solid red;
    height: 42px;
    border-radius: 0;
    margin: 0 !important;
    display: block !important;
    font-size: 12px;

    div {
        width: 100%;
        height: 40px;
        font-family: 'Poppins';
        font-size: 12px;
        border-radius: 00;
    }

    fieldset {
        border-color: #F0F0F0;
    }

    fieldset:focus-visible {
        border-color: red;
    }

    fieldset:hover {
        color: #111111;
        background-color: #FFFFFF;
        border-color: #025041 !important;
    }
}

.addteballist th {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 13px;
    color: #000;
    text-align: left;
    background-color: white;
    padding: 2px !important;
    border-bottom: 0px solid;
}

.addborderin {
    border-bottom: 1px solid #DDDDDD !important;
}

.addnewtext {
    font-family: 'Poppins' !important;
    font-style: normal;
    font-size: 22px !important;
    color: #111111;
}

.addnewbtnclass {
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 10px;
    padding-top: 10px;
}

.nextntmistish {
    background-color: transparent;
    border: none;
    margin-left: 20px;
}

.listrowadd {
    div {
        height: 35px;
        width: 60px;
        font-family: Poppins-400 !important;
    }
}

.inpagenum {
    padding: 0px 20px 0px 0px;

    p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 0px;
    }
}

.addnewbtnclass {
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 10px;
}

.tabalinputdata {
    height: 42px;
    border-radius: 0;
    margin: 0 !important;
    display: block !important;
    font-size: 12px;

    div {
        width: 30%;
        height: 40px;
        font-family: 'Poppins';
        font-size: 12px;
        border-radius: 00;
    }

    fieldset {
        border-color: #F0F0F0;
    }

    fieldset:focus-visible {
        border-color: red;
    }

    fieldset:hover {
        color: #111111;
        background-color: #FFFFFF;
        border-color: #025041 !important;
    }
}

.err_border_lineee {
    height: 42px;
    border-radius: 0;
    margin: 0 !important;
    display: block !important;
    font-size: 12px;

    div {
        width: 100%;
        height: 40px;
        font-family: 'Poppins';
        font-size: 12px;
        border-radius: 00;
    }

    fieldset {
        border-color: red;
    }

    fieldset:focus-visible {
        border-color: red;
    }

    fieldset:hover {
        color: #111111;
        background-color: #FFFFFF;
        border-color: #025041 !important;
    }
}

#text_fild_sjsjsjjs {
    fieldset:focus-visible {
        border: 2px solid red;
        border-color: red;
    }

}

select.err_border_lineee,
.err_border_lineee {
    border: 1px solid red !important;
}

.tabalinputErrordata {
    height: 42px;
    border-radius: 0;
    margin: 0 !important;
    display: block !important;
    font-size: 12px;

    div {
        width: 100%;
        height: 40px;
        font-family: 'Poppins';
        font-size: 12px;
        border-radius: 00;
    }

    fieldset {
        border-color: red !important;
    }
}



.white_btn {
    border: 1px solid #025041 !important;
    background-color: white !important;
    color: #025141 !important;
    margin-right: 10px;

}

.white_btn_two {
    border: 0px solid #025041 !important;
    margin-right: 20px;
    border-radius: 5px;
    background: #DF3A3A !important;
    color: #FFF !important;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    border: 1px solid #DF3A3A !important;

}

.white_btn_two:hover {
    color: #F87161 !important;
}

.go_back_btn {
    background-color: white !important;
    margin-right: 20px;
    color: #000 !important;
    border-bottom: 1px solid #707070;
    border-top: 0px solid;
    border-left: 0px solid;
    border-right: 0px;
    padding: 6px 6px 0px 6px !important;
    border-radius: 0px !important;
    margin-left: 15px;
}

.go_back_btn:hover {
    border-bottom: 1px solid #707070;
}

.go_back_btn:hover {
    color: #000 !important;
}

.save_btn {
    border: 1px solid #025041 !important;
    background-color: #025141 !important;
    color: #FFFFFF !important;
    padding: 12px 30px !important;
    margin-right: 10px;
}

.save_btn_delete_btn {
    border: 1px solid #F87161 !important;
    background-color: #F87161 !important;
    color: #FFFFFF !important;
    padding: 12px 30px !important;
    margin-right: 10px;
}

.save_btn22 {
    border-radius: 6px !important;
    color: #282828 !important;
    width: 148px;
    height: 38px;
    justify-content: center;
    margin-left: 15px;
    font-family: 'Poppins';
    font-style: normal;
    font-size: 12px;
    display: flex;
    align-items: center;
    border: 1px solid #025041;
}

.save_btn22 svg {
    margin-left: 11px;
}

.peregarafdata {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 12px;
    color: red;
}

.addnewselect div:first-child {
    min-height: 40px;
    border-radius: 0;
    font-size: 12px;
    max-width: 150px;
    margin: auto;

    span {
        background: #fff;
        border: 1px solid #ddd;
        border-radius: 4px;
        color: #474747;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        gap: 4px;
        height: 24px;
        padding: 0 8px;
    }
}

.call_heding_total_data {
    color: #282828;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.text_fast_datataa {
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.09px;
    padding-right: 10px;
}

#addnewinput {
    display: block !important;
    border: 1px solid #DDDDDD;
    border-radius: 0;
    height: 40px;
    font-size: 12px;
    padding: 0px 3px;
    width: 100%;
}

#addnewkeydata {
    display: block !important;
}

.addaffafagga {
    display: none !important;
}

.addnewdaddaa {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 12px;
    color: red;
}

.add_new_kjdjfhfggs {
    padding: 32px;
    border-bottom: 1px solid #FFFFFF;
    padding-left: 32px !important;
    padding-right: 32px !important;
}

.select_menu_item_sjjss {
    border: 1px solid #DDDDDD;
    border-radius: 100px !important;
    height: 40px;

    div {
        font-family: 'Poppins';
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.05em;
        color: #515151;
    }

    fieldset {
        border-width: 0px;
    }
}

.box_main_ashhshshhs {
    padding: 32px 0px 24px 43px;
}

.save_btn_delete_btn {
    border: 1px solid #F87161 !important;
    background-color: #F87161 !important;
    color: #FFFFFF !important;
    padding: 12px 30px !important;
    margin-left: 43px;
}

.main_call_rejecta_payy {
    display: flex;
    justify-content: space-between;
    width: 100%;
}


@media only screen and (max-width: 450px) {
    .maina_withe_ajajha {
        width: 12% !important;
    }

    .main_amount_datataaa {
        width: 14%;
    }

    #basic_addon3 {
        padding: 0px 2px;
        width: 27px;
    }

    .save_btn_delete_btn {
        margin-left: 0px;
    }

    .addnewtext {
        font-size: 22px !important;

    }

    .main_call_rejecta_payy {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .main_conteanat_lijshs {
        display: block !important;
    }

    .contenar_data_maiana_tebal {
        padding: 0px 15px 30px 15px;
    }

    .tebal_contenar_data {
        overflow-x: auto !important;
    }

    .tebaldata_hedinh {
        display: block;
    }

    .div_uplod_data_use {
        display: flex;
        justify-content: end;
        padding-top: 12px;
    }

    .add_delete_data_listtss {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .div_laiiaajjajjaa {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 12px;
    }

    .add_ajajjahahag {
        display: block !important;
        padding: 20px 10px !important;
    }
}