.gridEmailCom {
    padding: 12px 43px;
}

.ReactMultiEmailstyle {
    font-family: Poppins !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 20px;
    text-align: left;
    color: #1D1D1D !important;
    border: 0px solid !important;
    // height: 52px;
}

.Box_classinput {
    margin-top: 14px;
    border: 0.5px solid #8C8C8C;
    border-radius: 10px;
}

.Box_conteansubject {
    display: flex;
    align-items: center;
    border-bottom: 0.5px solid #A8A8A8;
    padding: 12px 20px;

}

.inputSubject {
    border: 0px solid;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #1D1D1D;
}

.inputSubject:focus {
    box-shadow: none !important;
}

.Grid_Conteanitem {
    padding-top: 40px;
}

.text_subject {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #6D6B6B;
}

.BoxClasstextarea {
    padding: 15px 20px;
}

.textarea {
    border: 0px solid;
    width: 100%;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    color: #1D1D1D;
}

.textarea:focus-visible {
    outline: none !important;
}

.typo_to {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #6D6B6B;
    padding-right: 38px;
}

.Box_conteanemail {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    border: 0.5px solid #8C8C8C;
    border-radius: 10px !important;

}