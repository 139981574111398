@import "../../../../styles/variables.module.scss";


.otp_box {
    background-color: #ffffff;
    padding: 32px;
    width: 610px !important;
    margin: 10px;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    height: max-content !important;
    margin-top:35px;

    .otp_authorize_heading {
        font-size: 24px;
        font-weight: $font-weight-semibold !important;
        font-family: "Poppins" !important;
        margin-bottom: 12px;
    }

    .authorize_sub_heading {
        font-size: $font-size-md !important;
        font-family: "Poppins" !important;
        text-align: center !important;
        width: 80%;
        line-height: 24px;
        letter-spacing: 0.04em;
        color: $color-text-primary;
        margin-bottom: 24px;
    }

    .otp_label {
        font-size: $font-size-md !important;
        font-family: "Poppins" !important;
        letter-spacing: 0.04em;
    }

    .otp_input {
        border: 1px solid #DDDDDD;
        border-radius: 4px;
        height: 48px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 8px;

        &::placeholder {
            color: #DDDDDD;
        }
    }

    .btn_wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 85%;
        margin-top: 27px;
        column-gap: 10px;
        margin-right: auto;
        margin-left: auto;

        .otp_button {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            letter-spacing: 0.05em;
            color: #FFFFFF;
            border-radius: 6px;

            &:disabled {
                background: #025041 !important;
                color: #FFFFFF !important;
                border: 1px solid #025041 !important;
            }
        }

        .green {
            background: #025041 !important;
            color: #FFFFFF !important;
            border: 1px solid #025041 !important;
            padding: 11px 40px;
        }

        .white {
            border: 1px solid #025041 !important;
            color: #000 !important;
            background-color: #FFFFFF !important;
            padding: 11px 24px;
        }
    }
}

@media only screen and (max-width : 1450px){
    .otp_box{
        width: 500px !important;
        padding: 27px;
        margin-top:0px;
        .otp_authorize_heading {
            font-size: 20px !important;
        }
        .authorize_sub_heading{
            font-size: $font-size-sm !important;
            width: 87%;   
            margin-bottom: 15px;
        }
        .otp_label {
            font-size: $font-size-base !important;
        }
        .btn_wrapper{
            .otp_button {
                font-weight: 400;
                font-size: 12px;
            }
            .green {
                padding: 9px 25px;
            }
    
            .white {
                padding: 9px 15px;
            }
        }
    }
    
}