.buttom_create {
    border: 0 solid;
    border-radius: 6px;
    color: #fff;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 12px 25px;
    background: #025141;

}

.buttom_create:hover {
    background: #025141;
    color: #fff;
}

.buttom_create:active {
    background: #025141;
    color: #fff;
    background-color: #025141 !important;
}

.tebalContenar {
    padding: 50px;

}
.box_dialog_main {
    padding: 14px 32px;
}
.Create_Team_heding {
    color: #2D2C2C;
    font-family: Poppins;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
}
.box_btn_cencal {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
}

.cerete_user {
    border-radius: 5px;
    border: 1px solid #025041;
    width: 179px;
    height: 40px;
    color: #025041;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-transform: capitalize;
    background: none !important;
}
.cerete_user:hover {
    border: 1px solid #025041;
    color: #025041;

}
.addinviteuser {
    background: #FFFFFF;
    border: 1px solid #474747 !important;
    border-radius: 5px !important;
    font-family: 'Poppins' !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    color: #474747 !important;
    margin: 15px 30px !important;
    text-transform: capitalize !important;
}

.savebtn {
    color: #FFFFFF !important;
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    text-transform: capitalize !important;
    margin-left: 15px;
    background: #025041 !important;
    border-radius: 4px !important;
    width: 179px;
    height: 40px;
    border: 0px solid !important;
}

.savebtn:hover {
    background-color: #025041;
}

@media only screen and (max-width: 450px) {

.tebalContenar{
    padding: 0px 20px 41px 20px;

}
}