.main_dev_contean {
    max-width: 550px;
    margin: auto;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 2px 14px 2px rgba(174, 174, 174, 0.25);
    padding: 70px 70px;
    text-align: center;

    p {
        color: #1D1D1D;
        text-align: center;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px
    }

    button {
        border-radius: 4px;
        background: #025141;
        color: #FFF;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        padding: 8px 15px;
        border: 0px solid;
        margin: 20px 0px 0px 0px;
    }

    button:hover {
        background: #025141;
        color: #FFF;
    }
}