.dashboard-container {
  padding: 0px 91px 40px 91px;

  &__header {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #025041;

    button {
      background-color: #fff;
      border: none;
      padding: 5px;

      svg {
        font-size: 20px;
      }
    }
  }

  &__balance-wrapper {
    margin-top: 7px;
    .total-balance {
      border-radius: 5px;
      background: #fff;
      height: 100%;
      box-shadow: 0px 1px 13px 0px rgba(17, 17, 17, 0.1);
      padding: 4px 12px 12px 12px;

      .header {
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: #025041;

        button {
          background-color: #fff;
          border: none;
          padding: 5px;

          svg {
            font-size: 20px;
          }
        }
      }

      .marginTop-90 {
        margin-top: 90px;
      }

      .marginTop-30 {
        margin-top: 30px;
      }

      .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 0px solid;

        .left {
          font-weight: 500;
          font-size: 22px;
          line-height: 33px;
          color: #257d6b;

          span {
            font-size: 14px;
          }
        }

        .right {
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: #257d6b;

          svg {
            margin-left: 10px;
          }

          .link {
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: #257d6b;
            text-decoration: none;
            align-items: center;
          }
        }
      }

      .d-flex {
        display: flex;
        justify-content: space-between;
        margin: 0px 0px;
        align-items: center;

        .left {
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: #000 !important;
        }

        .right {
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          color: #000;

          span {
            font-size: 11px;
          }
        }
      }
    }
  }

  &__graph-section {
    box-shadow: 0px 2px 11px rgba(158, 155, 155, 0.25);
    border-radius: 8px;
    padding: 20px 15px 15px 15px;
    margin-top: 20px;

    .graph-top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .header {
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: #025041;

        button {
          background-color: #fff;
          border: none;
          padding: 5px;

          svg {
            font-size: 20px;
          }
        }
      }

      .lefti {
        display: flex;
        align-items: center;

        h5 {
          font-weight: 500;
          font-size: 17px;
          line-height: 26px;
          color: #515151;
          font-family: 'Poppins';
        }

        .blue,
        .green,
        .red {
          margin-left: 45px;
          display: flex;
          align-items: center;

          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #000;
            margin-left: 3px;
          }
        }
      }

      .righti {
        display: flex;

        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #000;
          margin-right: 5px;
        }

        .react-date-picker__wrapper {
          border: none;
        }

        button.react-date-picker__clear-button {
          display: none;
        }

        .react-date-picker__inputGroup__input {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #000;
        }
      }
    }

    .graph-body {
      margin-top: 30px;
    }
  }

  &__subtitle {
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    color: #000 !important;
  }

  &__datepicker {
    display: flex;
    align-items: center;
    height: 24px;
    background: #ffffff;
    border: 1px solid #dddddd;
    outline: none;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: #111111;
    border-radius: 8px;
    padding: 10px;
    margin: 0 10px 15px;
    width: 105px;
  }
  &__datepicker2 {
    display: flex;
    align-items: center;
    height: 45px;
    background: #f2f8f7;
    border: 1px solid #dddddd;
    outline: none;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #111111;
    border-radius: 8px;
    padding: 10px;
    margin: 0 10px 15px;
    width: 150px;
  }

  &__chart_cantenar {
    .header {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      color: #025041;

      button {
        background-color: #fff;
        border: none;
        padding: 5px;

        svg {
          font-size: 20px;
        }
      }
    }
  }
}

.total-balance {
  border-radius: 5px;
  background: #fff;
  height: 95%;
  box-shadow: 0px 1px 13px 0px rgba(17, 17, 17, 0.1);
  padding: 20px 12px 12px 12px;
  position: relative;

  .header {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #025041;

    button {
      background-color: #fff;
      border: none;
      padding: 5px;

      svg {
        font-size: 20px;
      }
    }
  }

  .marginTop-90 {
    margin-top: 90px;
  }

  .marginTop-30 {
    margin-top: 30px;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 0px solid;

    .left {
      font-weight: 500;
      font-size: 22px;
      line-height: 33px;
      color: #257d6b;

      span {
        font-size: 14px;
      }
    }

    .right {
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #257d6b;

      svg {
        margin-left: 10px;
      }

      .link {
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #257d6b;
        text-decoration: none;
        align-items: center;
      }
    }
  }

  .d-flex {
    display: flex;
    justify-content: space-between;
    margin: 0px 0px;
    align-items: center;

    .left {
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #000 !important;
    }

    .right {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #000;

      span {
        font-size: 11px;
      }
    }
  }
}

.main_dev_total {
  padding-top: 16px;
}

#contean_dashboardV3 {
  box-shadow: 0px 0px 15px 1px #6868681a;
  padding: 12px 12px 12px 14px;
  border-radius: 5px;
  display: grid;
  gap: 4px;
  width: 100%;
}

#contean_dashboard {
  box-shadow: 0px 0px 15px 1px #6868681a;
  padding: 12px 12px 12px 14px;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
}

#contean_footerBox {
  display: flex;
  justify-content: space-between;
}

.header_Dashboard {
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 28px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(204, 204, 204, 0.25) 0px 4px 21px;
  padding: 24px 0px;
}

.input_select_data {
  width: 115px !important;
  height: 25px;
  padding: 0px 4px !important;
  color: #1d1d1d;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.select_heding {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #025041;

  button {
    background-color: #fff;
    border: none;
    padding: 5px;

    svg {
      font-size: 20px;
    }
  }

  button:hover {
    background-color: #fff;
  }
}

.Make_payment_Button_styles {
  padding: 5px 4px 5px 5px;
  gap: 2px;
  border-radius: 5px;
  border: 0.5px solid #025041 !important;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #025041 !important;
  margin-left: 10px;
}

.top_up_button_styles {
  padding: 5px 4px 5px 5px;
  gap: 2px;
  border-radius: 5px;
  border: 0.5px solid #025041 !important;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #025041 !important;
}

.a_tage_styles {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 400;
  line-height: 18px;
  text-align: right;
  color: #025041;
  border: 1 solid #025041;
  cursor: pointer;
}

.footer_data_contean {
  border-radius: 5px;
  background: #f6f6f6;
  padding: 20px 11px 20px 11px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left_data {
    .Savings_heding {
      color: #1d1d1d;
      text-align: center;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      padding-bottom: 8px;
    }

    .Savings_kes_amount {
      color: #1d1d1d;

      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;

      span {
        color: #1d1d1d;

        text-align: center;
        font-family: Poppins;
        font-size: 8px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
      }
    }
  }

  .rithe_data {
    color: #1d1d1d;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    max-width: 200px;
    line-height: 12px;
  }
}

.button_iconpanding {
  background-color: #fff !important;
  border: none !important;
  padding: 0px 5px !important;
}

.main_dev_three {
  display: flex;
  align-items: center;
  width: 100%;

  .fast_dev {
    width: 30%;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #000 !important;
  }

  .secend_dev {
    width: 40%;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #000 !important;
    text-align: center;
  }

  .thred_dev {
    width: 30%;
    text-align: end;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #000 !important;

    span {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #000 !important;
    }
  }
}
@media only screen and (max-width: 820px) {
  .dashboard-container {
    padding: 25px;

    &__balance-wrapper {
      .total-balance {
        margin-top: 10px;
      }
    }

    &__graph-section {
      margin-top: 10px;

      .d-flex {
        padding-top: 10px;
      }
    }

    &__datepicker {
      margin: 0 0px 15px;
    }

    &__chart_cantenar {
      margin-top: 10px;
      box-shadow: 0px 2px 11px rgba(158, 155, 155, 0.25);
      border-radius: 8px;
      padding: 15px;
    }
  }
}

.link {
  padding: 4px;
  font-size: 13px;
}

.link:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.fast_des_footer {
  border-radius: 5px;
  background: #e3edeb;
  padding: 10px 20px 8px 20px;
  // height: 100%;
  width: 99%;
  flex: 1;

  p {
    color: #1d1d1d;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
  }
}

#ReatBox {
  svg {
    margin-right: 6px;
  }
}

.box_imgavtar {
  background: #ffffff;
  border-radius: 50%;
  padding: 4px;
  width: 31px;
  height: 31px;
}
.heding_secend {
  font-weight: 500 !important;
  line-height: 26px !important;
  color: #025041 !important;
  font-size: 14px !important;
  margin-left: 8px;
}
.link_Top_up {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #fff !important;
  text-decoration: none;
  align-items: center !important;
  gap: 15px;
  border-radius: 6px;
  background: #025041;
  padding: 6px 10px;

  svg {
    color: #fff !important;
  }
}

@media only screen and (max-width: 1332px) {
  .dashboard-container {
    &__balance-wrapper {
      grid-gap: 10px;
    }
  }
}

@media only screen and (max-width: 1332px) {
  .dashboard-container {
    padding: 32px 15px;

    &__balance-wrapper {
      grid-gap: 8px;
    }
  }

  #desplay_contean {
    display: block !important;
  }

  .container_chart {
    gap: 8px;
  }
}

@media only screen and (max-width: 1100px) {
  .dashboard-container {
    padding: 32px 15px;

    &__balance-wrapper {
      grid-gap: 8px;
    }
  }

  #desplay_contean {
    display: block !important;
  }

  .container_chart {
    gap: 8px;
  }
}

@media only screen and (max-width: 450px) {
  .fast_des_footer {
    margin-top: 12px;
  }

  .header_Dashboard {
    margin-bottom: 0px;
  }

  .dashboard-container {
    padding: 20px !important;

    &__balance-wrapper {
      display: block;
      margin-top: 0px;
    }

    &__graph-section {
      margin-top: 10px;

      .d-flex {
        padding-top: 10px;
      }
    }

    &__datepicker {
      margin: 0 0px 15px;
    }

    &__chart_cantenar {
      margin-top: 10px;
      box-shadow: 0px 2px 11px rgba(158, 155, 155, 0.25);
      border-radius: 8px;
      padding: 15px;
    }
  }

  .total-balance {
    margin-top: 10px !important;
    height: 200px;
  }

  #contean_footerBox {
    display: block !important;
  }
}
.box_dialog_main {
  padding: 14px 32px;
}
.cancel_btn {
  font-family: 'Poppins' !important;
  font-size: 14px !important;
  color: #025141 !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
}
@media only screen and (min-width:1333px){
  .g_myaccounts{
    width:33%;
    margin-right: auto;
  }
  .g_inflows{
    width:33%;
  }
  .g_outflows{
    width:33%;
    margin-left: auto;
  }

}
@media only screen and (min-width:1024px) and (max-width:1333px){
  .g_myaccounts{
    width:49%;
    margin-right: auto;
  }
  .g_inflows{
    width:49% !important;
    margin-left: auto;
    
  }
  .g_outflows{
    width:49% !important;
  }

}
@media only screen and (min-width:580px) and (max-width:1024px){
  .g_myaccounts{
    width:100% ;
  }
  .g_inflows{
    width:49%;
    margin:0px;
    margin-right: auto;
  }
  .g_outflows{
    width:49%;
    margin:0px;
    margin-left: auto;
  }
  .container_chart_V3{
    margin-top: 10px;
  }

}
@media only screen and (max-width: 580px) {
  .g_myaccounts , .g_inflows , .g_outflows{
    width:100% ;
    margin: auto;
  }

}