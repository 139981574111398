.main_dev_contean {
    max-width: 450px;
    margin: auto;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 2px 14px 2px rgba(174, 174, 174, 0.25);
    padding: 45px 70px;
    text-align: center;

    .heding_tage_listtt {
        color: #1D1D1D;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    .div_main_calssname_this {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-top: 40px;

        .contenar_data {
            width: 50%;
            padding-bottom: 10px;
            cursor: pointer;

            img {
                width: 60px;
                height: 60px;
            }

            p {
                color: #1D1D1D;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                padding-top: 8px;
            }
        }
    }
}
@media only screen and (max-width: 450px) {
    .main_dev_contean {
        padding: 45px 30px;
    }

}