.grid_bill_container {
    // justify-content: space-between;
    .react-datepicker-wrapper {
        width: 100%;
    }

    .react-datepicker__input-container {
        height: 48px;
        border-radius: 50px;
        background: #FFFFFF !important;
        width: 100%;

        input {
            height: 48px;
            border-radius: 50px;
            background: #FFFFFF !important;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.03em;
            color: #383838 !important;
            margin: 0 0px 15px;
            width: 100%;

            &::placeholder {
                color: #383838 !important;
                font-family: 'Poppins';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.33px;
            }

            &::placeholder:hover {
                color: #515151;
                color: white;
            }

            &::placeholder:focus:hover {
                color: white;

            }

            &::placeholder:hover {
                color: #ffffff !important;
            }

            &:focus::-webkit-input-placeholder,
            &:hover::-webkit-input-placeholder {
                color: #ffffff !important;
            }

            &:focus {
                box-shadow: 0px 0px 0px 4px rgba(1, 82, 65, 0.25);
                border-color: #015241;
            }

            &:hover {
                color: #515151;
                background-color: #3f7268;
                box-shadow: 0px 0px 0px 4px rgba(1, 82, 65, 0.25);
            }


        }

    }
    .box_grid_filter {
        display: flex;
        justify-content: space-between;
        align-items: center;

        #select_up_div {
            padding: 0px !important;
        }

    }
}
.fildContenar{
    display: flex;
    align-items: center;
    gap: 22px;
    // padding-bottom: 17px;
}
.pay_buttom{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding: 8px 16px !important;
    border-radius: 6px;
    border: 1px solid #025041 !important;
    background-color: white !important;
    color: #025141 !important;
}
.white_btn_two {
    border: 0px solid #025041 !important;
    // color: #025141 !important;
    margin-right: 20px;
    border-radius: 5px;
background: #DF3A3A !important;
color: #FFF !important;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
border: 1px solid #DF3A3A !important;
}

.white_btn_two:hover {
    color: #FFF!important;
    border: 1px solid #DF3A3A;

}
.contenar_tebal_liner {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 2px 14px 2px rgba(174, 174, 174, 0.25);
    width: 100%;
    margin-bottom: 0px;
    th {
        font-family: Poppins;
        padding: 16px 6px;
        color: #000;
        font-size: 13px;
        font-style: normal;
        line-height: 20px;
    }

    td {
        color: #000;
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        // font-weight: 500;
        text-align: left;
        padding: 16px 6px;
        line-height: 20px;
    }

    .main_camin_box_dataa {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #D9D9D9;
    }

    .main_camin_box_dataa_twoo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        p {
            width: 50%;
        }

        .textLeft {
            text-align: left;
            overflow-wrap: normal;

        }

        .textRight {
            text-align: right;
        }
    }

    .maian_bottom_borderr {
        border-bottom: 1px solid #D9D9D9;
    }

}
@media only screen and (max-width: 450px) {

.fildContenar{
    display: block;
}
}