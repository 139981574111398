.payDetails-modal {

    &__btn-row {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 30px;
        column-gap: 10px;


        .btn {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0.05em;
            color: #FFFFFF;
            border-radius: 6px;

            &:disabled {
                background: #CFCFCF !important;
            }
        }

        &__green {
            background: #025041 !important;
            color: #FFFFFF !important;
            border-color: #CFCFCF !important;
            padding: 11px 40px;
        }

        &__white {
            // color: #707070 !important;
            color: #000 !important;
            /* added by  by VL 9 mar NIO-25 */
            background-color: #FFFFFF !important;
            border-color: #015241 !important;
            padding: 11px 25px;
            margin-right: 13px;

        }
    }
}

.bill-container {
    &__header {
        background: #FFFFFF;
        box-shadow: 0px 4px 21px rgba(204, 204, 204, 0.25);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 43px;

        &__name {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 20px;
            letter-spacing: 0.1em;
            color: #025041;
        }
    }

    .btn {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        padding: 12px 16px;
        border-radius: 6px;
    }

    &__white-btn {
        border: 1px solid #025041 !important;
        background-color: white !important;
        color: #025141 !important;
        margin-right: 13px;

    }

    &__save-btn {
        border: 1px solid #025041 !important;
        background-color: #025141 !important;
        color: #FFFFFF !important;
        margin-right: 0px;
    }

    &__Dropbtn {
        cursor: no-drop !important;
    }

    h1 {
        padding-left: 0px;
    }

    &__content {
        padding: 10px 0;
    }

    &__payables {
        // padding: 0 43px;

        h1 {
            font-family: "Poppins";
            font-weight: 700;
            font-size: 18px;
            line-height: 20px;
            color: #025041;
            // margin-left: 15px;
        }
    }
}

.active {
    background: #FFFFFF !important;
}

input[value=''],
input[name='amount'][value='0'],
select[value=''],
input::placeholder {
    color: #515151;
}

select option[value='']:checked {
    color: gray;
}

/* Apply italics to the invalid options too when shown in the dropdown */
select option[value=""],
select option:not([value]) {
    color: gray;
}

.AmountBox {
    width: 71%;
    padding: 10px;
}

.document {
    display: flex;
    padding: 0px 25px;
    margin-bottom: 20px;

    &__wrapper {
        h1 {
            margin: 30px 0px;
            margin-bottom: 25px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #111111;
            display: flex;
            gap: 5px;
        }

        .add-btn {
            background: #F3F3F3 !important;
            color: #282828 !important;
            border: none !important;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            padding: 12px 16px;
            margin-bottom: 10px;

            svg {
                margin-left: 10px;
            }
        }

        .mb-20 {
            margin-bottom: 20px;
        }

        .error {
            border-color: red;
        }

        .error-message {
            color: #FF6565;
            padding: .5em .2em;
            height: 1em;
            font-size: .8em;
        }

        .dot {
            label:after {
                content: "";
                display: inline-block;
                margin-left: 7px;
                vertical-align: middle;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: rgb(255, 71, 93);
            }
        }

        .row-date {
            display: flex;
            width: 510px;
            gap: 15px;
        }

        .date-width {
            width: 50%;
            margin: 0 !important;
        }

        .input-form-wrapper {
            margin: 20px 0px;

            li {
                background-color: #fff;
                color: #000;
            }

            label {
                display: block;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: #000;
                margin-bottom: 5px;
            }

            .text-field {
                border: 1px solid #025141;
                border-radius: 5px;
                width: 368px;
                height: 48px;
                padding: 0px 8px;
                outline: none;
                font-weight: 400;
                font-size: 15px;
                line-height: 24px;
                color: #3A3A3A;
            }

            select {
                // border: 1px solid green;
                border-radius: 5px;
                //width: 368px;
                height: 48px;
                outline: none;
                padding: 0px 8px;
                color: #3A3A3A;
            }

            select:focus {
                box-shadow: 0px 0px 0px 4px rgba(1, 82, 65, 0.25);
                border-color: #015241;
            }

            .doc-input-wrapper {
                display: flex;
                align-items: center;
                height: 48px;
                background: #FFFFFF;
                border: 1px solid #DDDDDD;
                outline: none;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                color: #111111;
                border-radius: 8px;

                &.input {
                    padding: 0px 12px;
                }

                input {
                    border: none;
                    height: 100%;
                }

                .doc-form-input {
                    outline: none;
                }

                .doc-form-input:focus {
                    box-shadow: 0px 0px 0px 4px rgba(1, 82, 65, 0.25);
                    border-color: #015241;
                }

                .doc-form-input :focus {
                    color: #212529;
                    background-color: #fff;
                    border-color: #015241;
                    outline: 0;
                    box-shadow: 0px 0px 0px 4px rgba(1, 82, 65, 0.25);
                }

                .input-mask {
                    display: flex;
                    align-items: center;
                    font-weight: 400;
                    font-size: 16px;
                    color: #B8B8B8;
                    background-color: #FFFFFF;
                    border: none;
                }
            }

            .doc-date {
                height: 48px;

                .react-date-picker__wrapper {
                    border-color: #DDDDDD;
                    padding: 0px 8px;
                    border-radius: 8px;
                }
            }

            .multiselect-container {
                .searchWrapper {
                    border-radius: 8px !important;
                    display: flex;
                    align-items: center;
                    padding: 8px !important;
                    border-color: #DDDDDD !important;
                    min-height: 48px;
                    flex-wrap: wrap;

                    .searchBox {
                        margin-top: 0px !important;
                    }

                    .chip {
                        padding: 0px 8px;
                        gap: 4px;
                        //width: 101px;
                        height: 24px;
                        background: #FFFFFF;
                        border: 1px solid #DDDDDD;
                        border-radius: 4px;

                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        color: #474747;

                        .closeIcon {
                            display: none !important;
                        }
                    }
                }

                input {
                    border: none !important;
                    height: auto !important;
                }
            }
        }

        .footer {
            position: sticky;
            bottom: 00;
            display: block;
            border-top: 1px solid #eee;
            background: white;

            .whitebtn {
                font-family: Poppins !important;
                font-size: 16px !important;
                font-style: normal !important;
                font-weight: 500 !important;
                line-height: 20px;
                width: 100px;
                height: 46px;
                color: rgb(17, 17, 17);
                background-color: #ffffff;
                border: none;
                border: 1px solid;
                margin-right: 10px;
            }

            .whitebtn:hover {
                border: 1px solid !important;
                border-radius: 6px !important;
                font-family: Poppins !important;
                font-size: 16px !important;
                font-style: normal !important;
                font-weight: 500 !important;
                line-height: 20px !important;
                width: 100px;
                height: 46px;
                color: rgb(17, 17, 17);
                background-color: #ffffff;
                margin-right: 10px;
            }

            .greenbtn {
                font-family: Poppins !important;
                font-size: 16px !important;
                font-style: normal !important;
                font-weight: 500 !important;
                color: white;
                width: 100px;
                height: 46px;
                border: none;
                border: 1px solid #025041 !important;
                background-color: #025141 !important;
            }

            .greenbtn:hover {
                border: 1px solid !important;
                border-radius: 6px !important;
                font-family: Poppins !important;
                font-size: 16px !important;
                font-style: normal !important;
                font-weight: 500 !important;
                line-height: 20px !important;
                width: 100px;
                height: 46px;
                color: white;
                border: 1px solid #025041 !important;
                background-color: #025141 !important;
            }
        }
    }
}

.paystatus {
    padding: 0px 25px;
    padding-bottom: 25px;

    &__wrapper {
        h1 {
            margin: 30px 0px;
            margin-bottom: 25px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #111111;
            display: flex;
            gap: 5px;
        }
    }

    &__txt {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        letter-spacing: 0.05em;
        color: #000;
    }
}

.payament-form {
    padding: 0px 25px;
    padding-bottom: 25px;

    &__wrapper {
        h1 {
            margin: 30px 0px;
            margin-bottom: 25px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #111111;
            display: flex;
            gap: 5px;
        }

        .input-form-wrapper {
            margin: 20px 0px;

            label {
                display: block;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: #000;
                margin-bottom: 5px;
            }

            .text-field {
                border: 1px solid #DDDDDD;
                border-radius: 5px;
                width: 368px;
                height: 48px;
                padding: 0px 8px;
                outline: none;
                font-weight: 400;
                font-size: 15px;
                line-height: 24px;
                color: #3A3A3A;
            }

            .doc-input-wrapper {
                display: flex;
                align-items: center;
                height: 48px;
                background: #FFFFFF;
                border: 1px solid #DDDDDD;
                outline: none;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                color: #111111;
                border-radius: 8px;

                &.input {
                    padding: 0px 12px;
                }

                .doc-form-input {
                    border: none;
                    outline: none;
                }

                .input-mask {
                    display: flex;
                    align-items: center;
                    font-weight: 400;
                    font-size: 16px;
                    color: #B8B8B8;
                    background-color: #FFFFFF;
                    border: none;
                }
            }
        }
    }
}

.height {
    height: 94vh;
    display: flex;
    padding: 10px 43px;
}

.height_two {
    height: 94vh;
    display: flex;
    padding: 50px
}

.mr-1 {
    margin-right: 10px;
}

.maihagaygajnjaauh {

    align-items: normal !important;
}

@media(max-width:768px) {
    .displya_data_fastdiv_media {
        display: block !important;
    }

    .fast_button_and_input_media {
        width: 100% !important;
    }

}

.displya_data_fastdiv {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .fast_button_and_input {
        width: 48.5%;

        // display: flex;


        .add_button_thisss {
            font-family: "Poppins";
            color: #025041;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            border-radius: 5px;
            border: 1px solid var(--theme-neutrals-80-light-grey-2, #DDD);
            background: var(--theme-neutrals-100-white, #FFF);
            margin-left: 15px;

        }
    }

}

@media only screen and (max-width: 450px) {
    .height_two {
        padding: 10px 15px;

    }

    .height {
        padding: 10px 20px;
        width: 100%;
        overflow-x: auto;
    }

    .bill-container__save-btn {
        margin-right: 0px;
    }

    .bill-container h1 {
        padding-left: 0px;
        margin: 0;
    }

    .bill-container .btn {
        margin-bottom: 10px;
    }

    .document__wrapper .input-form-wrapper select {
        width: 100%;
    }

    .document {
        padding: 0px 17px;
        overflow-y: scroll;

    }

    #afafafsdaFfsf {
        width: 100% !important;
    }

    .document__wrapper .input-form-wrapper .doc-input-wrapper {
        width: 100%;
    }

    .document__wrapper .row-date {
        display: block;
        width: 100% !important;
    }

    .document__wrapper .date-width {
        width: 100%;
    }

    .document__wrapper .input-form-wrapper .multiselect-container .searchWrapper {
        width: 100%;
    }

    .payDetails-modal .modal-content {
        overflow-y: unset !important;
        // overflow-x: auto;

    }

    .payDetails-modal__btn-row_two {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-top: 15px;
    }
}

.ADDBTNHHA {
    background-color: transparent !important;
    color: #025041 !important;
    border: 0px solid !important;
    padding: 0px !important;
}

// Start

.bills_header_btn_wrapper .bills_header_btn {
    width: 120px;
}

@media (min-width: 450px) and (max-width: 540px) {
    .bills_header_btn_wrapper {
        margin-left: 15px;

    }

    .bills_header_btn_wrapper .bills_header_btn {
        width: 95px !important;
        font-size: 10px !important;
        margin-right: 2px !important;
        padding: 4px 5px !important;
    }

}

@media only screen and (max-width: 520px) {
    .bills_header_btn_wrapper .bills_header_btn {
        width: 95px !important;
        font-size: 10px !important;
        margin-right: 2px !important;
        padding: 4px 5px !important;
    }
}

.bills_header_btn_wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
}