@import "../../../../../../../../styles/variables.module.scss";
.step5_container {
    height: calc(100vh - 50vh);
    display: flex;
    justify-content: center;
    align-items: center;
}



