.ClassName_Grid {
    padding: 40px 40px;
    height: 100%;

    .GOback_button {
        color: #1D1D1D;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        background-color: #fff;
        border: 0px solid;
        padding: 0px;
        margin-bottom: 20px;
    }

    .GOback_button:hover {
        background-color: #fff;
    }

    .GOback_button:active {
        background-color: #fff !important;
    }

    .Typography_error {
        margin-top: 0.25rem;
        font-size: 0.875em;
        color: #dc3545;
        font-family: Poppins;
    }
}

.className_divmenu {
    display: flex;
    justify-content: space-between;
    align-items: end;
}

.payment_BUUTON {
    border-radius: 5px;
    background: #025041;
    color: #FFF;

    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    width: 100%;
    border: 0px solid;
    padding: 10px 0px;
    margin-top: 20px;
}

.payment_BUUTON:hover {
    background: #025041 !important;
    color: #FFF;
}

.payment_BUUTON {
    background-color: #025041 !important;
    color: #FFF;
}

.className_divGridhing {
    text-align: center;
    padding: 16px 0px;

    p {
        color: #1D1D1D;
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
    }
}

.main_div_detels {
    border-radius: 10px;
    background: #FFF;
    margin-left: 20px;
    box-shadow: 0px 1px 10px 0px rgba(17, 17, 17, 0.10);
    // padding: 30px;
    margin-top: 30px;
    // height: 100%;
}

.previwe_className {
    padding: 25px 0px 0px 12px;
}

.className_logo {
    display: flex;
    align-items: center;

    .img_main_div {
        width: 70px;
        height: 70px;
        margin-right: 10px;

        img {
            width: 100%;
            height: 100%;

            // border-radius: 50%;
        }
    }

    .namw_typogarafi {
        color: #1D1D1D;
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

    }
}

.des_classname {

    p {
        color: #1D1D1D;
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        padding-top: 14px;
    }
}

.div_amounttypodiv {
    display: flex;
    padding-top: 8px;

    .currancy_typo {
        color: #1D1D1D;
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    .amountfilddd {
        color: #1D1D1D;
        font-family: Poppins;
        font-size: 23px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        padding-left: 6px;
    }
}

.logo_bottom_this {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 45px;
    width: 100%;

    .div_img_logo {
        padding: 0px 5px 0px 5px;
    }

    p {
        color: #1D1D1D;
        font-family: Poppins;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    a {
        color: #025041;
        font-family: Poppins;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        padding: 0px 3px;
    }
}

.from_contean_data_setp2 {
    border-radius: 0px 10px 10px 0px;
    height: 100%;
    padding: 25px 10px;
    border-left: 1px solid #FFF;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
    position: relative;

}

.button_clsssIconButton {
    position: absolute;
    bottom: 10px;
    width: 94%;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        color: #1D1D1D;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
}

.grid_imsaggsagsgs {
    padding: 10px;
}

@media only screen and (max-width: 820px) {

    .ClassName_Grid {
        padding: 40px 20px;

    }

    .main_div_detels {
        margin-left: 0px;
    }

    .logo_bottom_this {
        display: none;
    }

    .className_divGridhing {
        padding-top: 20px;
    }
}

@media only screen and (max-width: 450px) {

    .ClassName_Grid {
        padding: 40px 20px;

    }

    .main_div_detels {
        margin-left: 0px;
    }

    .logo_bottom_this {
        display: none;
    }

    .className_divGridhing {
        padding-top: 20px;
    }
}