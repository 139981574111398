.payablegrid {
    margin: 45px;
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(17, 17, 17, 0.16);

    &__content {
        padding: 32px;
    }

    &__header {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        color: #025041;
        padding: 25px 30px;
        border-bottom: 1px solid #DDDDDD;
    }

    &__search-row {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 10px;
        margin-bottom: 24px;
    }

    &__filter-wrapper {
        display: flex;
        background: #FFFFFF;
        border: 1px solid #DDDDDD;
        border-radius: 100px !important;
        outline: none;

        font-style: normal;
        font-weight: 500 !important;
        font-size: 11px !important;
        color: #111111;

        .input-group-text {
            border: none;
            height: 30px;
            background-color: #FFFFFF;
            border-bottom-left-radius: 15px;
            border-top-left-radius: 15px;
        }

        &.input {
            width: 160px;
            height: 32px;
        }

        &.dropdown {
            max-width: 114px !important;
            height: 32px;
        }

        .form-select {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            line-height: 20px;
            letter-spacing: 0.03em;
            color: #383838;
        }

        &.custom {
            width: 94px !important;
            display: flex;
            align-items: center;
            gap: 13px;
            justify-content: center;
            cursor: pointer;
        }

        .icon {
            display: flex;
            align-items: center;
            padding: 0px 0px 0px 12px;

            svg,
            img {
                margin-right: 4px;
            }
        }

        .payablegrid-search-input {
            border: none;
            border-radius: 100px;
            font-family: 'Roboto Serif';
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 20px;
            letter-spacing: 0.03em;
            color: #111111;
            outline: none;
            height: 30px;
        }
    }

    .table> :not(caption)>*>* {
        border-bottom: none;
    }

    table {
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 2px 14px 2px rgba(174, 174, 174, 0.25);
        border-radius: 8px;
        padding: 6px;

        td,
        th {
            text-align: start;
            padding: 14px 45px 14px 8px;
        }

        th {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.01em;
            color: #000000;
        }

        td {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.03em;
            color: #000;
        }

        td:first-child {
            padding: 14px 10px 14px 20px;
        }

        tr:nth-child(even) {
            background: #ffffff;
        }
    }

    &__btn-row {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 30px;
        column-gap: 10px;

        .btn {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0.05em;
            color: #FFFFFF;
            border-radius: 6px;

            &:disabled {
                background: #CFCFCF !important;
            }
        }

        &__green {
            background: #025041 !important;
            color: #FFFFFF !important;
            border-color: #CFCFCF !important;
            padding: 11px 40px;
            margin-left: 10px;
        }

        &__white {
            color: #000 !important;
            background-color: #FFFFFF !important;
            border-color: #015241 !important;
            padding: 11px 25px;

        }
    }

    .btn {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.05em;
        height: 48px;
        padding: 12px 24px;
        border-radius: 6px;
    }

    .white-btn {
        border: none;
        background-color: #ffffff !important;
        color: #000 !important;
    }

    .green-btn {
        background: #025041 !important;
        color: #ffffff !important;
        border-color: #025041 !important;
    margin-right: 15px;
    }

    .add-btn {
        background: #F3F3F3 !important;
        color: #282828 !important;
        border: none !important;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        padding: 12px 16px;

        svg {
            margin-left: 10px;
        }
    }

    &__btn-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 34px;
    }

    &__errorMsg {
        color: red;
        font-size: 14px;
    }

    .form-check-input {
        width: 20px;
        height: 20px;
        border: 2px solid #202D4C;
        border-radius: 4px;
    }

    .form-check-input:checked {
        background-color: #FFFFFF;
        border-color: #202D4C;
        border-radius: 4px;
    }

    .form-check-input:checked[type=checkbox] {
        padding: 3px;
        background: url("https://placehold.it/15/5C5/FFF") no-repeat scroll right center #FFFFFF;
        background-origin: content-box;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAICAYAAADA+m62AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACPSURBVHgBfc6xDcIwEAXQf+ejyhJHhwQSGYF0FJGACZiJEdiADcwIHsEjpEgHiTmnSIEs/8ryf/4yoRLdnRUid8j4kCraOJ+P+DSR6ogUCcFWO9JD75dWxlsM76GEYngNDLILwgnfxm/3/bGE8g7Duc6KaA/axFYWUM7yR22vimmypVREK1zxPF/A/PxHOT9tiTnPmWnYZwAAAABJRU5ErkJggg==');
    }

    .radio-btn-check {
        width: 20px;
        height: 19px;
        border-radius: 4px;
    }


}
@media only screen and (max-width: 820px) {

    .payablegrid {
        margin: 15px;

        &__btn-row {
            display: block;
            margin-top: 20px;

        }
        &__btn-row_twoo{
            display: flex;
            justify-content: space-between;
            padding-top: 20px;
        }
    }
   
}
@media only screen and (max-width: 450px) {

    .payablegrid {
        margin: 15px;

        &__btn-row {
            display: block;
            margin-top: 20px;

        }
        &__btn-row_twoo{
            display: flex;
            justify-content: space-between;
            padding-top: 20px;
        }
    }
   
}