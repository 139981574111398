.main_dev_contean {
    max-width: 450px;
    margin: auto;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 2px 14px 2px rgba(174, 174, 174, 0.25);
    padding: 45px 70px;
    text-align: center;

    .heding_tage_listtt {
        color: #1D1D1D;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    .contean_loader {
        margin-top: 60px;
        border-radius: 13px;
        border: 1px solid #DDD;
        padding: 10px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .img_and_p_tage {
            img {
                width: 60px;
                height: 60px;
            }

            p {
                color: #1D1D1D;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                padding-top: 8px;
            }
        }


    }

    .des_text_data {
        color: #515151;
        font-family: Poppins;
        font-size: 8px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        padding-top: 18px;
    }

    .div_button_footer {
        padding-top: 60px;

        button {
            border-radius: 5px;
            background: #025041;
            padding: 5px 60px;
            color: #FFF;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            border: 0px solid;
        }
        button:hover{
            background: #025041;
            border: 0px solid;
        }


    }
}
@media only screen and (max-width: 450px) {
    .main_dev_contean {
        padding: 45px 30px;
    }

}