.auth-trans-container {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 32px;
    width: 635px;
    height: 370px;
    margin: 90px auto;
    box-shadow: 0 3px 11px hsla(0,2%,61%,.25);
    &__heading {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: #292929;
        margin-bottom: 16px;
    }

    &__desc {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.04em;
        color: #000;
        margin-bottom: 24px;
    }

    &__label {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.04em;
        // color: #515151;
        color: #000;
        /* added by  by VL 9 mar NIO-25 */
        margin-bottom: 8px;
    }

    .input-wrapper {
        .auth-trans-form-input {
            border: 1px solid #DDDDDD;
            border-radius: 4px;
            height: 48px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;

            &::placeholder {
                color: #DDDDDD;
            }
        }

        margin-bottom: 8px;
    }

    &__btn-row {
        display: flex;
        align-items: center;
        justify-content: center !important;
        justify-items:center !important;
        margin-top: 30px;
        column-gap: 10px;

        .btn {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0.05em;
            color: #FFFFFF;
            border-radius: 6px;

            &:disabled {
                background: #CFCFCF !important;
            }
        }

        &__green {
            background: #025041 !important;
            color: #FFFFFF !important;
            border-color: #CFCFCF !important;
            padding: 11px 40px;
        }

        &__white {
            border: none !important;
            // color: #707070 !important;
            color: #000 !important;
            /* added by  by VL 9 mar NIO-25 */
            background-color: #FFFFFF !important;
            padding: 11px 25px;
        }
    }
}

@media only screen and (max-width: 800px) {
    .auth-trans-container {
        width: 100%;
        margin: 40px auto;

    }

}
@media only screen and (max-width: 450px) {
    .auth-trans-container {
        width: 100%;
        margin: 40px auto;
        height: 420px;
    }
    #div_authoridse_cls{
    display: block;
    }
#sumintbutton_autorise{
    justify-content: space-between;
    padding-top: 20px;
}
}