@import "../../../../styles/variables.module.scss";



.add_payment_method_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:0px;
    padding-bottom: 1px solid #DDDDDD;
    .avatar{
        background-color: $color-gray-light;
    }
    .method{
        margin:20px 0px 20px 0px;
    }
    .color_secondary{
        color:$color-text-secondary;
    }
    .heading_wrapper{
        .heading{
            font-weight:$font-weight-semibold;
            color:$color-text-primary;
            font-size: $font-size-xxl;
            font-family: "Poppins";
        }
    }
    .sub_heading{
        font-size: $font-size-xl;
        font-weight: $font-weight-semibold;
        color:$color-text-primary;
        font-weight: $font-weight-medium;
        border-bottom: 1px solid $color-border;
        padding-bottom: 10px;
        margin-bottom: 25px;
        font-family: "Poppins";
    }
    .add_payment_method_btn{
        display: flex;
        align-items: center;
        gap:10px;
        background-color: $color-background-light;
        color:$color-primary;
        border:none;
        outline:none;
        font-family: "Poppins";
        .add_icon{
            font-size: 25px;
            font-weight:$font-weight-semibold;
        }
        .button_text{
            font-size: 18px;
            font-family: "Poppins";
        }
        &:active{
        background-color: $color-background-light !important;
        color:$color-text-secondary !important;
        border:none;
        outline:none;
        }

    }

}




