@import "../../../../../../../../../../styles/variables.module.scss";


.schedule_step2_container{
    .color_secondary{
        color:$color-text-secondary;
    }
    .watch_icon{
        font-size:60px;
        color:$color-primary;
        margin:0px auto 10px auto;
    }
    .confirm_heading{
        font-size: 28px;
        color:$color-primary;
        font-weight: $font-weight-semibold;
        font-family: "Poppins";
    }
    .confirm_sub_heading{
        font-size: $font-size-md;
        font-family: "Poppins";
        color:$color-text-primary;
    }
    .font_md{
        font-size: $font-size-md;
    }
    .box{
        background-color: $color-secondary;
        padding:20px 20px;
        color:$color-text-primary;
        .box_heading{
            font-size: $font-size-xl;
            font-weight:$font-weight-semibold;
            margin-bottom:10px;
            font-family: "Poppins";
        }
        .kes{
            font-weight: $font-weight-semibold;
            font-family: "Poppins";
            
        }
    }
    
}
