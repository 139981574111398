.app {
    min-height: 100vh;
  }
  .rc-table-body td.rc-table-cell, .rc-table-body td.rc-table-cell *{
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 400;
    color: #000;
  }
  .rc-table-header tr th {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 500;
            
    color: #000;
  }
  .searchWrapper{
    border: 1px solid red;
  }
    
