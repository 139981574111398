$bg: #fff;
$fill: #000;
.toolTip_bulk_payment_Step_one {
    background-color: transparent !important;
        padding: 0px !important;
            height: auto !important;
            width: 25px !important;
            margin-left: 5px !important;
            border: none !important;
    
}
.modal-header .btn-close {
    display: none;
}
.BOX_tital_styles{
font-family: Poppins;
font-size: 22px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0em;
text-align: center;
color: #015241;
}
.heding_contean{
    padding:10px 32px 32px 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(222, 226, 230);
    p{
        color: #111;
        font-family: Poppins;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: .03em;
        line-height: 32px;
    }
}
.SelectCurrancyBtn{
    border-radius: 50px !important;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    letter-spacing: 0.03em;
    color: #383838 !important;
    border: 1px solid #ced4da !important;
    background-color: #fff !important;
    padding: .375rem 2.25rem .375rem .75rem !important;
}

.itemMenu{
    padding: 11px 10px 5px 10px;
p{
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #1D1D1D
}
}
.CloseContenar{
    display: flex;
    justify-content: end;
}
.topup-modal-two {
    .modal-content {
        width: 450px;
        background: #ffffff;
        height: auto;
        margin: 0 auto;
    }

    .className_modal_title {
        padding: 10px 0px 0px 32px;
    }

    .modal-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 32px;
        letter-spacing: 0.03em;
        color: #111111;
    }
}
.MenuItemBox{
    display: flex;
    align-items: center;
}
.selectBox{
    border-radius: 50px;
    color: #383838 !important;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: .03em;
    line-height: 20px;
    border: 1px solid #ced4da;
    height: 48px;
    p{
        font-family: Poppins;
        font-size: 14px; 
        color: #383838 !important;
        font-weight: 400;
    }

}
.topup-modal {
    .modal-content {
        width: 600px;
        background: #ffffff;
        height: auto;
        margin: 0 auto;
    }

    .modal-header {
        padding: 32px;
    }

    .modal-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 32px;
        letter-spacing: 0.03em;
        color: #111111;
    }

    .modal-body {
        padding: 32px;
    }

    .tophead {
        display: inline-block;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 11px;
        letter-spacing: 0.04em;
        color: #282828;
    }

    .headingCus {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
        color: #282828;
        margin-bottom: 10px;
        ;
    }

    .subheading {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 11px;
        letter-spacing: 0.02em;
        color: #000;
    }

    .label {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
        color: #000 !important;
    }

    .type-card-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 16px;
    }

    .type-card-header {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 17px;
        letter-spacing: 0.08em;
        color: #000;
        margin: 10px 0px;
        display: flex;
        text-align: center;
        justify-content: space-around;
    }

    .type-card {
        background: #F2F8F7;
        border-radius: 8px;
        padding: 15px 18px;
        height: 128px;
        cursor: pointer;
        svg {
            margin-right: 9px;
        }

        &__name {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 24px;
            letter-spacing: 0.05em;
            color: #282828;
        }

        &__detail {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.08em;
            color: #000;
            margin-top: 13px;
        }
    }

    .active {
        border: 1.6px solid #025041;
    }

    .type-card-twoo {
        background: #F2F8F7;
        border-radius: 8px;
        padding: 15px 18px;
        height: 128px;
        cursor: pointer;

        svg {
            margin-right: 9px;
        }

        &__name {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 24px;
            letter-spacing: 0.05em;
            color: #282828;
        }

        &__detail {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.08em;
            color: #000;
            margin-top: 13px;
        }
    }

    .active {
        border: 2px solid #025041;
    }

    .btn {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.05em;
        height: 48px;
        padding: 12px 36px;
        border-radius: 6px;
    }

    .btn:disabled {
        background: #F3F3F3 !important;
        border: none;
        color: #111111 !important;
        opacity: 1;
    }

    .white-btn {
        background-color: #fff !important;
        border-color: #015241 !important;
        color: #000;
        padding: 11px 25px;
    }

    .green-btn {
        background: #025041 !important;
        color: #ffffff !important;
        border: 0px solid;
    }
}
.topup-modal3 {

    .modal-content {
        background: #FFFFFF;
        border-radius: 12px;
        margin: 20px auto;
        padding:0px;
        box-shadow: 0px 2px 11px rgba(158, 155, 155, 0.25);
        width: 100% !important;
    }
}
.topup-modal2 {

    .modal-content {
        background: #FFFFFF;
        border-radius: 12px;
        margin: 20px auto;
        padding: 10px 32px 48px 32px !important;
        box-shadow: 0px 2px 11px rgba(158, 155, 155, 0.25);
        width: 100% !important;
    }

    &__description {
        display: flex;
        justify-content: center;
        margin-bottom: 15px;

        p {
            text-align: center;
            font-weight: 400;
            font-size: 13px;
            line-height: 24px;
            color: #000;
        }
    }

    &__button {
        display: flex;
        justify-content: center;

        .green-btn {
            background: #025041;
            border-radius: 6px;
            width: 376px;
            height: 48px;
            border: 1px solid #025041;
            margin-top: 33px !important;
            color: white;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
        }
    }

    &__heading {
        font-weight: 600 !important;
        font-size: 25px !important;
        line-height: 40px !important;
        text-align: center !important;
        color: #3A3A3A !important;
        margin-bottom: 15px !important;
    }

    &__heading2 {
        font-weight: 600 !important;
        font-size: 35px !important;
        line-height: 40px !important;
        text-align: center !important;
        color: #3A3A3A !important;
        margin-bottom: 15px !important;
    }

    &__table {
        border: 1px solid #DDDDDD;
        border-radius: 8px;
        padding: 0 10px;
        margin: 0px auto;
        width: 100% !important;

        .bottomNone {
            border-bottom: none !important;
        }

        .b-bot {
            border-bottom: 1px solid #DDDDDD;
        }

        .d-flex {
            display: flex;
            align-items: center;
            height: 36px;

            .left {
                font-weight: 400;
                font-size: 13px;
                line-height: 30px;
                color: #000;
                width: 50%;
            }

            .right {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #111111;
                width: 50%;
            }
        }
    }

    .topupbank .input-form-wrapper .text-field,
    .topupbank .complete-profile__upload__label {
        border: 1px solid #B8B8B8;
        border-radius: 6px;
        height: 48px;
        font-size: 12px !important;
    }

    .topupbank .input-form-wrapper {
        margin-bottom: 20px !important;
    }

    .topupbank .input-form-wrapper .text-field:hover {
        color: #111111;
        background-color: #FFFFFF;
        border-color: #025041 !important;
        box-shadow: 0px 0px 0px 4px #02504133
    }

    .user-detail-container__wrapper.topupbank .input-form-wrapper label {
        font-size: 13px;
        line-height: 24px;
        margin: 0;
    }

    .topupbank .complete-profile__upload__txt {
        font-size: 12px !important;
    }

    .topupbank .invalid-feedback {
        font-size: 10px;
    }
}

.autoCompaliat_styles {
    height: auto;
    border: 0px solid #B8B8B8;
    border-radius: 00px;

    div {
        border: 0px solid;
        padding: 0 !important;
    }

    input {
        min-width: 30px;
        width: 100%;
        box-sizing: border-box;
        font-size: 12px;
    }

    input:hover {
        border-color: #025041 !important;
        box-shadow: 0 0 0 4px #02504133;
    }

    input:focus-visible {
        border-color: #025041 !important;
        box-shadow: 0 0 0 4px #02504133;
        outline: none;
    }

    .autoCompaliat_styles fieldset:focus-visible {
        border-color: #025041 !important;
        box-shadow: 0 0 0 4px #02504133;
        outline: none;
        border: none;
    }

    fieldset {
        border-width: 0px;
    }

    span {
        font-size: 10px;
    }
}

.optionsmain {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;

    letter-spacing: 0.03em;

    color: #282828;

}

.description {
    font-size: 8px;
    color: #282828;
}

#sdFsfsfsfsfff {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.heding_data_call {
    color: #282828;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: 0.32px;
    margin-bottom: 0px;
    padding-bottom: 4px;
    // padding-top: 31px;
}

.discrip_the_ahhahas {
    color: #000;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.12px;
}

#bg_clor_add_input {
    background-color: white !important;
}

#fast_go_button {
    width: 100%;
    height: 48px;
    margin-left: 0px;
    margin-top: 10px;
}

#fast_go_button_two {
    width: 92%;
    height: 48px;
    margin-left: 0px;
    margin-top: 10px;
}

.text_new_heding_data {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 40px !important;
    color: #3A3A3A !important;
    margin-bottom: 0px;
}

.err_calss_display {
    // color: red;
    font-size: 0.875em;
    color: #dc3545;
    font-family: 'Poppins';

}

#main_fast_buttun_data {
    margin-bottom: 15px;
}
#type_card_id{
    margin-bottom: 15px;
    display:flex;
    justify-content: center;
    align-items: center;
}
#next_button_dataaa {
    border: none !important;
}

.contenar_main_text_line {
    text-align: center;
    padding: 30px 0px 0px 0px;
    
}

.Conversion_calss_data {
    color: #FF6565;
    padding: 0.5em 0.2em;
    height: 1em;
    font-size: .8em;
}

.classname_text_key_minnn {
    color: #025041;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 15px;
}

.text_err_contenarr_minn {
    color: #025041;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 15px;
}

.kes_text_stylee {
    font-size: 22px;
    color: #025041;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;

}

.Kenyan_heding_text {
    color: #1D1D1D;
    font-size: 12px;
    font-family: Poppins;

}

.current_text_rete {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #000 !important;

}

.CURRENCY_TEXT {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #000 !important;
    padding-bottom: 5px;
    padding-top: 5px;
}

.Checkbox_className {
    padding: 0px;
    margin-right: 8px;
}

.div_pading_data {
    text-align: center;

    .des_data {
        color: #474747;
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .helite_text {
        color: #474747;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}

.hourglass {
    display: block;
    background: $bg;
    margin: 3em auto;
    width: 2em;
    height: 3em;
    animation: hourglass 1s linear infinite;
}

.outer {
    fill: $fill;
}

.middle {
    fill: $bg;
}
#adadadaddddd{
    text-transform: capitalize !important;
}

.methods_logo_img{
   margin-left: 10px;
   margin-right: 10px;
}


@keyframes hourglass {
    0% {
        transform: rotate(0deg);
        box-shadow:
            inset $fill 0 -0em 0 0,
            inset $bg 0 -2em 0 0,
            inset $fill 0 -4em 0 0;
    }

    80% {
        transform: rotate(0deg);
        box-shadow:
            inset $fill 0 -2em 0 0,
            inset $bg 0 -2em 0 0,
            inset $fill 0 -2em 0 0;
    }

    100% {
        transform: rotate(180deg);
        box-shadow:
            inset $fill 0 -2em 0 0,
            inset $bg 0 -2em 0 0,
            inset $fill 0 -2em 0 0;
    }
}

@media only screen and (max-width: 450px) {
    .topup-modal {
        .modal-content {
            padding: 48px 10px !important;
            width: 100% !important;
        }

        &__table {
            width: 100% !important;
        }

    }
    #adadadaddddd {
        padding: 11px 15px !important;
    }
    #aDSaDaDadDddd {
        padding: 10px 10px;
    }

    .first-last-name {
        display: block !important;
    }

    .type-card-list {
        display: block !important;
    }

    .type-card {
        margin-top: 10px;
    }
}

.download_buttoun {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.87) !important;
}

.currency_text {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    color: #025041;
}
.country_text {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    color: #282828;
}
 .Typography_clsamount {
     font-family: Poppins;
     font-size: 10px;
     font-weight: 500;
     color: #025041;
     padding-top: 3px;
     text-align: center;
 }