@import "../../../../../../../../styles/variables.module.scss";

.heading {
    font-size: $font-size-xl;
    font-weight: $font-weight-semibold;
    color: $color-text-primary;
    margin-bottom: 5px;
    font-family: Poppins;
}

.sub_heading {
    font-size: $font-size-md;
    color: $color-text-primary;
    margin-bottom: 5px;
    font-family: Poppins;
}

.uploadBtn {
    height: 50px;
    width: 100%;
    border-radius: 5px;
    font-size: $font-size-lg;
    cursor: pointer;
    padding: 12px 12px;
    background-color: $color-background-light;
    border: 1px solid $color-primary;
    color: $color-primary;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Poppins";
    gap: 15px;

    .uploadIcon {
        font-size: 27px;
    }

    &:hover {
        background-color: $color-primary !important;
        color: $color-text-light !important;

        .iconWrapper svg {

            stroke: $color-text-light !important;
        }
    }

    &:active {
        background-color: $color-primary !important;
        color: $color-text-light !important;
        border: 1px solid $color-primary !important;
    }

    .iconWrapper svg {

        stroke: #025041;
    }
}

// .uploadafterBtn {
//     height: 50px;
//     width: 100%;
//     border-radius: 5px;
//     font-size: $font-size-xl;
//     cursor: pointer;
//     padding: 12px 12px;
//     background-color: $color-background-light;
//     border: 1px solid $color-primary;
//     color: $color-primary;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     font-family: "Poppins";
//     gap: 15px;

//     .uploadIcon {
//         font-size: 27px;
//     }

//     &:hover {
//         background-color: $color-primary !important;
//         color: $color-text-light !important;

//         .iconWrapper svg {
//             // 
//             // stroke: $color-text-light !important;
//             stroke: $color-text-light !important;
//         }
//     }

//     &:active {
//         background-color: $color-primary !important;
//         color: $color-text-light !important;
//         border: 1px solid $color-primary !important;
//     }

//     .iconWrapper svg {

//         stroke: #025041;
//     }
// }