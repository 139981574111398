.document-verified {
    width: 580px;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(117, 114, 114, 0.25);
    border-radius: 8px;
    padding: 48px 64px;
    margin: 10px auto;
    text-align: center;

    &__heading {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 40px;
        text-align: center;
        color: #025041;
        margin-bottom: 12px;
    }

    &__desc {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #000;

        span {
            color: #4D4D4D;
            font-weight: 500;
        }
    }

    &__btn {
        background: #FFFFFF !important;
        border-color: #6D6B6B !important;
        height: 48px;
        border-radius: 6px !important;
        font-family: 'Poppins' !important;
        font-style: normal;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        color: #000 !important;
        padding: 12px !important;
        margin-top: 41px;
        margin-bottom: 12px;
        width: 166px;
    }
}
.Congratulations_CLASS{
    color: #025041 !important;

text-align: center;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 600 !important;
line-height: normal;
}
#Go_back{
    margin: 0 !important;
    background: #025041 !important;
    color: #FFFFFF !important;
    border: 0px solid !important;
    width: 105px !important;
    padding: 6px 12px !important;
    height: 37px !important;
    font-size: 14px !important;
}