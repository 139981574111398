.noDataClass {
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
    padding: 30px 0px;
    height: 400px;
}

.noDataimg {
    display: flex;
    justify-content: center;
    // width: 100%;
    // width: 100px;
    // height: 100px;
}

#bold_data_heding {
    font-weight: 700;
    color: #000;
}