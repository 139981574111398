



.addonebtn {
    text-align: center;
    .download {
        transform: rotate(180deg);   
    }
}
.iconadda {
    padding: 8px;
   
    svg { 
        margin: 0 !important;
    }
}
.iconadda.download {
    transform: rotate(180deg);   
}
.save_btn22 {
    border-radius: 6px !important;
    color: #025041;
    padding: 12px 0;
    height: 50px;
    justify-content: center;
    margin-left: 15px;
    font-family: 'Poppins';
    font-style: normal;
    font-size: 18px;
    display: flex;
    align-items: center;
    border: 1px solid #025041;
    margin: 10px auto 00;
    gap:15px;
    cursor: pointer;
}

.addaffafagga {
    display: none !important;
}





@media only screen and (max-width: 450px) {

   
    #afsfaffff{
padding-bottom: 10px !important;
    }
}