.container_Detail_heder {
    background: #FFF;
    padding: 12px 33px;
    box-shadow: 0px 4px 21px 0px rgba(204, 204, 204, 0.25);

    .container_Detail_heder_box {
        display: flex;
        align-items: center;

        .close_button {}

        .heding_tagemain {
            color: #1D1D1D;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            // padding-left: 30px;

        }

        .Pending_typography {
            color: rgb(226, 126, 70);

            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            padding-left: 20px;
        }
.waiting_to_be_paid_typo{
    color: rgb(160, 109, 200);

            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            padding-left: 20px;
}
        .defolt_typogarafi {
            color: rgb(112, 112, 112);
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            padding-left: 20px;
        }

        .canceled_typogarafi {
            color: #F94040;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            padding-left: 20px;
        }

        .prid_stasts {
            color: rgb(31, 188, 160);
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            padding-left: 20px;

        }

        .new_stasts {
            color: #007AFF;
            padding-left: 20px;

            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }

    .box_main_teobutton {
        display: flex;
        justify-content: end;

        .button_fatsstastr {
            border-radius: 6px;
            background: #F94040;
            padding: 14px 29px;
            color: #FFF;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            border: 0px;
            height: 45px;
        }

        .Approve_button {
            color: #FFF;
            padding: 6px 22px;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            border-radius: 6px;
            background: #025141;
            border: 0px;
            margin-left: 15px;
            height: 45px;
        }

        .cansal_buttonn {
            border-radius: 6px;
            background: #DDD;
            color: #1D1D1D;
            padding: 6px 29px;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            border: 0px;
            height: 45px;
        }

        .save_Button {
            color: #1D1D1D;
            border: 0px;
            padding: 6px 29px;
            height: 45px;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            background-color: #FFF;
            margin-left: 15px;

        }
    }
}
.GOback_button {
    color: #1D1D1D;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    background-color: #fff;
    border: 0px solid;
    padding: 0px;
    // margin-bottom: 30px;
}

.GOback_button:hover {
    background-color: #fff;
}

.GOback_button:active {
    background-color: #fff !important;
}
@media only screen and (max-width: 450px) {

.container_Detail_heder{
    padding: 18px 15px;
    .box_main_teobutton{
        padding-top: 10px;
    }
.button_fatsstastr {
margin-bottom: 0px;
    }
    .Approve_button {
        margin-bottom: 0px;

    }
    .cansal_buttonn {
        margin-bottom: 0px;
    }
    .save_Button {
        margin-bottom: 0px;
    }
}
}