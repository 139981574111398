$primary-color: #025141;
$completed-color: #025041;
$inactive-color: #e0e0e0;
$text-color-light: #a0a0a0;
$text-color-dark: #282828;
.verticalStepNavContainerautoWidth{
    display: flex;
    flex-direction: column;
    gap: 26px;
    padding: 20px 0;
    position: relative;
    width:160px;
}
.verticalStepNavContainer {
    display: flex;
    flex-direction: column;
    gap: 26px;
    padding: 20px 0;
    position: relative;
    width: 260px;
}


.stepItem {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease;
    height:25px;
    // &:hover {
    //     opacity: 0.8;
    // }
}

.connectingLine {
    position: absolute;
    left: 6px;
    top: -35px;
    width: 1px;
    height: calc(190% + 0px);
    background-color: $inactive-color;
    transform: translateX(-85%);
    transition: background-color 0.3s ease;
    z-index: -1;

    &.lineCompleted {
        background-color: $completed-color;
    }
}

.stepCircle {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: $inactive-color;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    font-weight: bold;
    transition: all 0.3s ease;
    border: 2px solid $inactive-color;
    z-index: 1;
    flex-shrink: 0 !important;

    &.circleCompleted {
        background-color: $completed-color;
        border-color: $completed-color;
        color: white;
    }
}

.stepContent {
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.stepLabel {
    color: $text-color-light;
    transition: color 0.3s ease;
    font-family: "Poppins";
    font-size: 13px;

    &.labelCompleted {
        color: $text-color-dark;
    }
}

.stepDescription {
    color: $text-color-light;
    transition: color 0.3s ease;

    &.descriptionCompleted {
        color: $text-color-dark;
    }
}

.active {
    .stepCircle {
        background-color: $primary-color;
        border-color: $primary-color;
        color: white;
    }

    .stepLabel {
        color: $text-color-dark;
        
    }
}