.div_pirwushdata {
    background: #FFF;
    border: 1px solid rgba(31, 31, 31, 0.10);
    background: rgba(255, 255, 255, 0.00);
    box-shadow: -8px 8px 32px 0px rgba(0, 0, 0, 0.05);
    margin-top: 42px;
    height: 45%;
    display: flex;
    justify-content: center;
    align-items: center;

    .div_contenar_center {
        text-align: center;

        p {
            color: rgba(31, 31, 31, 0.40);
            text-align: center;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
        }
    }
}
@media only screen and (max-width: 450px) {

.div_pirwushdata{
height: 45vh;
}
}