.main_dev_contean {
    max-width: 80%;
    margin:40px auto;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 2px 14px 2px rgba(174, 174, 174, 0.25);
    padding: 45px 50px;
    text-align: center;

    .heding_tage_listtt {
        color: #1D1D1D;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    .div_main_calssname_this {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-top: 40px;

        .contenar_data {
            width: 48%;
            padding-bottom: 10px;
            cursor: pointer;
            border-radius: 7px;
            background: #FFF;
            box-shadow: 0px 0px 7px 0px rgba(17, 17, 17, 0.16);
            margin-bottom: 30px;
            display: flex;
            padding: 12px 17px;
            justify-content: space-between;
            align-items: flex-start;

            img {
                width: 60px;
                height: 60px;
            }

            p {
                color: #1D1D1D;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                padding-top: 8px;
            }

            .date_test_lineee {
                display: flex;
                align-items: center;

                .heding_test_onn {
                    color: #007AFF;
                    font-family: Poppins;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                }

                .calss_moment_date {
                    color: #1D1D1D;
                    font-family: Poppins;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    padding-left: 5px;
                }

            }

            .Deactivate_data {

                display: flex;
                align-items: end;
                // height: 58px;

                button {
                    border-radius: 4px;
                    border: 1px solid #025041;
                    display: flex;
                    padding: 3px 36px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    color: #025041;
                    font-family: Poppins;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    background-color: #FFF;
                }
            }

        }
    }
    
    .conatenar_footer {
        display: flex;
        justify-content: end;
        align-items: center;
        .List_footer {
            color: #fff;
            font-family: Poppins;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            padding: 6px 25px;
            background: #025041;
            border-radius: 6px;
            border: 0px solid;
        }
    }
}

@media only screen and (max-width: 450px) {
    .main_dev_contean {
        padding: 45px 10px;
        max-width: 100%;

        .div_main_calssname_this {
            display: block;
            padding: 40px 15px 0px 15px;

            .contenar_data {
                width: 100%;
            }
        }
    }

}