.addtebalcontenar {
    background: #FFFFFF;
    box-shadow: 0px 3px 11px rgba(158, 155, 155, 0.25);
    border-radius: 10px;
    padding: 17px 25px;

}

.addtebalcontenar22 {
    background: #FFFFFF;
    box-shadow: 0px 3px 11px rgba(158, 155, 155, 0.25);
    border-radius: 10px;
    padding: 20px;
}

.GOback_button {
    color: #1D1D1D;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    background-color: #fff;
    border: 0px solid;
    padding: 0px;
    margin: 12px 0px 15px 0px;
    text-transform: capitalize;
}

.GOback_button:hover {
    background-color: #fff;
}

.GOback_button:active {
    background-color: #fff !important;
}

.contenar_layout_darara {
    margin: auto;
    width: 500px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 3px 11px 0px rgba(158, 155, 155, 0.25);
}

.sjssjss_layout_key_datya {
    padding: 32px;
}

.shsggsggsggsggsg {
    padding-top: 35px;
    height: 100vh;
}

.text_heding_linee {
    color: #292929;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 16px;
    text-align: center;
}

.afaddagfaggaffa {
    border: 1px solid #ddd;
    border-radius: 8px;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    height: 48px;
    line-height: 18px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.afaddagfaggaffa_twoo {
    border: 1px solid #ddd;
    border-radius: 8px;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.ahhahaggakaaja {
    padding-top: 30px;
    border-bottom: 1px solid #dddddd;
    width: 100%;
}

.sjsj_contewnar_ketaaa {
    padding: 25px 0px;
    text-align: center;
    width: 100%;

}

.shhsjsh_close_data {
    border: 1px solid #025041 !important;
    background-color: #025141 !important;
    color: #ffffff !important;
    text-transform: capitalize;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding: 12px 16px;
    border-radius: 6px;
    width: 100%;
    margin-top: 50px;
}

.afaddaffaggaggafgaf {
    font-family: Poppins;
    font-size: 12px;
    color: #212529;
}

.affaggah_button_data {
    min-width: 0px !important;
    color: #717071;
}

.label_ahagagahhaagga {
    color: #000000;
    font-family: Poppins;
    font-weight: 600;
    padding: 5px 0px;
}

.typoinheding {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    color: #2D2C2C;
    text-align: center;
    padding-bottom: 30px;
}

.menuitem {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #282828;
}

.tebalheder th {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #282828;
    border-bottom: 0px solid !important;

}

.tebalheder td {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #000;
    background: #F9F9F9;
    border-bottom: 0px solid !important;

}

.adddivnewcals {
    text-align: end;
    padding-top: 30px;
}

.centerbtn {
    color: #282828 !important;
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize !important;
}

.savebtn {
    color: #FFFFFF !important;
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    text-transform: capitalize !important;
    margin-left: 15px !important;
    background: #025041 !important;
    border-radius: 4px !important;
    width: 179px;
    height: 40px;
}

.savebtn:hover {
    background-color: #025041;
}

.addinviteuser {
    background: #FFFFFF;
    border: 1px solid #474747 !important;
    border-radius: 5px !important;
    font-family: 'Poppins' !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    color: #474747 !important;
    margin: 15px 30px !important;
    text-transform: capitalize !important;
}

.addinviebtn {
    margin-right: 10px !important;
}

.Inactive {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 12px;
    color: #F87161;
    margin-bottom: 0px;
    text-transform: capitalize;
}

.Invited {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 12px;
    color: #007AFF;
    margin-bottom: 0px;
    text-transform: capitalize;
}

.Active {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 12px;
    color: #009E7E;
    margin-bottom: 0px;
    text-transform: capitalize;
}

.addnewdatalog {
    padding: 25px 60px 25px 0px;
    text-align: end;
}

.box_dialog_main {
    padding: 14px 32px;
}

.typo_text {
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 16px !important;
    text-align: center;
    color: #282828 !important;
    max-width: 220px;
}

.grid_senter {
    background-color: #FFFFFF;
    height: 170px;
}

.box_btn_cencal {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
}

.delete_btn {
    font-family: 'Poppins' !important;
    font-size: 14px !important;
    color: #FF3A23 !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
}

.cancel_btn {
    font-family: 'Poppins' !important;
    font-size: 14px !important;
    color: #025141 !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
}

.container_popup {
    display: flex;
    align-items: center;
    height: 100vh;
}

.modal-content {
    height: 0px;
}

.create_intigretion {
    width: 100%;
    max-height: 250px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 3px 11px 0px rgba(158, 155, 155, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 17px;



}

.create_intigretion_btn {
    color: #fff !important;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    padding: 6px 25px;
    border-radius: 6px;
    background: #025141 !important;
    border: 0px;
}

.heading_create {
    color: #2d2c2c;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.44px;
    margin: 0px;
}

.description_create {
    font-size: 13px;
    font-family: Poppins;
    font-weight: 400;
    margin: 0px;
}

.ssjssggsggshshhsh {
    border: 1px solid #025041 !important;
    background-color: #025141 !important;
    color: #ffffff !important;
    margin-right: 43px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    padding: 12px 16px;
    font-family: Poppins;
    text-transform: capitalize;
}

.list_heading_create {
    color: #2d2c2c;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.44px;
    margin-bottom: 40px;
}

@media only screen and (max-width: 450px) {
    .sjssjss_layout_key_datya {
        padding: 10px 13px;
    }

    .erwrwr_contenar_datatat {
        display: block;
    }

    .contenar_layout_darara {
        width: 100%;
    }

    .addnewdatalog {
        padding: 25px 21px 25px 0px;

    }

    .calss_tage_ajajahhahha {
        padding: 10px 13px !important;
        width: 100%;
        overflow-x: auto;
    }

    .tags h1 {
        padding-left: 0px;
    }

    .tags__save {
        margin-right: 0px;
    }
}