.grid_detels_page {
    padding: 32px 42px;

    .GOback_button {
        color: #1D1D1D;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        background-color: #fff;
        border: 0px solid;
        padding: 0px;
        margin-bottom: 30px;
    }

    .GOback_button:hover {
        background-color: #fff;
    }

    .GOback_button:active {
        background-color: #fff !important;
    }

    .classNameBoxline {
        display: flex;
        align-items: center;

        .heding_typolinlk {
            color: #1D1D1D;
            font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            padding-right: 10px;
        }

        .box_typolinkdataa {
            border-radius: 5px;
            // border: 0.5px solid #BBB;
            padding: 7px 16px;
            p {
                color: #1D1D1D;

                font-family: Poppins;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
            }
        }
    }

    .Status_Grid {
        display: flex;
        justify-content: center;
        align-items: center;

        .heding_Status {
            color: #1D1D1D;

            font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
        }

        .text_status_active {
            color: #025041;

            font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            padding-left: 10px;
            text-transform: capitalize;
        }

        .text_status_inactive {
            color: #1D1D1D;
            font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            padding-left: 10px;
            text-transform: capitalize;
        }

        .normal_text_moment {
            color: #1D1D1D;
            font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            padding-left: 10px;
        }
    }
}

@media only screen and (max-width: 820px) {

    .grid_detels_page {
        padding: 34px 20px;

    }

    .Status_Grid {
        justify-content: left !important;
        padding-top: 10px;
    }
}

@media only screen and (max-width: 450px) {

    .grid_detels_page {
        padding: 34px 20px;

    }

    .Status_Grid {
        justify-content: left !important;
        padding-top: 10px;
    }
}