.container_grid {
    padding: 56px 40px;

    .heding_typography {
        color: #1F1F1F;

        font-family: Poppins;
        font-size: 42px;
        font-style: normal;
        font-weight: 600;
        line-height: 48px;
    }

    .typo_heding_footer {
        color: rgba(31, 31, 31, 0.80);

        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
    }

    .two_grid_inputt {
        display: flex;
        justify-content: space-between;
    }

    #id_contean {
        width: 47%;
    }

    .div_buttomborder {
        border-bottom: 1px solid #E0DDD8;
        padding: 15px 0px 0px 0px;
    }

    .contenar_typo_left {
        display: flex;
        justify-content: space-between;

        .fast_contean {
            width: 47%;
            padding-top: 15px;

            .Typography_companyinfo {
                color: #1D1D1D;

                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px;
            }
        }

        .two_contendata {
            width: 47%;
            padding-top: 15px;

            .Typography_companyinfo {
                color: #1D1D1D;

                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px;
            }
        }
    }
}

.Typography_companyinfo {
    color: #1D1D1D;

    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

.two_input_dataa {
    display: flex;
    justify-content: space-between;
}

#in_haf_cambain {
    width: 48%;
}

.description_contean {
    // display: flex;
    justify-content: space-between;
}

.button_description {
    color: #1D1D1D;
    border: 0px solid;
    height: 20px;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    background: #fff;
    margin: 20px 0px;

}

.button_description:hover {
    border: 0px solid;
    background: #fff;

}

.button_lineitem {
    border-radius: 8px;
    background: #025041;
    padding: 13px 21px;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    border: 0px solid;
}

.button_lineitem:hover {
    background: #025041;
    border: 0px solid;
    color: #FFF;

}

.button_lineitem:active {
    background: #025041;
    border: 0px solid;
    color: #FFF;
    background-color: #025041 !important;
}

.button_lineitem:focus-visible {
    background: #025041;
    border: 0px solid;
    color: #FFF;
    box-shadow: none !important;
}

.textarea_Typography {
    color: #1D1D1D;

    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    padding-top: 20px;
}

.textarea_Typography_two {
    color: #1D1D1D;

    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.textarea {
    border: 1px solid rgba(31, 31, 31, 0.40);
    width: 100%;
    padding: 13px;

}

.Typography_styles {
    color: #1D1D1D;

    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    padding-top: 14px;
}

.div_totel_amout {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    border-radius: 4px;
    background: rgba(31, 31, 31, 0.05);
    padding: 12px 24px 12px 24px;



}

.select_box_kes {
    width: 33%;
}

.selext_inputbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.contenar_boad_data {
    display: flex;
    justify-content: end;
    padding-top: 20px;
}

.textarea_Typography_minhithe {
    color: #1D1D1D;

    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding-right: 5px;
}

.conatebar_chackbox {
    display: flex;
    width: 100%;
    justify-content: end;
    padding-top: 10px;
}

#vat_input {
    margin: 0px;
    width: 70%;
}
#disacaunt_input {
    margin: 0px;
    width: 70%;
    display: flex;
    justify-content: space-between;
}

.remove_button {
    display: flex;
    justify-content: end;
}

.PaymentLink_button {
    border-radius: 8px;
    background: #025041;
    text-align: center;
    color: #FFF !important;
    text-decoration: none;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    padding: 12px 0px;
    width: 100%;
    border: 0px solid;

}

.PaymentLink_button:hover {
    color: #FFF;
    background: #025041;
}

.PaymentLink_button:active {
    color: #FFF;
    background: #025041 !important;
    background-color: #025041 !important;
}

.PaymentLink_button:focus-visible {
    color: #FFF;
    background: #025041;
}

.main_addbutton {
    padding-top: 10px;
}

.a_yagesfffff {
    color: #FFF !important;
}

.textarea_classs {
    width: 100%;
}

@media only screen and (max-width: 820px) {
    #vat_input {

        width: 100% !important;
    }

    .div_totel_amout {
        width: 100% !important;
    }

    #div_checkboxx {
        width: 100% !important;
    }
}

@media only screen and (max-width: 450px) {

    .two_grid_inputt {
        display: block !important;
    }

    #id_contean {
        width: 100% !important;
        margin: 10px 0px !important;
        padding-top: 0px !important;
    }

    .contenar_typo_left {
        display: block !important;

        .fast_contean {
            width: 100% !important;
        }

        .two_contendata {
            width: 100% !important;

        }
    }

    .selext_inputbox {
        // display: block !important;
    }

    #vat_input {

        width: 100% !important;
    }

    .div_totel_amout {
        width: 100% !important;
    }

    #div_checkboxx {
        width: 100% !important;
    }
    #disacaunt_input{
        width: 100%;
    }
}