.main_radio_button_container{
    flex-direction: initial;
    margin-left: 3px;
    font-family: "Poppins";

    .main_radio_button {
        span {
            font-family: Poppins;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: #000;
            font-family: "Poppins";
        }
    }
}