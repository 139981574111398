.modal-header .btn-close {
    display: none;
}

.topup-modal {
    .modal-content {
        width: 600px;
        background: #ffffff;
        height: auto;
        margin: 0 auto;
    }

    .modal-header {
        padding: 32px;
    }

    .modal-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 32px;
        letter-spacing: 0.03em;
        color: #111111;
    }

    .modal-body {
        padding: 32px;
    }

    .label {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
        color: #000;
    }

    .type-card-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 16px;
        margin-top: 16px;
    }

    .type-card {
        background: #F2F8F7;
        border-radius: 8px;
        padding: 15px 18px;
        height: 128px;
        cursor: pointer;
        width: 100%;

        svg {
            margin-right: 9px;
        }

        &__name {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 24px;
            letter-spacing: 0.05em;
            color: #282828;
        }

        &__detail {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.08em;
            color: #000;
            margin-top: 13px;
        }
    }

    .active {
        border: 1.6px solid #025041;
    }

    .btn {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.05em;
        height: 48px;
        padding: 12px 36px;
        border-radius: 6px;
    }

    .btn:disabled {
        background: #F3F3F3 !important;
        border: none;
        color: #111111 !important;
        opacity: 1;
    }

    .white-btn {
        background-color: #fff !important;
        border-color: #015241 !important;
        // color: #707070!important;
        color: #000;
        /* added by  by VL 9 mar NIO-25 */
        padding: 11px 25px;
    }

    .green-btn {
        background: #025041 !important;
        color: #ffffff !important;
    }
}

.topup-modal2 {

    .modal-content {
        width: 480px;
        background: #FFFFFF;
        border-radius: 12px;
        margin: 20px auto;
        padding: 48px 32px;
        box-shadow: 0px 2px 11px rgba(158, 155, 155, 0.25);

    }

    &__description {
        display: flex;
        justify-content: center;
        margin-bottom: 15px;

        p {
            text-align: center;
            font-weight: 400;
            font-size: 13px;
            line-height: 24px;
            // width: 340px;
            color: #000;
        }
    }

    &__button {
        display: flex;
        justify-content: center;

        .green-btn {
            background: #025041;
            border-radius: 6px;
            width: 376px;
            height: 48px;
            border: 1px solid #025041;
            margin-top: 40px;
            color: white;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
        }
    }

    &__heading {
        font-weight: 600 !important;
        font-size: 25px !important;
        line-height: 40px !important;
        text-align: center !important;
        color: #3A3A3A !important;
        margin-bottom: 15px !important;
    }

    &__heading2 {
        font-weight: 600 !important;
        font-size: 35px !important;
        line-height: 40px !important;
        text-align: center !important;
        color: #3A3A3A !important;
        margin-bottom: 15px !important;
    }

    &__table {
        border: 1px solid #DDDDDD;
        border-radius: 8px;
        padding: 0 10px;
        margin: 0px auto;
        width: 380px;

        .bottomNone {
            border-bottom: none !important;
        }

        .b-bot {
            border-bottom: 1px solid #DDDDDD;
        }

        .d-flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 36px;

            .left {
                font-weight: 400;
                font-size: 13px;
                line-height: 30px;
                color: #000;
            }

            .right {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #111111;
            }
        }
    }
}

.addonebtn {
    text-align: center;
    padding-top: 15px;
}

.iconadda {
    padding-top: 8px;
}

@media only screen and (max-width: 450px) {

    .topup-modal {
        .modal-content {
            height: 679px;
width: 100% !important;
        }
        .modal-body{
            padding: 15px !important;
        }
    }
}