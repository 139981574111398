.addtebalcontenar {
    background: #FFFFFF;
    box-shadow: 0px 3px 11px rgba(158, 155, 155, 0.25);
    border-radius: 10px;
}

.addtebalcontenar22 {
    background: #FFFFFF;
    box-shadow: 0px 3px 11px rgba(158, 155, 155, 0.25);
    border-radius: 10px;
    padding: 20px;
}

.typoinheding {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    color: #2D2C2C;
    text-align: center;
    padding-bottom: 30px;
}

.menuitem {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #282828;
}

.tebalheder th {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #000000;
    background: #F8F8F8;

}

.tebalheder td {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #474747;
}

.adddivnewcals {
    text-align: end;
    padding-top: 30px;
}

.centerbtn {
    color: #282828 !important;
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize !important;
    /* margin-left: 15px; */
}

.savebtn {
    color: #FFFFFF !important;
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    text-transform: capitalize !important;
    margin-left: 15px;
    background: #025041 !important;
    border-radius: 4px !important;
    width: 179px;
    height: 40px;
}

.savebtn:hover {
    background-color: #025041;
}

.cerete_user {
    border-radius: 5px;
    border: 1px solid #025041;
    width: 179px;
    height: 40px;
    color: #025041;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-transform: capitalize;
}

.addinviteuser {
    background: #FFFFFF;
    border: 1px solid #474747 !important;
    border-radius: 5px !important;
    font-family: 'Poppins' !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    color: #474747 !important;
    margin: 15px 30px !important;
    text-transform: capitalize !important;
}

.addinviebtn {
    margin-right: 10px !important;
}

.Inactive {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 13px;
    color: #F87161;
    margin-bottom: 0px;
    text-transform: capitalize;
}

.Invited {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 13px;
    color: #007AFF;
    margin-bottom: 0px;
    text-transform: capitalize;
}

.Active {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 13px;
    color: #009E7E;
    margin-bottom: 0px;
    text-transform: capitalize;
}

.Create_Team_heding {
    color: #2D2C2C;
    font-family: Poppins;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
}

.addnewdatalog {
    padding: 25px 60px 25px 60px;
    text-align: end;
}

.box_dialog_main {
    padding: 14px 32px;
}

.typo_text {
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 16px !important;
    text-align: center;
    color: #282828 !important;
    max-width: 220px;
}

.grid_senter {
    background-color: #FFFFFF;
    height: 170px;
}

.box_btn_cencal {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
}

.delete_btn {
    font-family: 'Poppins' !important;
    font-size: 14px !important;
    color: #FF3A23 !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
}

.cancel_btn {
    font-family: 'Poppins' !important;
    font-size: 14px !important;
    color: #025141 !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
}

.container_popup {
    display: flex;
    align-items: center;
    height: 100vh;
}

.modal-content {
    height: 0px;
}

@media only screen and (max-width: 450px) {

    .erwrwr_contenar_datatat {
        display: block;
    }

    .addnewdatalog {
        padding: 25px 21px 25px 25px;

    }
}