.tags {
    &__header {
        background: #FFFFFF;
        box-shadow: 0px 4px 21px rgba(204, 204, 204, 0.25);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 43px;

        &__name {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 20px;
            font-family: "Poppins";
            color: #025041;
        }
    }

  .btn {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      padding: 12px 16px;
      border-radius: 6px;
    }

  &__white-btn {
      border: 1px solid #025041 !important;
      background-color: white !important;
      color: #025141 !important;
    }

  &__save-btn {
      border: 1px solid #025041 !important;
      background-color: #025141 !important;
      color: #FFFFFF !important;
      margin-right: 43px;
    }
    h1{
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        color: #025041;
        font-family: "Poppins";
        // margin-left: 15px;
        // padding-left: 43px;
    }
}
@media only screen and (max-width: 450px) {

.calss_tage_ajajahhahha{
  padding: 10px 13px !important;
  width: 100%;
  overflow-x: auto;
}
.tags {
  h1{
    padding-left: 0px;
  }
  &__save-btn {
    margin-right: 0px;
  }
}
}