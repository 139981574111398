.tabalinputdata {
    height: 30px;
    border-radius: 0;
    margin: 0 !important;
    display: block !important;
    font-size: 12px;

    div {
        width: 100%;
        height: 40px;
        font-family: 'Poppins';
        font-size: 12px;
        border-radius: 00;
    }

    fieldset {
        border-color: #F0F0F0;
    }

    fieldset:focus-visible {
        border-color: red;
    }

    fieldset:hover {
        color: #111111;
        background-color: #FFFFFF;
        border-color: #025041 !important;
    }
}
.saveButton {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding: 12px 16px;
    border-radius: 6px;
    border: 1px solid #025041 !important;
    background-color: #025141 !important;
    color: #FFFFFF !important;
}

.saveButton:focus-visible {
    box-shadow: none;
}

.GOback_button {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding: 0px;
    border-radius: 6px;
    border: 0px solid;
    background-color: #FFFFFF !important;
    color: #000;
}

.Box_layoutclass {
    box-shadow: 0px 3px 11px 0px #9E9B9B40;
    background: #FFFFFF;
    border-radius: 10px;
    margin: 50px;
    padding: 30px;
}

.typo_MAIN {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    color: #1D1D1D;
}

.gridEmailCom {
    padding: 12px 43px;
}

.ReactMultiEmailstyle {
    font-family: Poppins !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 20px;
    text-align: left;
    color: #1D1D1D !important;
    border: 0px solid !important;
    // height: 52px;
}

.Box_classinput {
    margin-top: 14px;
    border: 0.5px solid #8C8C8C;
    border-radius: 10px;
}

.Box_conteansubject {
    display: flex;
    align-items: center;
    border-bottom: 0.5px solid #A8A8A8;
    padding: 12px 20px;

}

.inputSubject {
    border: 0px solid;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #1D1D1D;
}

.inputSubject:focus {
    box-shadow: none !important;
}

.Grid_Conteanitem {
    padding-top: 40px;
}

.text_subject {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #6D6B6B;
}

.BoxClasstextarea {
    padding: 15px 20px;
}

.textarea {
    border: 0px solid;
    width: 100%;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    color: #1D1D1D;
}

.textarea:focus-visible {
    outline: none !important;
}

.typo_to {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #6D6B6B;
    padding-right: 38px;
}

.Box_conteanemail {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    border: 0.5px solid #8C8C8C;
    border-radius: 10px !important;

}

.ButtonClassnormal {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #025041;
    background-color: #FFFFFF;
    border: 0px solid;
padding: 0px !important;
}

.ButtonClassnormal:hover {
    color: #025041;
    background-color: #FFFFFF;
    border: 0px solid;
}

.ButtonClassnormal:active {
    color: #025041 !important;
    background-color: #FFFFFF !important;
    border: 0px solid !important;
}

.RecurringBox {
    border: 1px solid #B8B8B8;
    border-radius: 9px;
    padding: 10px;
}

.buttonstylesrecring {
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #DDDDDD;
    background-color: #fff;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #1D1D1D;
    width: 32%;
    gap: 2px;
}

.buttonstylesrecring:hover {
    border: 1px solid #DDDDDD;
    background-color: #fff;
    color: #1D1D1D;
}

.buttonstylesrecring:active {
    border: 1px solid #DDDDDD !important;
    background-color: #fff !important;
    color: #1D1D1D !important;
}

.buttonstylesrecringactive {
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #DDDDDD;
    background-color: #F0F1F1;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #1D1D1D;
    width: 32%;
    gap: 2px;
}

.buttonstylesrecringactive:hover {
    border: 1px solid #DDDDDD;
    background-color: #F0F1F1;
    color: #1D1D1D;
}

.buttonstylesrecringactive:active {
    border: 1px solid #DDDDDD !important;
    background-color: #F0F1F1 !important;
    color: #1D1D1D !important;
}

.createButtonstyle {
    // padding: 6px 12px;
    padding: 12px 16px;

    background: #025041;
    border-radius: 5px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: center;
    color: #FFFFFF;
    // border: 0px solid;
    border: 1px solid #025041 !important;

    // width: 100%;
}
.createButtonstyle:hover {
    background: #025041;
    color: #FFFFFF;
    border: 0px solid;

}
.createButtonstyle:active {
    background: #025041 !important;

}
.createButtonstyle:disabled{
    background: #025041 !important;
}

.SaveButtonstyle{
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding: 12px 16px;
    border-radius: 6px;
    border: 1px solid #025041 !important;
    background-color: white !important;
    color: #000 !important;
}
.SaveButtonstyle:hover {
    border: 1px solid #025041 !important;
    background-color: white !important;
    color: #000 !important;
}
.SaveButtonstyle:active {
    border: 1px solid #025041 !important;
    background-color: white !important;
    color: #000 !important;
}
.DeleteButtonstyle{
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding: 12px 16px;
    border-radius: 6px;
    border: 1px solid #DF3A3A !important;
    background: #DF3A3A !important;
    color: #FFF !important;

}
.DeleteButtonstyle:hover{

    border: 1px solid #DF3A3A !important;
    background: #DF3A3A !important;
    color: #FFF !important;

}
.DeleteButtonstyle:active{

    border: 1px solid #DF3A3A !important;
    background: #DF3A3A !important;
    color: #FFF !important;

}
.gridcontainer{
    padding: 20px 30px;
}
.Typography_Normal{
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.03em;
    color: #000;
}
.CountrySelectBoxclass{
    border-radius: 5px;
    height: 48px;
    font-family: "Poppins";
    outline: none;
    padding: 0px 8px;
    color: #3a3a3a;
    font-size: 12px;
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 11px;
    gap: 8px;
    background: #ffffff;
    border: 1px solid #dddddd;
    margin: 10px 0px;
}
.CountrySelectBoxclass:focus {
    box-shadow: 0px 0px 0px 4px rgba(1, 82, 65, 0.25);
    border-color: #015241;
}
