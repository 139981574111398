.invite-niobi-balance {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 38px;
    width: 635px;
    margin: 50px auto;
    box-shadow: 0 3px 11px hsla(0,2%,61%,.25);
    &__heading {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: #292929;
        margin-top: 14px;
        margin-bottom: 32px;
    }

    label {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 3px;
        color: #000;
        margin-bottom: 4px;
    }

    .doc-form-input {
        outline: none;
        padding: 0px 12px;
        height: 45px;
        background: #FFFFFF;
        outline: none;
        font-weight: 400;
        font-size: 12px;
        font-family: 'Poppins';
        font-style: normal;
        line-height: 24px;
        letter-spacing: 0.05em;
        color: #474747;

        border-radius: 5px;
        border: 1px solid #E9EAED;
        // margin-bottom: 32px;

        &::placeholder {
            color: #6D6B6B;
            font-weight: 400;
        }
    }

    &__btn-row {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;

        .btn {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0.05em;
            color: #FFFFFF;
            border-radius: 6px;
            &:disabled {
                background: #CFCFCF !important;
            }
        }

        &__green {
            background: #025041 !important;
            color: #FFFFFF !important;
            border-color: #CFCFCF !important;
            padding: 11px 40px;
            width: 380px;
        }
    }
}
.addphoneno{
    padding-bottom: 25px;
}
@media only screen and (max-width: 450px) {
    .invite-niobi-balance {
        padding: 20px;
        width: 100%;
    }

}