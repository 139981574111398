.makepayment-trans-detail-container{
    background: #FFFFFF;
    border-radius: 8px;
    padding: 32px;
    width: 530px;
    box-shadow: 0 3px 11px hsla(0,2%,61%,.25);
    position: absolute;
    top:5px;
    &__heading {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #282828;
        margin-bottom: 34px;
    }

    &__table {
        border: 1px solid #DDDDDD;
        border-radius: 8px;
        padding: 0 12px;
        margin-bottom: 18px;

        .bottomNone {
            border-bottom: none !important;
        }

        .b-bot {
            border-bottom: 1px solid #DDDDDD;
        }

        .dflex {
            display: grid;
            grid-template-columns: 1fr 1fr;
            // margin-bottom: 10px;
            align-items: center;
            height: 36px;
            padding: 0 8px;

            .left {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: #798194;
            }

            .right {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 24px;
                letter-spacing: -0.01em;
                color: #202D4C;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }

    &__btn-row {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 65px;
        column-gap: 16px;

        .btn {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0.05em;
            color: #FFFFFF;
            border-radius: 6px;

            &:disabled {
                background: #CFCFCF !important;
            }
        }

        &__green {
            background: #025041 !important;
            color: #FFFFFF !important;
            border-color: #CFCFCF !important;
            padding: 11px 15px;
            width: 100%;
        }
        &__white {
            background-color: #fff!important;
            border: 1px solid #025041!important;
            color: #025141!important;
            color: #000!important;
            padding: 11px 15px;
            width: 100%;
        }
       
    }
}
#typestyle{
    background-color: #202D4C;
    padding: 4px;
    border-radius: 4px;
    color: #fff;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: min-content;
    height: auto;
    padding: 0px 15px;

    // width: 10;
}
#typestyle2{
    background-color: #009e7e;
    padding: 4px;
    border-radius: 4px;
    color: #fff;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: min-content;
    height: auto;
    padding: 0px 15px; 
}
#typestyle3{
    background-color:#f87161;
    padding: 4px;
    border-radius: 4px;
    color: #fff;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: min-content;
    height: auto;
    padding: 0px 15px; 
}
@media only screen and (max-width: 450px) {
    .trans-detail-container {
        padding: 20px;
        width: 100%;
    }

}