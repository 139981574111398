@import "../../../../../../../../styles/variables.module.scss";


.step2_container{
    .reject{
        font-size: $font-size-xl;
        color:red;
        cursor:pointer;
        font-family: 'Poppins';
    }
    .step2_payee_data_wrapper{
        background-color: $color-secondary;
        padding:20px;
        
        .step2_payee_data_container{
            display: flex;
            flex-direction: row;
            column-gap:10px;
        }
        .step2_payee_data_row{
            display: flex;
            justify-content: space-between;
        }
        .color_secondary{
             color:$color-text-secondary; 
        }
    
    }
    .arrowforwardicon{
        font-size:$font-size-xxl;
        cursor: pointer;
    }
    .font-base{
        font-size: $font-size-base;
        font-family: "poppins" !important;
    }



}
.rejection_box{
    width:450px;
    background-color: $color-background-light;
    display: flex;
    flex-direction: column;
    padding:20px;
    gap:10px;
    
    .rejection_heading{
       font-size: $font-size-xxl;
       font-weight: $font-weight-bold;
       margin-bottom: 10px;
    }
    .rejection_subheading{
      color:$color-text-secondary;
      font-family: 'Poppins';

    }
}

