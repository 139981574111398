.mpesa-container {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 32px;
  height: max-content;
  box-shadow: 0 3px 11px hsla(0,2%,61%,.25);

  &__heading {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #292929;
      margin-top: 4px;
      margin-bottom: 39px;
      text-transform: capitalize;
  }

  &__option-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #FFFFFF;
      border: 1px solid #DDDDDD;
      border-radius: 4px;
      padding: 12px;
      margin-bottom: 8px;
      cursor: pointer;
      font-size: 13px;

      &__txt {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 24px;
          letter-spacing: 0.03em;
          color: #111111;
        }
    }
   
  &__errorMsg{
    color: red;
    font-size: 14px;
  }
  .mpaisa.disabled {
    cursor: default;
    background-color: #eee;    
}
  .input-wrapper {
      .mpesa-form-input {
          // border: 1px solid #025041;
          border-radius: 4px;
          height: 48px;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;

          &::placeholder {
              color: #707070;
          }
          
      }
      .mpesa-form-input:focus {
          box-shadow: 0px 0px 0px 4px rgba(1,82,65,0.25) !important;
          border-color: #015241 !important;
      }
      margin-bottom: 8px;
  }

  &__btn-row {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 30px;
      column-gap: 10px;

      .btn {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 24px;
          letter-spacing: 0.05em;
          color: #FFFFFF;
          border-radius: 6px;
          &:disabled {
              background: #CFCFCF !important;
          }
      }

      &__green {
          background: #025041 !important;
          color: #FFFFFF !important;
          border-color: #CFCFCF !important;
          padding: 11px 40px;
      }

      &__white {
          //color: #707070 !important;
          color: #000 !important; /* added by  by VL 9 mar NIO-25 */ 
          background-color: #FFFFFF !important;
          border-color: #015241 !important;
          padding: 11px 25px;
      }
  }
}
.tab_contean_button {
    display: flex;
    justify-content: space-between;

    .difolt_button {
        border-radius: 5px;
        border: 1px solid #DDD;
        color: #000;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        background-color: #FFF;
        line-height: 24px;
        // width: 49%;
        display: flex;
        width: 96%;
        align-items: center;

        img {
            margin-right: 10px;
        }
    }

    .mpesa_button {
        border-radius: 5px;
        border: 1.5px solid #025041;
        color: #000;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        background-color: #FFF;
        line-height: 24px;
        // width: 49%;
        width: 96%;
        display: flex;
        align-items: center;

        img {
            margin-right: 10px;
        }
    }
}
.mpsaimg {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}
.border-green{
    border: 1px solid #025041 !important
}