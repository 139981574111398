@import "../../../../styles/variables.module.scss";

.search_input{
     &:focus{
        border:1px solid $color-primary !important;
        outline: none !important;
     }
}

.payment_search_icon {
    color: $color-text-primary;
}

.payment_search_icon_wrapper {
    background-color: #ffffff;
    border-right: none;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
}
