@import "../../../../../styles/variables.module.scss";



.transaction_success_box{
    padding:40px 8px;
    background-color: $color-background-light;
    width:400px;
    font-family: "Poppins";
    .transaction_success_heading{
        margin-top:20px;
        font-size: 24px;
        color:$color-primary;
        font-weight: $font-weight-semibold;
        font-family: "Poppins";
    }
}