@import "../../../../../src/styles/variables.module";
*{
    box-sizing: border-box;
    padding:0;
    margin:0;
}

.defaultHalfWidthBtn {
    width: 48%;
    border-radius: 5px;
    font-size:$font-size-md;
    cursor:pointer;
    padding:10px 0px 10px 0px;
    background-color: $color-primary;
    border:1px solid $color-primary !important;
    outline: none;
    color:$color-text-light;
    font-family: "Poppins";
    &:hover{
        background-color: $color-primary;
        color:$color-text-light;
        border:1px solid $color-primary !important;
    }
    &:active{
        background-color: $color-primary !important;
        color:$color-text-light !important;
        border:1px solid $color-primary !important;
    }
    &:disabled{
        background-color: $color-primary;
        color:$color-text-light;
        border:1px solid $color-primary;
    }
}


.lightHalfWidthBtn{
    width: 48%;
    border-radius: 5px;
    font-size:$font-size-md;
    cursor:pointer;
    padding:10px 0px 10px 0px;
    background-color: $color-background-light;
    border:1px solid $color-primary;
    color:$color-primary;
    font-family: "Poppins";

    &:hover{
        background-color: $color-background-light;
        color:$color-primary;
        border:1px solid $color-primary;
    }
    &:active{
        background-color: $color-background-light !important;
        color:$color-primary !important;
        border:1px solid $color-primary !important;
    }
    &:disabled{
        background-color: $color-background-light;
        color:$color-primary;
        border:1px solid $color-primary;
    }
}

.textBtn{
    width: 48%;
    border-radius: 5px;
    font-size:$font-size-md;
    cursor:pointer;
    padding:10px 0px 10px 0px;
    background-color: $color-background-light;
    border:none;
    color:$color-primary;
    font-family: "Poppins";

    &:hover{
        background-color:$color-background-light;
        color:$color-text-primary;
        border:none;
    }
    &:active{
        background-color:$color-background-light !important;
        color:$color-text-primary !important;
        border:none !important;
    }
    &:disabled{
        background-color: $color-background-light;
        color:$color-text-primary;
        border:none;
    }
}

.defaultGoBackBtn{
     background-color:$color-background-light;
     border:none;
     color:$color-text-primary;
     cursor:pointer;
     padding-left: 0px;
     font-family: "Poppins";
     &:hover{
        background-color:$color-background-light;
        color:$color-text-primary;
     }
     &:active{
        background-color:$color-background-light !important;
        color:$color-text-primary !important;
     }

}

.downlaodBtn{
    width: 100%;
    border-radius: 5px;
    font-size:$font-size-xxl;
    cursor:pointer;
    padding:12px 0px 12px 0px;
    background-color: $color-background-light;
    border:1px solid $color-primary;
    color:$color-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:15px;
    font-family: "Poppins";
    .downloadIcon{
        font-size:27px;
    }
    &:hover{
        background-color: $color-background-light;
        border:1px solid $color-primary;
        color:$color-primary;
    }
    &:active{
        background-color: $color-background-light !important;
        border:1px solid $color-primary !important;
        color:$color-primary !important;
    }
    &:disabled{
        background-color: $color-background-light;
        border:1px solid $color-primary;
        color:$color-primary;
    }
   
}

.uploadBtn{
    width: 100%;
    border-radius: 5px;
    font-size:$font-size-xxl;
    cursor:pointer;
    padding:12px 0px 12px 0px;
    background-color: $color-background-light;
    border:1px solid $color-primary;
    color:$color-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins";
    gap:15px;
    .uploadIcon{
        font-size:27px;
    }
    &:hover{
        background-color: $color-background-light !important;
        border:1px solid $color-primary !important;
        color:$color-primary !important;
    }
    &:active{
        background-color: $color-background-light !important;
        border:1px solid $color-primary !important;
        color:$color-primary !important;
    }
    &:disabled{
        background-color: $color-background-light;
        border:1px solid $color-primary;
        color:$color-primary;
    }
    
   
}

.LightIconAndTextButton{
     background-color:$color-background-light;
     border:none;
     color:$color-text-primary;
     cursor:pointer;
     padding-left: 0px;
     margin-left: 0px;
     font-family: "Poppins";
     &:hover{
        background-color:$color-background-light;
        color:$color-text-primary;
     }
     &:active{
        background-color:$color-background-light !important;
        color:$color-text-primary !important;
     }
     &:disabled{
        background-color: $color-background-light;
        color:$color-text-primary;
     }
}

.disabledd{
     color: $color-text-secondary !important;
     background-color: $color-background-light !important;
}
