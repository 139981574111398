.box_contenar_data_nodata {
    display: flex;
    justify-content: center;
}

.text_img_footer {
    color: #8C8C8C;
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    padding: 7px 0px 0px 0px;
}

.nodata_link {
    color: #025041;
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 180% */
    text-decoration-line: underline;
}
.nodata_link_block {
    color: #025041;
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-decoration-line: underline;
    cursor: no-drop;
}
.box_class_conteann {
    text-align: center;
    height: 80%;
    justify-content: center;
    display: flex;
    align-items: center;

}