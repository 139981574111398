@import "../../../../../styles/variables.module.scss";


.waiting_container {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 32px;
    width: 600px;
    box-shadow: $box-shadow;
    font-family: "Poppins";

    .waiting_heading {
        font-family: "Poppins";
        color: #025041;
        text-align: center;
        font-family: Poppins;
        font-size: 20px !important;
        font-weight: 500;
    }

    .waiting_text1,
    .waiting_text2 {
        color: #1d1d1d;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
    }
}