.box_contean_imgzoom {}

.box_contean_imgzoom {
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.10);
}

.main_contean_boxxx {
    padding: 15px 23px;
    background: #FFF;
    width: 100%;
    height:98%;
}

.grid_conteannGrid {
    background: #FFF;
    height: 40px;
    // margin-top: 3px;
    // margin-bottom: 3px;
    padding: 10px 0px 0px 0px;
}

.bxox_zoom_icon {
    width: 100%;
    display: flex;
    justify-content: center;
}
@media only screen and (max-width: 450px) {

.main_contean_boxxx{
    height: calc(60vh - 80px);
}
}