@import "../../../../styles/variables.module.scss";


.model_container{
    background-color: $color-secondary;
    width:100%;
    height:100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position:absolute;
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:9999;
    overflow-y: hidden !important;
    color:$color-text-primary;
}