.addteballist td {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 10px;
    color: #000;
    text-align: center;
    background-color: white;
    padding: 2px !important;
}

.addteballist td:first-child {
    width: 1%;
}

.addteballist th {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 13px;
    color: #000;
    text-align: center;
    background: #fff;
    padding: 16px 0px !important;
    text-align: left;
    background: none;
    padding: 10px 10px !important;
    border-top: 1px solid #ddd;
    margin: 0 10px;
    border-bottom: 1px solid #ddd;
}

.addborderin {
    border-bottom: 1px solid #DDDDDD !important;
}
.addnewtext {
    font-family: 'Poppins' !important;
    font-style: normal;
    font-size: 24px !important;
    color: #111111;
}

.listrowadd {
    div {
        height: 35px;
        width: 60px;
        font-family: Poppins-400 !important;
    }
}

.tabalinputdata {
    height: 42px;
    width: 100px;
    div {
        width: 100%;
        height: 40px;
        font-family: 'Poppins';
        font-size: 12px;
        border-radius: 00;
    }
}
#from_select{
    font-size: 12px;
}
.tableDropdown {
    height: 42px;
    width: 100px !important;
    font-family: 'Poppins' !important;
    font-size: 12px !important;
    border-radius: 0 !important;
}

.white_btn {
    border: 1px solid #025041 !important;
    background-color: white !important;
    color: #025141 !important;
    margin-right: 10px;

}

.save_btn {
    border: 1px solid #025041 !important;
    background-color: #025141 !important;
    color: #FFFFFF !important;
    padding: 12px 30px !important;
    margin-right: 10px;
}
#Bank_Name_Select{
    width: 100px;
}
.save_btn22 {
    border-radius: 6px !important;
    color: #282828 !important;
    width: 148px;
    height: 38px;
    justify-content: center;
    margin-left: 15px;
    font-family: 'Poppins';
    font-style: normal;
    font-size: 12px;
    display: flex;
    align-items: center;
    border: 1px solid #025041;
}

.tabalinputdatadattag div {
    height: 40px;
    font-family: "Poppins";
    font-size: 12px;
    border-radius: 0 !important;
}

.save_btn22 svg {
    margin-left: 11px;
}

.peregarafdata {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 12px;
    color: red;
}

.addnewselect div {
    height: 40px;
}

#addnewinput {
    display: block !important;
    border: 1px solid #DDDDDD;
    border-radius: 8px;
    height: 42px;
    font-size: 1rem;
    padding: 0px 10px;
    width: 138px;
}

#addnewkeydata {
    display: block !important;
}

.addaffafagga {
    display: none !important;
}

.addnewdaddaa {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 12px;
    color: red;
}

.addredilebal span {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 14px;
}

@media only screen and (max-width: 450px) {
    .afdafafagalfff {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 5px 0px 15px;

    }

    .adhuadhuwqhhw {
        display: block !important;
    }

    .tebaldata_hedinh {
        display: block;
    }

    .div_uplod_data_use {
        display: flex;
        justify-content: end;
        padding-top: 12px;
    }

    .add_delete_data_listtss {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .div_laiiaajjajjaa {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 12px;
    }

    .add_ajajjahahag {
        display: block !important;
        padding: 20px 10px !important;
    }
}