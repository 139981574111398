.div_pirwushdata {
    background: #FFF;
    border: 1px solid rgba(31, 31, 31, 0.10);
    background: rgba(255, 255, 255, 0.00);
    box-shadow: -8px 8px 32px 0px rgba(0, 0, 0, 0.05);
    margin-top: 42px;
    padding: 30px 20px 20px 20px;

    // height: 100vh;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    .main_div_class {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .name_heding {
            color: #1D1D1D;

            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
        }

    }

    .contenar_secasan {
        display: flex;
        justify-content: space-between;
        padding-top: 30px;

        .fast_divdass {
            width: 30%;
.hedingpaylod_popup{
    color: #1D1D1D;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px
}
            .hedingpaylod {
                color: #1D1D1D;
                font-family: Poppins;
                font-size: 9px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px
            }
        }

        .classname_two_div {
            width: 30%;

            .ptages_hedingbill {
                color: #1D1D1D;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 18px
            }
            .hedingpaylod_popup{
                color: #1D1D1D;
                            font-family: Poppins;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 18px
            }
            .hedingpaylod {
                color: #1D1D1D;
                font-family: Poppins;
                font-size: 9px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px
            }
        }

        .threed_div {
            width: 30%;

        }
    }
}

.item_typography {
    color: #1D1D1D;
    font-family: Poppins;
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}
.item_typography_popup {
    color: #1D1D1D;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}
.div_buttomborder {
    border-bottom: 1px solid #E0DDD8;
}

.grid_contenar_item {
    overflow-y: scroll;
    height: 130px;
    margin-top: 30px;
}

.loop_contenardata {
    padding: 14px 0px;
    border-bottom: 1px solid #E0DDD8;
}

.Typography_heding {
    color: #1D1D1D;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

.Gridcontainer {
    padding-top: 15px;
}

.footer_logotypo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;

    p {
        color: #6D6B6B;
        font-family: Poppins;
        font-size: 8px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        padding-right: 5px;
    }
}

.preview_button {
    border-radius: 8px;
    border: 1px solid #1D1D1D !important;
    padding: 12px 142px 12px 135px;
    display: flex;
    padding: 12px 142px 12px 135px;
    align-items: center;
    justify-content: center;
    // flex: 1 0 0;
    width: 100%;
    background-color: #FFF;
    margin-top: 32px;

    p {
        color: #1D1D1D;

        text-align: center;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        padding-left: 10px;
    }
}

.preview_button:active {
    background-color: #FFF !important;
    border: 1px solid #1D1D1D !important;
}

.preview_button:hover {
    background-color: #FFF;
    border: 1px solid #1D1D1D !important;
}

.Share_Invoicecontean {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;

    .fast_button_data {
        border-radius: 8px;
        border: 1px solid #025041;
        height: 40px;
        padding: 13px 10px;
        color: #025041;
        width: 49%;
        text-align: center;
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        background-color: #FFF;
    }

    .fast_button_data:hover {
        background-color: #FFF;
        border: 1px solid #025041;
    }

    .two_button_invoice {
        border-radius: 8px;
        background: #025041;
        padding: 13px 19px 13px 19px;
        color: #FFF;
        width: 49%;
        text-align: center;
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        border: 0px solid;
    }

    .two_button_invoice:hover {
        border: 0px solid;
        background: #025041;
    }
}

.contenar_divdialog {
    border-radius: 10px;
    background: #FFF;
    padding: 30px;
    box-shadow: 0px 1px 9px 0px rgba(17, 17, 17, 0.15)
}

.two_button_invoice_data {
    border-radius: 8px;
    background: #025041;
    padding: 13px 32px 13px 31px;
    color: #FFF;
    width: 100%;
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    border: 0px solid;
    margin-top: 30px;
}

.two_button_invoice_data:hover {
    border: 0px solid;
    background: #025041;
    color: #FFF;
}
.two_button_invoice_data:active {
    border: 0px solid;
    background: #025041 !important;
    color: #FFF;
    background-color: #025041 !important;
}
.Share_Invoice {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        color: #025041;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }

    svg {
        width: 25px;
    }
}

.div_buttomborder {
    border-bottom: 1px solid #D9D9D9;
    padding-top: 25px;

}

.text_Typography {
    color: #000;
    padding-top: 20px;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.img_and_inputcls {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
        margin-top: 13px;
    }
}

#div_classname {
    width: 88%;
}
.niobi-payment-error {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 32px;
    // width: 440px;
    margin: 25px auto;
    box-shadow: 0 3px 11px hsla(0,2%,61%,.25);
    &__text-red{
        color: red;
    }
    &__logo {
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
    }

    &__heading {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #257D6B;
        margin-bottom: 20px;
    }

    &__desc {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.03em;
        color: #000;
        margin-bottom: 34px;
    }

    &__table {
        border: 1px solid #DDDDDD;
        border-radius: 8px;
        padding: 0 12px;
        margin-bottom: 18px;

        .bottomNone {
            border-bottom: none !important;
        }

        .b-bot {
            border-bottom: 1px solid #DDDDDD;
        }

        .dflex {
            display: grid;
            grid-template-columns: 1fr 1fr;
            // margin-bottom: 10px;
            align-items: center;
            height: 36px;
            padding: 0 8px;

            .left {
                font-weight: 400;
                font-size: 13px;
                line-height: 30px;
                color: #000;
            }

            .right {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #111111;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                padding-right: 15px;
            }
        }
    }

    &__btn-row {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 65px;

        .btn {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0.05em;
            color: #FFFFFF;
            border-radius: 6px;

            &:disabled {
                background: #CFCFCF !important;
            }
        }

        &__green {
            background: #025041 !important;
            color: #FFFFFF !important;
            border-color: #CFCFCF !important;
            padding: 11px 40px;
            width: 380px;
        }
    }
}
@media only screen and (max-width: 450px) {

    .niobi-payment-error{
        padding: 20px;
        width:100%;
    }
}
@media only screen and (max-width: 450px) {
    .fast_button_data {

        padding: 13px 10px 13px 10px !important;
    }
    .two_button_invoice {
        padding: 13px 10px 13px 10px !important;
    }
}