
.counterparty {
  &__header {
    background: #FFFFFF;
    box-shadow: 0px 4px 21px rgba(204, 204, 204, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 43px;

    &__name {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      font-family: "Poppins";
      color: #025041;
    }
  }

  .btn {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding: 12px 16px;
    border-radius: 6px;
  }

  &__white-btn {
    border: 1px solid #025041 !important;
    background-color: white !important;
    color: #025141 !important;
    margin-right: 12px;

  }

  &__save-btn {
    border: 1px solid #025041 !important;
    background-color: #025141 !important;
    color: #FFFFFF !important;
    margin-right: 0px;
  }

  h1 {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #025041;
    font-family: "Poppins";
    margin-left: 0px;
    padding-left: 0px;
  }
}

@media only screen and (max-width: 820px) {

  .height {
    padding: 10px 17px !important;

  }
}
@media screen and (max-width:520px) {
  .payee_header_btn{
    width:96px !important;
    font-size: 11px !important;
    margin-right: 2px !important;
    padding: 5px 7px !important;
  }
}
@media only screen and (max-width:450px) {

  .counterparty {
    h1 {
      padding-left: 0px;
      margin-left: 0px;

    }

    &__save-btn {
      margin-right: 0px;
      padding: 9px 4px !important;

    }

    &__white-btn {
      padding: 9px 4px !important;
      margin-right: 10px;
    }

  }

  .styles_main_tesffsffs__VVD-X {
    padding: 16px 10px;

  }
 
}